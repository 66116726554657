import styled, { css, keyframes } from 'styled-components'

import {
  TAnimationProps,
  TEntryAnimations,
  TStyledAnimatedContainer,
} from './animated-container.types'

export const getAnimationKeyframe = ({
  base,
  active,
}: TAnimationProps) => keyframes`
  from {
    ${base}
  } to {
    ${active}
  }
`

export const getCombinedTransitionString = ({
  entryAnimations,
  extraTransitions,
}: {
  entryAnimations: TEntryAnimations
  extraTransitions: string
}) => {
  let animatedTransitions = entryAnimations
    .map(
      ({ property, duration, ease, delay }) =>
        `${property} ${duration} ${ease} ${delay}`
    )
    .join(', ')

  return extraTransitions
    ? `${animatedTransitions}, ${extraTransitions}`
    : animatedTransitions
}

const getTransitionsCss = ({
  entryAnimations,
  extraTransitions,
  runEntryAnimations,
}: {
  entryAnimations?: TEntryAnimations
  extraTransitions: string
  runEntryAnimations: boolean
}) => css`
  transition: ${getCombinedTransitionString({
    entryAnimations: entryAnimations || [],
    extraTransitions,
  })};

  ${entryAnimations?.map(animation =>
    runEntryAnimations ? animation.active : animation.base
  )}
`

const getAnimationsCss = ({
  entryAnimations,
}: {
  entryAnimations: TEntryAnimations
}) => css`
  animation: ${entryAnimations.map(
    ({ animationKeyframe, duration, ease, delay }, index) => {
      const delimiter = index > 0 ? ', ' : ''

      return css`
        ${delimiter}${duration} ${ease} ${delay} 1 normal both running ${animationKeyframe}
      `
    }
  )};
`

export const StyledAnimatedContainer = styled.div<TStyledAnimatedContainer>`
  @media not all and (prefers-reduced-motion) {
    ${({
      runAnimationsOnPageLoad,
      entryAnimations,
      extraTransitions,
      runEntryAnimations,
    }) =>
      runAnimationsOnPageLoad
        ? getAnimationsCss({ entryAnimations: entryAnimations || [] })
        : getTransitionsCss({
            entryAnimations: entryAnimations || [],
            extraTransitions: extraTransitions || '',
            runEntryAnimations: runEntryAnimations || false,
          })}
  }
`
