export const DATA_FIELD_ID = 'data-field-id'
export const CONSENTS_OPTIONS = 'consents-options'
export const TERMS_AND_CONSENTS = 'terms_and_consents'
export const MARKETING = 'marketing'
export const TAILOR_NEEDS_AGREED = 'tailor_to_needs_agreed'
export const THIRD_PARTIES_AGREED = 'third_parties_agreed'
export const SIGNUP_CONSENTS_STORAGE_KEY = 'signup_consents'
export const CONSENTS_FIELDS_EMBED_DOM = [
  MARKETING,
  TAILOR_NEEDS_AGREED,
  THIRD_PARTIES_AGREED,
]

export const CHECKBOX_DATA_SELECTOR = '[data-checked]'

export const FORM_ELEMENT_TYPES_MAP = {
  [TERMS_AND_CONSENTS]: 'checkbox',
  [MARKETING]: 'radio',
  [TAILOR_NEEDS_AGREED]: 'radio',
  [THIRD_PARTIES_AGREED]: 'radio',
}

export const formFields = [
  {
    'id': TERMS_AND_CONSENTS,
    'label': 'auth.form.terms-service-and-consents',
    'unsafeLabelHTML': true,
    'data-qa': 'field-terms_and_consents',
  },
]

const consentsFieldsLink = {
  url: '/privacy-policy/',
  label: 'auth.consents.privacy-policy.link',
}

export const consentFields = [
  {
    id: MARKETING,
    label: 'auth.consents.marketing',
  },
  {
    id: TAILOR_NEEDS_AGREED,
    label: 'auth.consents.tailor-needs',
    link: consentsFieldsLink,
  },
  {
    id: THIRD_PARTIES_AGREED,
    label: 'auth.consents.third-parties',
    link: consentsFieldsLink,
  },
]
