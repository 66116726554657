// eslint-disable-next-line local-rules/no-disallowed-root-domains
const TYPEFORM_COM_DEFAULT_ROOT_DOMAIN = 'typeform.com'
// eslint-disable-next-line local-rules/no-disallowed-root-domains
const VIDEOASK_COM_DEFAULT_ROOT_DOMAIN = 'videoask.com'

const DISALLOWED_ROOT_DOMAINS = [
  TYPEFORM_COM_DEFAULT_ROOT_DOMAIN,
  VIDEOASK_COM_DEFAULT_ROOT_DOMAIN,
]

module.exports = {
  TYPEFORM_COM_DEFAULT_ROOT_DOMAIN,
  VIDEOASK_COM_DEFAULT_ROOT_DOMAIN,
  DISALLOWED_ROOT_DOMAINS,
}
