import { fontWeight } from '@typeform/ginger/dist/constants/typography'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import styled from 'styled-components'

export const StyledSeeMoreLink = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${spacing(1)};
  text-decoration: underline;
  color: inherit;
  transition: transform 0.2s ease-in;
  margin-bottom: ${spacing(4)};

  ${mediaQueries.md`
    margin-bottom: 0;
    justify-content: flex-end;
    font-weight: ${fontWeight.bold};
  `}

  &:hover {
    transform: translateX(8px);
  }
`
