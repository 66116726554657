import '../lib/wdyr'
import 'intersection-observer'
import {
  TYPEFORM_ROOT_DOMAIN,
  VIDEOASK_ROOT_DOMAIN,
} from 'constants/public-envs'
import { LANGUAGES, NAMESPACES } from 'constants/locales'
import { TEST } from 'constants/query-strings'
import { TEXT_WRAP_BALANCED } from 'constants/experiments'

import PropTypes from 'prop-types'
import appWithI18n from 'next-translate/appWithI18n'
import React from 'react'
import App from 'next/app'
import Router from 'next/router'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import Cookies from 'js-cookie'
import CookieBanner, {
  CookieSafeAreaProvider,
  CookieSafeAreaSpacer,
} from 'components/organisms/cookie-banner'
import ConsentListener from '@typeform/js-tracking/lib/consents/consentListener'
import {
  hasTargetingConsent,
  hasPerformanceConsent,
} from '@typeform/js-tracking/lib/consents/consentUtil'
import {
  ClearbitScript,
  Tracking,
  trackExperimentEvent,
  syncAttributionIdState,
} from 'components/tracking'
import GlobalStyles from 'components/common/global-styles'
import Header from 'components/header'
import { merge } from '@s-libs/micro-dash'
import { MainProvider } from 'components/molecules/main-context'
import { AppProvider } from 'components/app-context'
import { LocaleContext } from 'components/locale-context'
import { EnvContext } from 'components/env-context'
import ErrorBoundary from 'components/error-boundary'
import PageContainer from 'components/common/page-container'
import ClientSideExperimentTriggers from 'components/common/client-side-experiment-triggers'
import SignupPreloader from 'components/okta-signup-page/signup-preloader'
import { loadLocaleFrom } from 'translations/loaders'
import useTranslation from 'next-translate/useTranslation'
import i18nConfig from 'i18n'
import { getOptimizelyAttributes } from 'utils/optimizely'
import { NavigationTrackingWrapper } from 'hooks/use-navigation-tracking-middleware'
import { getLocaleByLanguageCode } from '@typeform/ginger/dist/lib/i18n'
import UserProvider from 'hooks/use-user'
import { deleteNonStrictlyNecessaryCookies } from 'utils/cookies'
import { reportAccessibility } from 'utils/accessibility'
import {
  OptimizelyExperiment,
  OptimizelyVariation,
} from 'components/optimizely'
import { Device } from '@typeform/ginger/dist/constants/device'
import ContentfulLivePreviewWrapper from 'components/contentful-live-preview/contentful-live-preview-wrapper'
import { Provider as RollbarProvider } from '@rollbar/react'
import { ONE_TAP_BLOCKED_URLS } from 'components/google-one-tap/google-one-tap.constants'
import OptimizelyProviderWrapper from 'components/optimizely-provider-wrapper'

import { DEFAULT_PAGE_LOCALES, getPageLocales } from '../lib/localization'
import { rollbarConfig } from '../rollbar.config'

const Footer = dynamic(() => import('components/footer/next'))

const GoogleOneTap = dynamic(
  () => import('components/google-one-tap/google-one-tap'),
  { ssr: false }
)

const GingerProvider = React.memo(({ locale, device, children }) => {
  const { t } = useTranslation(NAMESPACES.GINGER)

  return (
    <MainProvider
      locale={locale}
      t={t}
      device={device}
      sharedVariables={{
        TYPEFORM_ROOT_DOMAIN,
        VIDEOASK_ROOT_DOMAIN,
      }}
    >
      {children}
    </MainProvider>
  )
})

GingerProvider.propTypes = {
  locale: PropTypes.oneOf(LANGUAGES),
  device: PropTypes.string,
  children: PropTypes.node,
}

class PublicMainSiteApp extends App {
  constructor(props) {
    super(props)

    const {
      pageProps: {
        device,
        currentUrl,
        language,
        visitorType,
        experiments,
        isVisitorAuthenticated,
        isVisitorRegistered,
        userCountryCode,
      },
    } = props

    this.optimizelyAttributes = getOptimizelyAttributes({
      device,
      currentUrl,
      language,
      visitorType,
      experiments,
      isVisitorAuthenticated,
      isVisitorRegistered,
      userCountryCode,
    })

    this.appConfig = this.getAppConfig()

    this.state = {
      hasTargetingConsent: hasTargetingConsent(),
    }
  }

  getAppConfig() {
    const { router } = this.props

    const initialConfig = {
      disableTracking: router.query.hasOwnProperty(TEST),
    }

    return merge(
      {},
      DEFAULT_APP_CONFIG,
      initialConfig,
      this.props.pageProps?.appConfig
    )
  }

  getIsOneTapSupported() {
    const { router, pageProps } = this.props
    const { isVisitorAuthenticated, device } = pageProps
    const { hasTargetingConsent } = this.state

    if (isVisitorAuthenticated) {
      return false
    }

    if (device === Device.Mobile && !hasTargetingConsent) {
      return false
    }

    if (ONE_TAP_BLOCKED_URLS.some(path => router.asPath.includes(path))) {
      return false
    }

    return true
  }

  componentDidMount() {
    // This syncs the attribution id for all future tracking events.
    // It must be first after the component mounts.
    syncAttributionIdState()
    // Preload okta widget component
    const timeBeforePreloadingSignup = 15000

    setTimeout(() => {
      const preloadSignupWidget = () => {
        import('../components/okta-widget').catch(() => {})
      }

      window.requestIdleCallback &&
        window.requestIdleCallback(preloadSignupWidget)
    }, timeBeforePreloadingSignup)
  }

  handleConsentUpdate = () => {
    const {
      router: { query },
    } = this.props
    const cjCookie = Cookies.get('cje')
    const hasConsent = hasTargetingConsent()

    this.setState({
      hasTargetingConsent: hasConsent,
    })

    if (hasPerformanceConsent() && query.cjevent && !cjCookie) {
      Router.reload(window.location.pathname)
    }

    if (hasConsent) {
      trackExperimentEvent({
        name: 'targeting_consent_accepted',
      })
    }
  }

  handleCookiesRejected = () => {
    deleteNonStrictlyNecessaryCookies()
  }

  render() {
    const { Component, pageProps } = this.props
    const {
      experiments = {},
      locale,
      currentUrl,
      isEphemeral,
      bannerItems,
      feedbackForm,
      device,
      visitorType,
      oktaConfig,
      topMenu,
      footer,
      // Public environment variables
      // These are used in react components but are loaded from process.env server-side into EnvContext.
      // They are then pulled out in getCommonSyncServerSideProps and made available to all pages.
      env,
    } = pageProps
    const language = locale // TODO: Refactor out duplication
    const showHeader = !!this.appConfig?.headerProps?.showHeader
    const showFooter = !!this.appConfig?.footerProps?.showFooter
    const showBanners = this.appConfig?.showBanners
    const availableLocales =
      pageProps?.pageContent?.locales || this.appConfig.availableLocales
    const isVisitorAuthenticated = pageProps?.isVisitorAuthenticated
    const localeProviderValue = { locale, language }
    const trackingConfig = pageProps?.pageContent?.trackingConfig
    const { hasTargetingConsent } = this.state
    const userCountryCode = pageProps?.userCountryCode
    const typeformProperty =
      trackingConfig?.viewPageSectionProps?.typeform_property
    const isOneTapSupported = this.getIsOneTapSupported()

    return (
      <React.StrictMode>
        <EnvContext.Provider value={env}>
          <RollbarProvider config={rollbarConfig}>
            <AppProvider isEphemeral={isEphemeral} device={device}>
              <NavigationTrackingWrapper
                typeformProperty={typeformProperty}
                userCountryCode={userCountryCode}
              />
              <ContentfulLivePreviewWrapper
                locale={getLocaleByLanguageCode(locale)}
              >
                <GingerProvider locale={locale} device={device}>
                  <OptimizelyProviderWrapper
                    attributes={this.optimizelyAttributes}
                    experiments={experiments}
                  >
                    <ClientSideExperimentTriggers currentUrl={currentUrl} />
                    <Tracking
                      disabled={this.appConfig.disableTracking}
                      trackingConfig={trackingConfig}
                    />
                    <ClearbitScript hasTargetingConsent={hasTargetingConsent} />
                    <OptimizelyExperiment experimentId={TEXT_WRAP_BALANCED.ID}>
                      <OptimizelyVariation
                        variationId={TEXT_WRAP_BALANCED.VARIATIONS.CONTROL}
                        default
                      >
                        <GlobalStyles />
                      </OptimizelyVariation>
                      <OptimizelyVariation
                        variationId={TEXT_WRAP_BALANCED.VARIATIONS.VARIATION}
                      >
                        <GlobalStyles applyTextWrapBalance />
                      </OptimizelyVariation>
                    </OptimizelyExperiment>
                    <LocaleContext.Provider value={localeProviderValue}>
                      <UserProvider
                        isUserAuthenticated={isVisitorAuthenticated}
                      >
                        <CookieSafeAreaProvider>
                          <ErrorBoundary>
                            <Script
                              strategy='beforeInteractive'
                              data-testid='onetrust-script'
                              src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
                              data-domain-script={`dc37179a-4de5-4648-bd8f-2f087ec53cbd${
                                process.env.NODE_ENV === 'production'
                                  ? ''
                                  : '-test'
                              }`}
                            />
                            <CookieBanner
                              locale={locale}
                              onCookiesRejected={this.handleCookiesRejected}
                            />
                            <PageContainer
                              banners={bannerItems}
                              currentUrl={currentUrl}
                              showBanners={showBanners}
                              device={device}
                              feedbackForm={feedbackForm}
                              visitorType={visitorType}
                              userCountryCode={userCountryCode}
                              language={language}
                            >
                              <SignupPreloader />
                              {showHeader && (
                                <Header
                                  locale={locale}
                                  topMenu={topMenu}
                                  {...this.appConfig.headerProps}
                                  isVisitorAuthenticated={
                                    isVisitorAuthenticated
                                  }
                                  trackingConfig={
                                    pageProps?.pageContent?.trackingConfig
                                  }
                                  oktaConfig={oktaConfig}
                                />
                              )}
                              <Component
                                availableLocales={availableLocales}
                                currentUrl={currentUrl}
                                language={language}
                                locale={locale}
                                {...pageProps}
                              />
                              <ErrorBoundary>
                                {showFooter && (
                                  <Footer
                                    locale={language}
                                    pageLocales={availableLocales}
                                    {...footer}
                                    {...this.appConfig.footerProps}
                                  />
                                )}
                              </ErrorBoundary>
                              {isOneTapSupported && (
                                <ErrorBoundary>
                                  <GoogleOneTap
                                    oktaConfig={oktaConfig}
                                    device={device}
                                  />
                                </ErrorBoundary>
                              )}
                              <CookieSafeAreaSpacer />
                            </PageContainer>
                          </ErrorBoundary>
                        </CookieSafeAreaProvider>
                      </UserProvider>
                    </LocaleContext.Provider>
                    <ConsentListener
                      onConsentUpdate={this.handleConsentUpdate}
                    />
                  </OptimizelyProviderWrapper>
                </GingerProvider>
              </ContentfulLivePreviewWrapper>
            </AppProvider>
          </RollbarProvider>
        </EnvContext.Provider>
      </React.StrictMode>
    )
  }
}

reportAccessibility()

const DEFAULT_APP_CONFIG = {
  headerProps: {
    showHeader: true,
  },
  footerProps: {
    showFooter: true,
  },
  disableTracking: false,
  availableLocales: getPageLocales(DEFAULT_PAGE_LOCALES),
}

export default appWithI18n(PublicMainSiteApp, {
  ...i18nConfig,
  loadLocaleFrom,
})
