import type { Configuration } from 'rollbar'

type TTelemetry = {
  body: {
    message: string
  }
}

type TPayload = { body: { telemetry: TTelemetry[] } }

// @ts-expect-error - our types are more detailed than Rollbar's own ones.
export const checkIgnore: Required<Configuration>['checkIgnore'] = (
  isUncaught,
  args,
  payload: TPayload
) => {
  const userAgent = window.navigator.userAgent || ''
  const hostname = window.location.hostname || ''
  const uaIgnorelist = ['duckduckbot', 'MSIE', 'rv:11.0']
  const domainIgnoreList = ['translate.goog']

  if (uaIgnorelist.some(ua => userAgent.includes(ua))) {
    return true
  }

  const telemetry = payload?.body?.telemetry || []
  // Attempt to ignore noisy minified React Errors that will go away when we update React.
  // @see: https://typeform.atlassian.net/browse/WEB-7814
  if (
    telemetry.some((t: TTelemetry) =>
      `${t?.body?.message}`.includes('Minified React error')
    )
  ) {
    return true
  }

  return domainIgnoreList.some(domain => hostname.includes(domain))
}
