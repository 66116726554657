import React from 'react'
import Link from 'next/link'

/**
 * This component is used to enable Nextjs mechanism to prefetch the chunk for a specific page
 *
 * Explanation: when a Nextjs Link component is present on a page, Nextjs prefetches the page chunk, so when
 * users navigate to the page, the JS for that page is already available
 *
 * Therefore, this component places an invisible link on every page to make sure access to signup page
 * is always fast
 *
 * More info: https://nextjs.org/docs/api-reference/next/link
 */
const SignupPreloader = () => (
  <div
    style={{
      visibility: 'hidden',
      position: 'fixed',
      top: 0,
      pointerEvents: 'none',
    }}
  >
    <Link href={'/signup'}>Signup</Link>
  </div>
)

SignupPreloader.displayName = 'SignupPreloader'

export default SignupPreloader
