const { LANGUAGE_CODES } = require('@typeform/ginger/dist/lib/i18n')

const NAMESPACES = {
  COMMON: 'common',
  GINGER: 'ginger',
  HOME: 'home',
  PRICING: 'pricing',
  HELP: 'help',
  CONTACT_FORM_ZENDESK: 'contact-form-zendesk',
  AUTH: 'auth',
  ERROR_PAGE: 'error-page',
  PTG: 'ptg',
  ENTERPRISE_SECTION: 'enterprise-section',
}

const DEFAULT_NAMESPACE = NAMESPACES.COMMON

module.exports = {
  LANGUAGES: [
    LANGUAGE_CODES.english,
    LANGUAGE_CODES.spanish,
    LANGUAGE_CODES.french,
    LANGUAGE_CODES.german,
  ],
  NAMESPACES,
  DEFAULT_NAMESPACE,
}
