import siteRebrandTokens from 'constants/echo-rebrand-theme-override'

import { RebrandTokens } from '@typeform/echo-tokens'
import { EchoProvider } from '@typeform/echo-ui'
import { ThemeProvider, ThemeProviderComponent } from 'styled-components'

const SiteEchoThemeProvider: ThemeProviderComponent<RebrandTokens> =
  ThemeProvider

const SiteEchoProvider = ({
  children,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any
}) => {
  return (
    <EchoProvider themeName='rebrand' shouldLoadDefaultFont={false}>
      <SiteEchoThemeProvider theme={siteRebrandTokens}>
        {children}
      </SiteEchoThemeProvider>
    </EchoProvider>
  )
}

export default SiteEchoProvider
