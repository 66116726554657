import { Palette } from '@typeform/ginger/dist/constants/palettes'
import { BOX_SHADOWS } from '@typeform/ginger/dist/constants/shadows'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import styled, { css } from 'styled-components'

import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../mega-menu.constants'
import { getMenuBackgroundColorStyles } from '../../helpers/background'

// To ensure the `backdrop-filter` effect works in Safari, the content wrapper cannot have a height of 0.
// Therefore we give it a minimum height of 1px.
export const HEADER_BACKGROUND_OFFSET = 1

type TMenuBackgroundContainerProps = {
  palette: Palette
  transparentBackground: boolean
  contentHeight?: string
  isMenuScrolled?: boolean
  isExpanded?: boolean
}

const getTop = () => {
  return `-${HEADER_BACKGROUND_OFFSET}px`
}

const getPaddingTop =
  (forMobile = true) =>
  ({ transparentBackground }: TMenuBackgroundContainerProps) => {
    const headerHeight = forMobile ? MOBILE_HEADER_HEIGHT : HEADER_HEIGHT
    return transparentBackground
      ? `${HEADER_BACKGROUND_OFFSET}px`
      : `${HEADER_BACKGROUND_OFFSET + headerHeight}px`
  }

const getHeight =
  (forMobile = true) =>
  ({
    contentHeight,
    isMenuScrolled,
    transparentBackground,
  }: TMenuBackgroundContainerProps) => {
    if (contentHeight) {
      return contentHeight
    }

    if (forMobile) {
      return `${MOBILE_HEADER_HEIGHT}px`
    }

    return !isMenuScrolled && transparentBackground ? 0 : `${HEADER_HEIGHT}px`
  }

export const menuBackgroundCommonStyles = css<TMenuBackgroundContainerProps>`
  position: absolute;
  z-index: -1;
  top: ${getTop};
  padding-top: ${getPaddingTop(true)};
  left: 0;
  right: 0;
  ${getMenuBackgroundColorStyles}
  transition: height 0.4s ease-out;

  ${mediaQueries.md`
    padding-top: ${getPaddingTop(false)}
  `}
`
export const menuBackgroundExpandedStyles = css`
  transition-delay: 100ms;
  box-shadow: ${BOX_SHADOWS.small};
`
export const MenuBackgroundContainer = styled.div<TMenuBackgroundContainerProps>`
  ${menuBackgroundCommonStyles}

  height: ${getHeight(true)};
  transition: height 600ms cubic-bezier(0.165, 0.84, 0.44, 1),
    box-shadow 600ms cubic-bezier(0.165, 0.84, 0.44, 1);

  ${mediaQueries.md`
    height: ${getHeight(false)};

    ${({ isExpanded }: TMenuBackgroundContainerProps) =>
      isExpanded && menuBackgroundExpandedStyles}
  `}
`
