import styled from 'styled-components'
import { LogoTypeWrapper } from 'components/atoms/common/logo/logo.styles'

export const TypeformLogoWrapper = styled.div`
  @media (max-width: 1100px) {
    ${LogoTypeWrapper} {
      display: none;
    }
  }

  @media (min-width: 1101px) {
    ${LogoTypeWrapper} {
      display: inline-block;
    }
  }
`
