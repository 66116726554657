import React, { createContext, useContext, useState } from 'react'

import {
  TCookieSafeAreaContext,
  TCookieSafeAreaProvider,
} from './cookie-banner.types'

const CookieSafeAreaContext = createContext<TCookieSafeAreaContext>([])

export const useCookieBannerHeight = () => {
  const [height] = useContext(CookieSafeAreaContext)

  return height
}
export const useCookieBannerHeightSetter = () => {
  const [, setCookieBannerHeight] = useContext(CookieSafeAreaContext)

  return setCookieBannerHeight
}

const CookieSafeAreaProvider = ({ children }: TCookieSafeAreaProvider) => {
  const cookieBannerHeightState = useState<number>()

  return (
    <CookieSafeAreaContext.Provider value={cookieBannerHeightState}>
      {children}
    </CookieSafeAreaContext.Provider>
  )
}

CookieSafeAreaProvider.displayName = 'CookieSafeAreaProvider'

export default CookieSafeAreaProvider
