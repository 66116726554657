import React, { memo, useCallback, useMemo } from 'react'
import BannerProvider, {
  dispatchDismissActiveBannerEvent,
} from 'components/molecules/banner-provider'
import PropTypes from 'prop-types'
import { useOptimizelyContext } from 'components/optimizely/optimizely-provider'

import { trackEvent, trackClickEvent } from '../tracking'
import { useAppContext } from '../app-context'
import { useLocaleContext } from '../locale-context'

const Banners = ({ banners, children, currentUrl }) => {
  const { user, isCookieBannerVisible: isCookieBannerVisibleState } =
    useAppContext()
  const { locale } = useLocaleContext()
  const { experiments } = useOptimizelyContext()

  const getBannerEvents = useCallback(
    banner => ({
      click: () => {
        if (!(banner.dismissibleOnClick === false)) {
          dispatchDismissActiveBannerEvent()
        }

        if (banner?.trackingItemName) {
          trackClickEvent({
            ...banner.cta?.tracking,
            item: banner.trackingItemName,
            item_type: 'link',
            value: banner.cta?.text,
          })
        }
      },
      show: () => {
        if (banner?.trackingItemName) {
          trackEvent('message_shown', {
            message: banner.trackingItemName,
            value: banner.name,
          })
        }
      },
      dismiss: () => {
        if (banner?.trackingItemName) {
          trackClickEvent({
            item: `${banner.trackingItemName}_close`,
            item_type: 'link',
            value: banner?.message,
          })
        }
      },
    }),
    []
  )

  const audienceConfig = useMemo(() => {
    let isUserAuthenticated = null
    let isCookieBannerVisible = null

    if (user !== undefined) {
      isUserAuthenticated = !!user
    }
    if (isCookieBannerVisibleState !== undefined) {
      isCookieBannerVisible = isCookieBannerVisibleState
    }

    return {
      isUserAuthenticated,
      isCookieBannerVisible,
      locale,
      currentUrl,
      experiments,
    }
  }, [locale, user, isCookieBannerVisibleState, currentUrl, experiments])

  return (
    <BannerProvider
      banners={banners}
      bannerEvents={getBannerEvents}
      audiencesConfig={audienceConfig}
    >
      {children}
    </BannerProvider>
  )
}

Banners.propTypes = {
  children: PropTypes.any.isRequired,
  currentUrl: PropTypes.string,
  banners: PropTypes.arrayOf(PropTypes.object),
}

Banners.defaultProps = {
  banners: [],
  currentUrl: null,
}

export default memo(Banners)
