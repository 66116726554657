import { ReactElement } from 'react'

export enum AvatarSize {
  Medium = 'medium',
  Large = 'large',
}

export type TAvatarProps = {
  email: string
  name?: string
  size?: AvatarSize
}

export type TColors = { [key: string]: string }
export type TAvatarColor = {
  background: TColors[string]
  shape: TColors[string]
}
export type TAvatarBackground = ReactElement & {
  background?: TColors[string]
  shape?: TColors[string]
}
