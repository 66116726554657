import { QUERY_STRINGS_TO_PRESERVE_IN_REDIRECTS } from 'constants/query-strings'

import { getAppPublicBaseUrl } from 'utils/environment-variables'

export const removeQueryString = link => {
  return link?.split('?')[0]
}

export const getQueryString = link => {
  return link?.split('?')[1]
}

export const appendQueryStringToUrl = ({ url, key, value }) => {
  let urlCopy = `${url}`
  const isRelativeUrl = urlCopy.startsWith('/')
  if (isRelativeUrl) {
    urlCopy = `${getAppPublicBaseUrl()}${urlCopy}`
  }
  const urlObject = new URL(urlCopy)
  urlObject.searchParams.set(key, value)
  if (isRelativeUrl) {
    return `${urlObject.pathname}${urlObject.search}`
  }
  return urlObject.toString()
}

export const pathWithQueries = (path, query = {}) => {
  const queryString = Object.keys(query)
    .filter(key => QUERY_STRINGS_TO_PRESERVE_IN_REDIRECTS.includes(key))
    .map(key => `${key}=${query[key]}`)
    .join('&')

  if (queryString) {
    return `${path}?${queryString}`
  }

  return `${path}`
}
