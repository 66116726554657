import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'

import { AlignmentStyles, DropdownMenuContainer } from './dropdown-menu.styles'

export type TDropdownMenu = {
  id: string
  children: React.ReactNode
  triggerId: string
  open?: boolean
  onRequestClose?: () => void
  alignment: keyof typeof AlignmentStyles
  // alignment: 'Left' | 'Center' | 'Right'
}

type TFocusToNextElement = {
  menuItems: HTMLElement[]
  activeElement: HTMLElement
  direction: number
}
const menuItemSelector = '[role="menuitem"]'

const focusToNextElement = ({
  menuItems,
  activeElement,
  direction,
}: TFocusToNextElement) => {
  if (!menuItems || !activeElement || menuItems.length === 1) {
    return
  }

  const activeIndex = menuItems.indexOf(activeElement)
  const lastIndex = menuItems.length - 1

  let nextIndex = activeIndex + direction
  nextIndex = nextIndex > lastIndex ? 0 : nextIndex
  nextIndex = nextIndex < 0 ? lastIndex : nextIndex

  const nextElement = menuItems[nextIndex]
  nextElement.focus()
}

const DropdownMenu = forwardRef(
  (
    {
      children,
      open = false,
      onRequestClose,
      triggerId,
      ...props
    }: TDropdownMenu,
    ref
  ) => {
    const menuContainerRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => menuContainerRef.current)

    const handleRequestClose = useCallback(() => {
      const dropdownTrigger = document.getElementById(triggerId)
      dropdownTrigger?.focus?.()

      onRequestClose?.()
    }, [onRequestClose, triggerId])

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        const dropdownTrigger = document.getElementById(triggerId)
        const target = event.target as Node | null

        if (
          menuContainerRef.current &&
          !menuContainerRef.current.contains(target) &&
          dropdownTrigger &&
          !dropdownTrigger.contains(target)
        ) {
          handleRequestClose()
        }
      }

      const handleKeyboardAction = (event: KeyboardEvent) => {
        if (event.defaultPrevented) {
          return
        }

        const menuItems = Array.from(
          menuContainerRef.current?.querySelectorAll(menuItemSelector) || []
        ).filter((item): item is HTMLElement => item instanceof HTMLElement)
        const activeElement = document.activeElement as HTMLElement

        switch (event.key) {
          case 'ArrowDown':
            focusToNextElement({ menuItems, activeElement, direction: 1 })
            break
          case 'ArrowUp': {
            focusToNextElement({ menuItems, activeElement, direction: -1 })
            break
          }
          case 'Escape':
          case 'Tab':
            handleRequestClose()
            break
          default:
            return
        }
        event.preventDefault()
      }

      if (open && menuContainerRef.current) {
        const menuItem =
          menuContainerRef.current.querySelector(menuItemSelector)
        if (menuItem instanceof HTMLElement) {
          menuItem.focus()
        }

        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('keydown', handleKeyboardAction)
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
        document.removeEventListener('keydown', handleKeyboardAction)
      }
    }, [open, handleRequestClose, triggerId])

    return (
      <DropdownMenuContainer
        ref={menuContainerRef}
        role='menu'
        tabIndex={-1}
        open={open}
        aria-labelledby={triggerId}
        {...props}
      >
        {open && children}
      </DropdownMenuContainer>
    )
  }
)

export default DropdownMenu
