import { CONTAINER_WIDTH } from '@typeform/ginger/dist/constants/design'
import styled, { css } from 'styled-components'

/**
 * Function that gives compatibility for IE11 grid columns
 * @param {number} starts number from 1 to 12 from where the column starts
 * @param {number} ends number from 1 to 12 for column grid grow
 */
export const createGridColumns = (starts: number, ends: number) => css`
  grid-column: ${starts} / span ${ends};
  -ms-grid-column: ${starts};
  -ms-grid-column-span: ${ends};
`

type TContainerBaseProps = {
  minHeight?: string
}

export const ContainerBase = styled.div<TContainerBaseProps>`
  position: relative;
  align-self: center;
  margin: 0 auto;
  max-width: ${CONTAINER_WIDTH}px;
  width: 100%;
  padding: 0 24px;

  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`};
`

type TSectionProps = {
  minHeight?: string
  padding?: string
  backgroundColor?: string
  fontColor?: string
}

export const Section = styled.section<TSectionProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: ${({ minHeight }) => minHeight || '90vh'};
  width: 100%;
  padding: ${({ padding }) => padding || '80px 0'};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  color: ${({ fontColor }) => fontColor || 'inherit'};
`

export const FlexContainer = styled(ContainerBase)`
  display: flex;
`

export const GridContainer = styled(ContainerBase)`
  display: -ms-grid;
  display: grid;
  // eslint-exclude-next-line
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; /* this is for IE 11 🙄 */
  grid-template-columns: repeat(12, 1fr);
  column-gap: 2.6%;

  /* this to align with the design system */
  // eslint-exclude-next-line
  @media (min-width: 400px) {
    column-gap: 32px;
  }
`
