export const isBetween = ({ dateToTest, start, end }) => {
  return start <= dateToTest && dateToTest <= end
}

export const addYears = (date = new Date(), years = 0) => {
  date.setFullYear(date.getFullYear() + years)

  return date
}

export const date = { isBetween }
