// Copied from Kitt
// https://github.com/Typeform/kitt/blob/05d879218730c3562ab6a47329e5e12b4e2acd35/src/components/avatar/avatar-backgrounds.tsx

import { TAvatarBackground } from '../avatar.types'
export const avatarBackgrounds: TAvatarBackground[] = [
  <g key='path1'>
    <path d='M-2.272 22.42c1.206.78 2.425.836 3.617.352 1.154-.47 2.263-1.438 3.342-2.66 1.583-1.791 3.204-4.255 4.884-6.81.606-.92 1.22-1.854 1.843-2.771 2.377-3.504 4.891-6.8 7.675-8.645C20.47.969 21.897.426 23.39.379c1.49-.047 3.093.398 4.835 1.54l.548-.837C26.891-.152 25.088-.675 23.359-.621c-1.725.055-3.33.683-4.823 1.673-2.967 1.967-5.577 5.42-7.95 8.917-.647.954-1.276 1.91-1.89 2.844-1.666 2.532-3.227 4.903-4.758 6.637-1.046 1.185-2.031 2.014-2.97 2.396-.903.366-1.777.33-2.696-.266l-.544.84zM23.573 33.5c8.837 0 27-6.663 27-15.5s-7.163-16-16-16c-8.836 0-9.5 9.163-9.5 18 0 3.974-2.09 7.104-3.588 9.345-1.831 2.743-2.775 4.155 2.088 4.155z' />
  </g>,

  <g key='path2'>
    <path d='M8-2.5C12.18-1.784-4.161 13 8 35c7.5 7.5-7.98-4.665-12.161-5.38-4.18-.716-21.272-6.248-19.84-14.62C-22.566 6.628 3.82-3.216 8-2.5z' />
    <path
      clipRule='evenodd'
      d='M22.265-1.097c.581-4.73.035-9.123-2.76-12.773l.794-.608c3.014 3.937 3.558 8.63 2.959 13.503-.589 4.794-2.292 9.83-3.901 14.591l-.07.206c-1.64 4.854-3.156 9.388-3.378 13.193-.11 1.892.102 3.567.755 4.991.648 1.416 1.747 2.62 3.475 3.554l-.475.88c-1.902-1.028-3.163-2.387-3.91-4.017-.742-1.62-.96-3.473-.843-5.467.231-3.967 1.802-8.642 3.429-13.454l.052-.155c1.622-4.798 3.297-9.752 3.873-14.444z'
      fillRule='evenodd'
    />
  </g>,
  <g key='path3'>
    <path d='M15.232 13.484c-3.553-8.09 10.4-21.495 18.49-25.047 8.09-3.553.502 4.873 4.054 12.963 3.553 8.091 6.63 16.748-1.461 20.301-8.091 3.553-17.53-.126-21.083-8.217z' />
    <path
      clipRule='evenodd'
      d='M2.76 27.77C1.313 29.686-.71 30.883-3.365 31.16l-.103-.995c2.38-.247 4.152-1.303 5.432-2.997 1.293-1.71 2.105-4.097 2.485-7.021.76-5.852-.232-13.684-2.32-22.025l.97-.242c2.099 8.383 3.126 16.36 2.342 22.396-.392 3.02-1.243 5.596-2.68 7.495z'
      fillRule='evenodd'
    />
  </g>,
  <g key='path4'>
    <path d='M-2.903 24.369c4.471-7.622 23.51-4.177 31.132.294 7.621 4.472-3.654 3.264-8.126 10.885-4.471 7.622-9.681 15.19-17.303 10.718C-4.82 41.794-7.375 31.99-2.903 24.369z' />
    <path
      clipRule='evenodd'
      d='M6.687.769c-.9-2.227-.887-4.578.24-6.997l.907.422c-1.011 2.17-1.015 4.232-.22 6.2.802 1.988 2.433 3.911 4.747 5.74 4.63 3.658 11.859 6.83 20.088 9.324l-.29.957c-8.271-2.506-15.642-5.723-20.418-9.497C9.35 5.03 7.578 2.976 6.687.768z'
      fillRule='evenodd'
    />
  </g>,
  <g key='path5'>
    <path d='M39.237 33.084c-3.606 8.068-22.908 6.738-30.975 3.131-8.067-3.606 3.272-3.645 6.879-11.712 3.606-8.067 7.952-16.162 16.02-12.555 8.067 3.606 11.683 13.07 8.076 21.136z' />
    <path
      clipRule='evenodd'
      d='M-2.883 18.72c-1.415-.532-2.799-1.65-4.165-3.45l.797-.605c1.296 1.708 2.539 2.675 3.72 3.118 1.168.439 2.326.383 3.5-.05 2.4-.884 4.81-3.313 7.277-6.208.733-.86 1.47-1.76 2.208-2.662 1.707-2.085 3.423-4.181 5.124-5.827 2.42-2.34 5.018-3.98 7.726-3.187l-.281.96c-2.124-.622-4.337.614-6.75 2.946-1.658 1.604-3.312 3.625-4.999 5.686-.748.914-1.502 1.836-2.267 2.733-2.448 2.872-5.013 5.51-7.692 6.497-1.364.503-2.771.584-4.198.048z'
      fillRule='evenodd'
    />
  </g>,
  <g key='path6'>
    <path d='M45.766 8.824c4.18.716-12.161 15.5 0 37.5 7.5 7.5-7.98-4.665-12.161-5.38-4.18-.716-21.273-6.247-19.84-14.62 1.434-8.372 27.82-18.216 32-17.5z' />
    <path
      clipRule='evenodd'
      d='M14.198 7.65c.581-4.731.035-9.123-2.76-12.774l.793-.607c3.015 3.936 3.558 8.63 2.96 13.503-.59 4.793-2.292 9.83-3.902 14.59l-.07.207c-1.64 4.854-3.155 9.388-3.378 13.192-.11 1.892.103 3.567.755 4.992.649 1.416 1.748 2.62 3.476 3.554l-.475.88c-1.903-1.028-3.163-2.388-3.91-4.018-.742-1.62-.96-3.472-.844-5.466.232-3.968 1.803-8.642 3.43-13.455l.052-.155c1.622-4.797 3.297-9.752 3.873-14.443z'
      fillRule='evenodd'
    />
  </g>,
  <g key='path7'>
    <path d='M10.231 27.948c2.337-8.522 21.617-10.145 30.138-7.808 8.522 2.337-2.68 4.101-5.016 12.623-2.337 8.522-5.4 17.184-13.923 14.847-8.522-2.337-13.536-11.14-11.199-19.662z' />
    <path
      clipRule='evenodd'
      d='M.893 18.03c-2.374.367-4.66-.183-6.757-1.833l.619-.786c1.88 1.48 3.887 1.955 5.985 1.631 2.118-.327 4.364-1.475 6.672-3.31 4.62-3.671 9.36-9.984 13.669-17.425l.865.5c-4.33 7.48-9.146 13.92-13.911 17.708C5.65 16.41 3.245 17.667.893 18.03z'
      fillRule='evenodd'
    />
  </g>,
  <g key='path8'>
    <path d='M44.5 20.5C22.834 23.795 34-.5 4.155 2.601c-29.846 3.101 8.336-3.993 11.273-7.053 2.936-3.061 17.099-14.113 23.228-8.232 6.13 5.88 27.51 29.889 5.844 33.184z' />
    <path
      clipRule='evenodd'
      d='M25.323 47.565c1.809.262 4.224.041 7.514-.853l.262.965c-3.353.912-5.92 1.168-7.92.877-2.021-.294-3.452-1.144-4.514-2.4-1.046-1.236-1.712-2.84-2.266-4.6a69.833 69.833 0 0 1-.79-2.78l-.043-.157c-.246-.914-.498-1.851-.784-2.803-1.208-4.02-3.005-8.239-7.308-11.567-4.31-3.335-11.205-5.829-22.717-6.23l.035-1c11.63.406 18.757 2.929 23.294 6.439 4.545 3.515 6.421 7.97 7.654 12.07.29.966.546 1.917.791 2.828l.043.16c.26.964.508 1.88.779 2.74.544 1.727 1.161 3.174 2.075 4.255.899 1.062 2.107 1.796 3.895 2.056z'
      fillRule='evenodd'
    />
  </g>,
  <g key='path9'>
    <path d='M30.166 48.15C8.482 51.324 21.6 39.181-9.861 34.51c-31.46-4.672 8.359-3.947 11.312-6.991 2.953-3.045 17.176-14.019 23.273-8.104 6.097 5.914 27.125 25.559 5.442 28.734z' />
    <path
      clipRule='evenodd'
      d='M22.16 5.62c1.008-1.525 1.834-3.805 2.424-7.163l.985.173c-.601 3.423-1.46 5.854-2.574 7.541-1.125 1.705-2.503 2.639-4.091 3.066-1.564.421-3.298.342-5.127.095a69.745 69.745 0 0 1-2.852-.466l-.16-.029a99.126 99.126 0 0 0-2.871-.482C3.74 7.74-.841 7.572-5.683 10.051c-4.851 2.484-10.04 7.664-15.298 17.913l-.89-.456c5.312-10.354 10.626-15.733 15.732-18.347 5.115-2.618 9.944-2.423 14.18-1.795.997.148 1.966.32 2.895.486l.164.029c.982.175 1.917.34 2.81.46 1.795.242 3.367.299 4.734-.07 1.344-.361 2.521-1.142 3.517-2.65z'
      fillRule='evenodd'
    />
  </g>,
  <g key='path10'>
    <path d='M14.887-4.583c2.146-3.658 18.824 2.419 26.15 6.717 7.327 4.299-2.376 1.201-4.522 4.86-2.147 3.658-4.923 7.129-12.25 2.83C16.94 5.526 12.74-.924 14.888-4.583z' />
    <path
      clipRule='evenodd'
      d='M4.906 15.64C3.516 7.33 4.738-1.53 6.928-6.228l.906.422C5.746-1.327 4.528 7.321 5.892 15.475c1.364 8.155 5.272 15.628 13.253 18.047l-.29.956c-8.519-2.58-12.56-10.529-13.95-18.838z'
      fillRule='evenodd'
    />
  </g>,
]
