import React, { memo, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { hasPerformanceConsent } from '@typeform/js-tracking/lib/consents/consentUtil'
import ConsentListener from '@typeform/js-tracking/lib/consents/consentListener'
import { useRouter } from 'next/router'
import { useRollbar } from '@rollbar/react'
import { useEnvContext } from 'components/env-context'

import { initTracking, trackViewPageSection } from './utils'

export const Tracking = memo(({ disabled, trackingConfig = {} }) => {
  const envContext = useEnvContext()
  const rollbar = useRollbar()
  const { viewPageSectionProps } = trackingConfig
  const lastTrackedPath = useRef('')
  const { asPath: currentPath } = useRouter()

  if (!envContext) {
    rollbar.error('envContext is not available')
  }

  const handleInit = useCallback(() => {
    if (envContext && !disabled && hasPerformanceConsent()) {
      const { SEGMENT_TRACKING_KEY, COOKIE_DOMAIN, GTM_ID } = envContext
      initTracking({
        trackingKey: SEGMENT_TRACKING_KEY,
        COOKIE_DOMAIN,
        GTM_ID,
      })
      if (lastTrackedPath.current !== currentPath) {
        lastTrackedPath.current = currentPath
        trackViewPageSection(viewPageSectionProps)
      }
    }
  }, [disabled, viewPageSectionProps, currentPath, envContext])

  useEffect(handleInit, [handleInit])

  return <ConsentListener onConsentUpdate={handleInit} />
})

Tracking.defaultProps = {
  disabled: false,
  trackingConfig: {},
}

Tracking.propTypes = {
  disabled: PropTypes.bool,
  trackingConfig: PropTypes.object,
}

Tracking.displayName = 'Tracking'
