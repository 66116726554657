import { sizes as defaultSizes } from '../../constants'
import { getImageVariantPath } from '../get-image-variant-path'
import { getSrcSet } from '../get-src-set'
const CONTENTFUL_IMAGE_DOMAIN = 'images.ctfassets.net'

export const getPictureSources = ({
  src,
  sizes = defaultSizes,
  defaultSize,
}: {
  src: string
  sizes?: string[]
  defaultSize?: string
}) => {
  if (src?.includes(CONTENTFUL_IMAGE_DOMAIN)) {
    // Contentful assets cannot be resized, so we return the original URL
    return {
      fallback: src,
      srcSet: src,
      srcSetWebP: src,
    }
  }

  const fallback = getImageVariantPath({
    src,
    size: defaultSize || sizes[sizes.length - 1],
  })
  const srcSet = getSrcSet({ src, sizes })
  const srcSetWebP = getSrcSet({
    src,
    overrideExtension: '.webp',
    sizes,
  })

  if (!fallback || !srcSet || !srcSetWebP) {
    return null
  }

  return {
    fallback,
    srcSet,
    srcSetWebP,
  }
}
