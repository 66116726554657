export const fonts = {
  SFProDisplay: 'SF Pro Display',
  apercuPro: 'apercu-pro',
  apercuMonoPro: 'apercu-mono-pro',
} as const

export const fontFamily = {
  SFProDisplay: `${fonts.SFProDisplay}, -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif`,
  apercuPro: `${fonts.apercuPro}, -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif`,
  apercuMonoPro: `${fonts.apercuMonoPro}, -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif`,
} as const

export const fontSize = {
  x5Large: '80px',
  x4Large: '64px',
  x3Large: '48px',
  x2Large: '36px',
  xLarge: '30px',
  large: '24px',
  medium: '20px',
  small: '16px',
  xSmall: '14px',
  x2Small: '12px',
  x3Small: '10px',
} as const

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
} as const

export const lineHeight = {
  xSmall: 1.2, // 44px, 56px, 76px, 96px
  small: 1.3, // 32px, 38px
  medium: 1.4, // 28px
  large: 1.5, // 18px, 20px, 24px
} as const

export const TYPOGRAPHY = {
  fonts,
  fontFamily,
  fontWeight,
  fontSize,
  lineHeight,
} as const
