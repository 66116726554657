import {
  HEADER_HEIGHT as GINGER_HEADER_HEIGHT,
  HEADER_MAX_WIDTH as GINGER_HEADER_MAX_WIDTH,
  MOBILE_HEADER_HEIGHT as GINGER_MOBILE_HEADER_HEIGHT,
  MEGA_MENU_Z_INDEX as GINGER_MEGA_MENU_Z_INDEX,
} from 'constants/design'

export const MEGA_MENU_Z_INDEX = GINGER_MEGA_MENU_Z_INDEX
export const HEADER_HEIGHT = GINGER_HEADER_HEIGHT
export const MOBILE_HEADER_HEIGHT = GINGER_MOBILE_HEADER_HEIGHT
export const HEADER_MAX_WIDTH = GINGER_HEADER_MAX_WIDTH
export const MOBILE_CTAS_CONTAINER_HEIGHT = 64

export const DEBOUNCE_THRESHOLD = 200
export const MENU_SCROLL_THROTTLE_INTERVAL = 100
export const TRACKING_THROTTLE_INTERVAL = 700

export const TOP_MENU_MAX_WIDTH_MOBILE = 498
export const SUB_MENU_COLUMN_GAP = 144
export const SUB_MENU_MIN_WIDTH = 220
export const SUB_MENU_MAX_IMAGE_WIDTH = 256
export const SUB_MENU_MAX_IMAGE_WIDTH_MOBILE = 328

export enum MegaMenuLinkType {
  Product = 'Product',
  Resource = 'Resource',
  Gallery = 'Gallery',
}

export enum MegaMenuLayout {
  Columns = 'Columns',
  Gallery = 'Gallery',
}

export enum MegaMenuType {
  Mobile = 'Mobile',
  Desktop = 'Desktop',
  Mimimal = 'Minimal',
}
