import styled, { css } from 'styled-components'

export const LogoTypeWrapper = styled.span`
  margin-left: 10.21px;
  overflow: hidden;
`
type TLogoTypeSvgProps = {
  hide: boolean
  animated: boolean
}
export const LogoTypeSvg = styled.svg<TLogoTypeSvgProps>`
  ${({ animated }) =>
    animated &&
    css`
      transition: transform 500ms, visibility 500ms;
    `}

  ${({ hide }) =>
    hide &&
    css`
      visibility: hidden;
    `}

  ${({ hide, animated }) =>
    hide &&
    animated &&
    css`
      transition: transform 500ms, visibility 500ms;
      transform: translateX(-100%);
    `}
`

export const StyledLink = styled.a`
  line-height: 0;
  text-decoration: none;

  &:hover,
  &:focus {
    ${LogoTypeSvg} {
      transform: translateX(0);
      visibility: visible;
    }
  }
`

export const LogoWrapper = styled.div`
  display: flex;
`
