import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { Section as GingerSection } from 'components/atoms/layout/'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import {
  fontSize,
  fontWeight,
} from '@typeform/ginger/dist/constants/typography'
import { Picture } from 'components/atoms/images'

export const Section = styled(GingerSection)`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || NeutralColors.Ink};
  display: none;
  flex: 1 1;
  overflow: hidden;

  ${mediaQueries.md`
    display: inherit;
  `}
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
`

const BREAKPOINT_XXL = 1920

export const Image = styled(Picture)`
  width: 100%;
  max-width: 366px;

  @media (min-width: ${BREAKPOINT_XXL}px) {
    max-width: 432px;
  }
`

export const Headline = styled.h1`
  color: ${NeutralColors.White};
  font-size: ${fontSize.x2Large};
  margin: 0 0 35px;
  width: 366px;
  font-weight: ${fontWeight.regular};
  line-height: 44px;
  text-align: center;

  @media (min-width: ${BREAKPOINT_XXL}) {
    font-size: ${fontSize.x3Large};
    font-weight: ${fontWeight.regular};
    line-height: 56px;
  }
`

export const Copyright = styled.p`
  color: ${NeutralColors.White};
  font-size: ${fontSize.xSmall};
  line-height: 20px;
  position: absolute;
  bottom: 24px;
`
