import React, { memo } from 'react'

const Globe = () => {
  return (
    <svg
      height='17'
      viewBox='0 0 20 20'
      width='17'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zM9 17.9C5.1 17.4 2 14 2 10c0-.6.1-1.2.2-1.8L7 13v1c0 1.1.9 2 2 2v1.9zm6.9-2.5c-.3-.8-1-1.4-1.9-1.4h-1v-3c0-.6-.4-1-1-1H6V8h2c.6 0 1-.4 1-1V5h2c1.1 0 2-.9 2-2v-.4c2.9 1.2 5 4.1 5 7.4 0 2.1-.8 4-2.1 5.4z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}

Globe.displayName = 'Globe'

export default memo(Globe)
