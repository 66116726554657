export const transparent = '#00000000'

export enum NeutralColors {
  White = '#FFFFFF',
  Ink = '#191919',
  GreyPaper = '#F7F7F6',
  Grey100 = '#EAEAE9',
  Grey200 = '#DCDCDB',
  Grey300 = '#CFCFCE',
  Grey400 = '#C2C2C1',
  Grey500 = '#A0A0A0',
  Grey600 = '#7F7F7F',
  Grey700 = '#5E5E5E',
  Grey800 = '#3D3D3C',
  Grey900 = '#191919',
}

export enum UIPalette {
  Red25 = '#FEF9F8',
  Red50 = '#FFF7F5',
  Red100 = '#FFEEEA',
  Red200 = '#FEE1DC',
  Red300 = '#FDCCC3',
  Red400 = '#F5ACA1',
  Red500 = '#F18174',
  Red600 = '#E36554',
  Red700 = '#C13B2F',
  Red800 = '#8D3B34',
  Red900 = '#7A2F29',
  Red1000 = '#4E211D',
  Orange25 = '#FDF9F5',
  Orange50 = '#FCF7ED',
  Orange100 = '#FBF1DF',
  Orange200 = '#F9E8C8',
  Orange300 = '#F4D39F',
  Orange400 = '#ECB462',
  Orange500 = '#DC8F32',
  Orange600 = '#CB7A2B',
  Orange700 = '#AE4E09',
  Orange800 = '#7E462A',
  Orange900 = '#6A3C24',
  Orange1000 = '#452A1C',
  Yellow25 = '#FCFAF4',
  Yellow50 = '#FBF9E4',
  Yellow100 = '#FBF7D0',
  Yellow200 = '#F7EEB0',
  Yellow300 = '#EDDE7D',
  Yellow400 = '#E0C32E',
  Yellow500 = '#C3A21D',
  Yellow600 = '#A88A15',
  Yellow700 = '#866609',
  Yellow800 = '#695320',
  Yellow900 = '#57461E',
  Yellow1000 = '#372F15',
  Green25 = '#F5FBF8',
  Green50 = '#F2FCF5',
  Green100 = '#E2F9E9',
  Green200 = '#C1F1D0',
  Green300 = '#A8E6BD',
  Green400 = '#79D29A',
  Green500 = '#45B574',
  Green600 = '#3A9C66',
  Green700 = '#047B48',
  Green800 = '#1E6243',
  Green900 = '#1A513A',
  Green1000 = '#103727',
  Blue25 = '#F7FBFE',
  Blue50 = '#F1F9FE',
  Blue100 = '#E7F4FD',
  Blue200 = '#D3EBFD',
  Blue300 = '#B5DDFC',
  Blue400 = '#8EC4F6',
  Blue500 = '#5FA6EC',
  Blue600 = '#3F8FE4',
  Blue700 = '#2269BF',
  Blue800 = '#2B5791',
  Blue900 = '#294A7A',
  Blue1000 = '#1B3150',
  Purple25 = '#FCF9FE',
  Purple50 = '#FBF6FE',
  Purple100 = '#F8F1FD',
  Purple200 = '#F3E4FB',
  Purple300 = '#E6CEF3',
  Purple400 = '#DAADF0',
  Purple500 = '#CA85E5',
  Purple600 = '#BA6DD7',
  Purple700 = '#9350AA',
  Purple800 = '#6E487A',
  Purple900 = '#633A6E',
  Purple1000 = '#472050',
  Emerald = '#026451',
}

export enum ForegroundColors {
  DefaultWhite = '#FFFFFF',
  DefaultMinor = '#C2C2C1',
  DefaultSubtle = '#7F7F7F',
  DefaultMuted = '#5E5E5E',
  DefaultDefault = '#3D3D3C',
  DefaultEmphasis = '#191919',
  InformativeMuted = '#3F8FE4',
  InformativeDefault = '#2269BF',
  InformativeEmphasis = '#2B5791',
  InformativeContrast = '#294A7A',
  CriticalMuted = '#E36554',
  CriticalDefault = '#C13B2F',
  CriticalEmphasis = '#8D3B34',
  CriticalContrast = '#7A2F29',
  CautionMuted = '#A88A15',
  CautionDefault = '#866609',
  CautionEmphasis = '#695320',
  CautionContrast = '#57461E',
  SuccessMuted = '#3A9C66',
  SuccessDefault = '#047B48',
  SuccessEmphasis = '#1E6243',
  SuccessContrast = '#1A513A',
}

export type TColorKeys =
  | keyof typeof NeutralColors
  | keyof typeof UIPalette
  | keyof typeof ForegroundColors

export type TColor = NeutralColors | UIPalette | ForegroundColors

export const Colors: Record<string, TColor> = {
  ...NeutralColors,
  ...UIPalette,
  ...ForegroundColors,
}
