import React, { memo } from 'react'
import SiteEchoProvider from 'utils/site-echo-provider'

import { BackgroundDropContainer, WidgetContainer } from './widget.styles'
import Auth from './auth'
import { TOktaConfig } from './types'

const WidgetSignUp = ({
  oktaConfig,
  isModalOpen,
  closeModal,
}: {
  oktaConfig: TOktaConfig
  isModalOpen: boolean
  closeModal: () => {}
}) => {
  const onClickBackground = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (e.target === e.currentTarget) {
      closeModal()
    }
  }

  return (
    <BackgroundDropContainer onClick={onClickBackground} isOpen={isModalOpen}>
      <SiteEchoProvider>
        <WidgetContainer isOpen={isModalOpen}>
          <Auth oktaConfig={oktaConfig} closeModal={closeModal} />
        </WidgetContainer>
      </SiteEchoProvider>
    </BackgroundDropContainer>
  )
}

export default memo(WidgetSignUp)
