import React, { memo } from 'react'
import * as PropTypes from 'prop-types'

const OktaValidationError = ({ error }) => {
  return (
    <div className='subschema-unsatisfied visible'>
      <p>
        <span role='presentation' aria-label='Error' />
        {error}
      </p>
    </div>
  )
}

OktaValidationError.propTypes = {
  error: PropTypes.string.isRequired,
}

export default memo(OktaValidationError)
