import React, { memo } from 'react'
import PropTypes from 'prop-types'

import PasswordValidation from './password-validation'

const OktaWidgetSignupCustomValidation = ({ isWidgetRendered }) => {
  return <PasswordValidation isWidgetRendered={isWidgetRendered} />
}

OktaWidgetSignupCustomValidation.propTypes = {
  isWidgetRendered: PropTypes.bool.isRequired,
}

export default memo(OktaWidgetSignupCustomValidation)
