import { useCallback, useEffect, useState } from 'react'
import {
  ONETRUST_EVENT,
  hasFunctionalConsent,
  hasPerformanceConsent,
  hasTargetingConsent,
} from '@typeform/js-tracking/lib/consents/consentUtil'

const useCookieSettings = () => {
  const [settings, setSettings] = useState({
    ready: false,
    areFunctionalCookiesAllowed: false,
    areAnalyticsCookiesAllowed: false,
    areAdvertisingAndProfilingCookiesAllowed: false,
  })

  const updateSettings = useCallback(() => {
    setSettings({
      ready: true,
      areFunctionalCookiesAllowed: hasFunctionalConsent(),
      areAnalyticsCookiesAllowed: hasPerformanceConsent(),
      areAdvertisingAndProfilingCookiesAllowed: hasTargetingConsent(),
    })
  }, [])

  useEffect(() => {
    updateSettings()

    window.addEventListener(ONETRUST_EVENT, updateSettings)

    return () => {
      window.removeEventListener(ONETRUST_EVENT, updateSettings)
    }
  }, [updateSettings])

  return settings
}

export default useCookieSettings
