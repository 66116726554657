import React, {
  memo,
  createContext,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import { hasConsentCookie } from '@typeform/js-tracking/lib/consents/consentUtil'
import ConsentListener from '@typeform/js-tracking/lib/consents/consentListener'
import Cookies from 'js-cookie'

import {
  OPTANON_COOKIE_BANNER_CLOSED,
  TF_USER_EMAIL,
  TF_USER_ID,
} from '../../constants/cookies'

const defaultContext = {
  isEphemeral: false,
  user: undefined,
  isCookieBannerVisible: undefined,
  device: undefined,
}

export const AppContext = createContext({ ...defaultContext })

export const useAppContext = () => useContext(AppContext)

export const AppProvider = memo(({ isEphemeral, device, children }) => {
  const [app, setApp] = useState({ isEphemeral, ...defaultContext })

  const handleConsentUpdate = useCallback(() => {
    if (hasConsentCookie()) {
      setApp(app => ({
        ...app,
        isCookieBannerVisible: false,
      }))
    }
  }, [])

  useEffect(() => {
    let user = null
    const isCookieBannerVisible = !Boolean(
      Cookies.get(OPTANON_COOKIE_BANNER_CLOSED)
    )
    const userId = Cookies.get(TF_USER_ID)

    if (userId) {
      user = {
        id: userId,
        email: Cookies.get(TF_USER_EMAIL) ?? null,
      }
    }

    setApp(app => ({
      ...app,
      user,
      isCookieBannerVisible,
      device,
    }))
  }, [device])

  return (
    <AppContext.Provider value={app}>
      {app.isCookieBannerVisible && (
        <ConsentListener onConsentUpdate={handleConsentUpdate} />
      )}
      {children}
    </AppContext.Provider>
  )
})

AppProvider.propTypes = {
  isEphemeral: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  device: PropTypes.string.isRequired,
}
