import React from 'react'
import PropTypes from 'prop-types'
import { htmlToReactSanitizer } from '@typeform/text-to-jsx-sanitizer'

import { Checkbox, CheckboxLabel } from './radio-toggle-group.styles'

const InputCheckboxGdpr = ({
  label,
  id,
  value,
  unsafeLabelHTML,
  onChange,
  ...props
}) => {
  const labelId = `label-${id}`

  const handleCheck = () => {
    onChange(id, !value)
  }

  return (
    <CheckboxLabel className='checkbox-label' id={labelId}>
      <Checkbox
        id={id}
        className='gdpr-checkbox'
        aria-labelledby={labelId}
        checked={value}
        data-checked={value}
        data-field-id={id}
        data-testid={id}
        onChange={handleCheck}
        {...props}
      />
      {htmlToReactSanitizer(label, { allowHyperlinks: unsafeLabelHTML })}
    </CheckboxLabel>
  )
}

InputCheckboxGdpr.displayName = 'InputCheckboxGdpr'

InputCheckboxGdpr.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.bool,
  unsafeLabelHTML: PropTypes.bool,
  onChange: PropTypes.func,
}

InputCheckboxGdpr.defaultProps = {
  value: false,
  unsafeLabelHTML: false,
  onChange: null,
}

export default InputCheckboxGdpr
