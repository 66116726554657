import { memo, SVGProps } from 'react'

const CaretDown = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill='none'
      height='5'
      viewBox='0 0 9 5'
      width='9'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        clipRule='evenodd'
        d='M4.35156 2.80708L2.33394 0.789472C1.78653 0.24205 0.898985 0.242052 0.351563 0.789472L4.35156 4.78946L8.35156 0.789472C7.80411 0.242052 6.91658 0.242052 6.36917 0.789472L4.35156 2.80708Z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  )
}

CaretDown.displayName = 'CaretDown'

export default memo(CaretDown)
