import { DEFAULT_AUDIENCES } from 'components/molecules/banner/constants'
import { TExperiments } from 'types/experiments'
import { TBanner } from 'components/molecules/banner/banner.types'

import {
  DEFAULT_AUDIENCES_CONFIG,
  DISMISS_ACTIVE_BANNER_EVENT_NAME,
} from './constants'
import { TAudiencesConfig } from './banner-provider.types'

export const dispatchDismissActiveBannerEvent = () => {
  window.dispatchEvent(new Event(DISMISS_ACTIVE_BANNER_EVENT_NAME))
}

export const testAuthenticationAudience = (
  isUserAuthenticated: boolean,
  isAuthenticationRequired: boolean | null
) => {
  return (
    isAuthenticationRequired === null ||
    isAuthenticationRequired === isUserAuthenticated
  )
}

export const testCookieBannerAudience = (
  isCookieBannerVisible: boolean,
  shouldCookieBannerBeVisible: boolean | null
) => {
  return (
    shouldCookieBannerBeVisible === null ||
    shouldCookieBannerBeVisible === isCookieBannerVisible
  )
}

export const testLocalesAudience = (
  localeConfig: TAudiencesConfig['locale'],
  bannerLocales: TAudiencesConfig['locale'][] | null
) => {
  return (
    bannerLocales === null ||
    (Array.isArray(bannerLocales) && bannerLocales.includes(localeConfig))
  )
}

export const testExperimentVariationAudience = (
  experiments: TExperiments = {},
  bannerExperimentVariations: string[] | null
) => {
  const experimentObjects = Object.values(experiments)

  return (
    bannerExperimentVariations === null ||
    (Array.isArray(bannerExperimentVariations) &&
      bannerExperimentVariations.some(bannerVariationId => {
        return experimentObjects.find(
          ({ variationId }) => bannerVariationId === variationId
        )
      }))
  )
}

export const testUrlsAudience = (
  currentUrl: TAudiencesConfig['currentUrl'],
  urls: string[] | null
) => {
  if (urls === null) {
    return true
  }
  if (!Array.isArray(urls)) {
    return false
  }
  // Separate the urls into includes and excludes
  const groupedUrls = urls.reduce<{
    includes: string[]
    excludes: string[]
  }>(
    (prev, url) => {
      const copy = { ...prev }
      if (url.startsWith('!')) {
        copy.excludes.push(url.substring(1))
      } else {
        copy.includes.push(url)
      }
      return copy
    },
    {
      includes: [],
      excludes: [],
    }
  )
  const iterator = (url: TAudiencesConfig['currentUrl']) => {
    if (!url || !currentUrl) {
      return false
    }

    if (url.endsWith('*')) {
      return currentUrl.startsWith(url.replace('*', ''))
    } else {
      return currentUrl === url
    }
  }
  const { includes, excludes } = groupedUrls
  // If there are any excluded urls then they take precendence over included ones.
  if (excludes.length > 0) {
    const isExcluded = Boolean(excludes.find(iterator))
    return !isExcluded
  }
  const isIncluded = Boolean(includes.find(iterator))
  return isIncluded
}

export const testUtils = {
  testLocalesAudience,
  testCookieBannerAudience,
  testAuthenticationAudience,
  testUrlsAudience,
  testExperimentVariationAudience,
}

export const fulfillsClientSideAudiences = (
  banner: TBanner,
  audiencesConfig: TAudiencesConfig
) => {
  const { audiences = {} } = banner
  const { isUserAuthenticated = null, isCookieBannerVisible } = audiencesConfig

  const { testAuthenticationAudience, testCookieBannerAudience } = testUtils
  const isAuthenticationRequired =
    audiences.isAuthenticationRequired ??
    DEFAULT_AUDIENCES.isAuthenticationRequired

  const isAuthenticationAudienceValid = testAuthenticationAudience(
    isUserAuthenticated === true,
    isAuthenticationRequired
  )

  const shouldCookieBannerBeVisible =
    audiences.shouldCookieBannerBeVisible ??
    DEFAULT_AUDIENCES.shouldCookieBannerBeVisible
  const isCookieBannerAudienceValid = testCookieBannerAudience(
    isCookieBannerVisible === true,
    shouldCookieBannerBeVisible
  )

  return isAuthenticationAudienceValid && isCookieBannerAudienceValid
}

export const fulfillsGeneralAudiences = (
  banner: TBanner,
  audiencesConfig: TAudiencesConfig
) => {
  const { audiences = {} } = banner
  const { locale, currentUrl, experiments } = audiencesConfig

  const {
    testLocalesAudience,
    testUrlsAudience,
    testExperimentVariationAudience,
  } = testUtils

  const locales = audiences.locales ?? DEFAULT_AUDIENCES.locales
  const isLocalesAudienceValid = testLocalesAudience(locale, locales)

  const experimentVariations =
    audiences.experimentVariations ?? DEFAULT_AUDIENCES.experimentVariations
  const isExperimentVariationAudienceValid = testExperimentVariationAudience(
    experiments,
    experimentVariations
  )

  const urls = audiences.urls ?? DEFAULT_AUDIENCES.urls
  const isUrlsAudienceValid = testUrlsAudience(currentUrl, urls)

  return (
    isLocalesAudienceValid &&
    isUrlsAudienceValid &&
    isExperimentVariationAudienceValid
  )
}

export const getTargetedBanners = (
  banners: TBanner[],
  audiencesConfig: TAudiencesConfig
) => {
  const audiencesConfigWithDefaults = {
    ...DEFAULT_AUDIENCES_CONFIG,
    ...audiencesConfig,
  }

  const allAudiencesBanners = banners.filter(
    banner =>
      fulfillsClientSideAudiences(banner, audiencesConfigWithDefaults) &&
      fulfillsGeneralAudiences(banner, audiencesConfigWithDefaults)
  )

  const generalAudiencesBanners = banners.filter(banner =>
    fulfillsGeneralAudiences(banner, audiencesConfigWithDefaults)
  )

  const shouldWaitForClientSide =
    allAudiencesBanners.length !== generalAudiencesBanners.length

  if (shouldWaitForClientSide) {
    return []
  }

  return banners.filter(
    banner =>
      fulfillsClientSideAudiences(banner, audiencesConfigWithDefaults) &&
      fulfillsGeneralAudiences(banner, audiencesConfigWithDefaults)
  )
}
