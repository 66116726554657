export type TSocialProviderName = 'google' | 'microsoft' | 'linkedin'

export type TSocialProvider = {
  name: TSocialProviderName
  icon: string
}

export const SOCIAL_PROVIDERS: TSocialProvider[] = [
  {
    name: 'google',
    icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAALGPC/xhBQAAAq9JREFUOBGlVU1IVFEUPue8azPOKOqoUUkGIUISmpCmoCmYE5a5shGDFrWqXRQm/a0CyxZug9lEYYtxQioJpsmYoo1/UZmtKzfVjGKRDjrOvNN9A+/NnUnN6sLjfuec7/vunbn3nYewxmBmjLjr2lDXu2W5HBDLGNgGDF8RYFLGI0W79/nR613NlMt6+oi01nWArt+QBhXplYwI8SMRnC8KTjxUK2mG4UO1V5D5OgOk5VVBJtaQTheOjt8x88IEkZbafvlTL0qzTQ9EeOmivCFVQEYQaa1vAuAetSDJuvyv7gnSGrJynQVb7DYXkmgGwrsGzzArooKjGAwupek41CwWvHPT8XD2nlQBF0HAka1PJ16lcikUdtccLIaC15lmBgPjIVs7r/DIUmAnxN67kipNI3dhcPxZymLzSIDOJ0AwONtnQZREITZV/Mblm/4nM2NZwQx7zfVt1XNgr57vB5+ZSc0tfYvvUtG6aEAgcKl6spoGH9aiy4Ur18qrOUSqSJ6ymvw/rNuJAWdVk4VEVpUa/w1mxDDJ+zRjih4t74KO+cMnzVidC/OduZmP1E6rHAT6lLw2Kwl4fOtnJT5ZKU3WNSL3uMe/4Um7+6IH4qyPWYaILLIc20iD+sDZHw2fTTODkGAervV3NlrkDNDSt1yeAB5OT3Mo2IPhZBOoGTrexKyHZHtSmgLKVw8GBZA3B3mG8uzx7wuxMsCEB2Mlp7K/nNtOq8WWJwmqH+11jFkG+32d/SCbg8X4A0DdAdnfzoCIVhnv9cDzyzkXDIl1baa6HvQS0VW5K/VarmvLFIXojgFezQ8MNl5yWo3F2qGprPF5jgHoN2Ur27DByl3NA9K1SY//tqk15t8MjaTxCagb6mrTQe+W2y2XJOMTYEeGMCO8JcaAg/D+C49/0eCr4xdWP+SQWHT2AwAAAABJRU5ErkJggg==',
  },
  {
    name: 'microsoft',
    icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsSAAALEgHS3X78AAAAVElEQVQ4jWP8//8/A7UBC8i8z4HKBE3mXX+XsWE3I0F1Da7/GZmo7kwGBoZRQ0cNpTKgXY5iXPqBoMn/owUYGXYRzlEMbqM5atTQoWEo9XMUAwMDAGSOGCOVddy/AAAAAElFTkSuQmCC',
  },
]

export const EXPERIMENTAL_LINKEDIN_PROVIDER: TSocialProvider = {
  name: 'linkedin',
  icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5LjY1IDNINC4zNUMzLjk5MTk2IDMgMy42NDg1OCAzLjE0MjIzIDMuMzk1NDEgMy4zOTU0MUMzLjE0MjIzIDMuNjQ4NTggMyAzLjk5MTk2IDMgNC4zNVYxOS42NUMzIDIwLjAwOCAzLjE0MjIzIDIwLjM1MTQgMy4zOTU0MSAyMC42MDQ2QzMuNjQ4NTggMjAuODU3OCAzLjk5MTk2IDIxIDQuMzUgMjFIMTkuNjVDMjAuMDA4IDIxIDIwLjM1MTQgMjAuODU3OCAyMC42MDQ2IDIwLjYwNDZDMjAuODU3OCAyMC4zNTE0IDIxIDIwLjAwOCAyMSAxOS42NVY0LjM1QzIxIDMuOTkxOTYgMjAuODU3OCAzLjY0ODU4IDIwLjYwNDYgMy4zOTU0MUMyMC4zNTE0IDMuMTQyMjMgMjAuMDA4IDMgMTkuNjUgM1pNOC40IDE4LjNINS43VjEwLjJIOC40VjE4LjNaTTcuMDUgOC42MjVDNi43NDA1NiA4LjYxNjE2IDYuNDQwNiA4LjUxNjMyIDYuMTg3NTggOC4zMzc5N0M1LjkzNDU2IDguMTU5NjIgNS43Mzk3IDcuOTEwNjYgNS42MjczNyA3LjYyMjJDNS41MTUwMyA3LjMzMzc0IDUuNDkwMTkgNy4wMTg1NyA1LjU1NTk1IDYuNzE2MDdDNS42MjE3IDYuNDEzNTggNS43NzUxNSA2LjEzNzE2IDUuOTk3MSA1LjkyMTM4QzYuMjE5MDYgNS43MDU1OSA2LjQ5OTY4IDUuNTU5OTkgNi44MDM5MSA1LjUwMjc4QzcuMTA4MTQgNS40NDU1NiA3LjQyMjQ4IDUuNDc5MjcgNy43MDc2NiA1LjU5OTY5QzcuOTkyODQgNS43MjAxIDguMjM2MjIgNS45MjE4OSA4LjQwNzM3IDYuMTc5ODNDOC41Nzg1MyA2LjQzNzc4IDguNjY5ODcgNi43NDA0NCA4LjY3IDcuMDVDOC42NjI4OSA3LjQ3MzMxIDguNDg4NSA3Ljg3NjYgOC4xODQ5NSA4LjE3MTczQzcuODgxMzkgOC40NjY4NSA3LjQ3MzM1IDguNjI5ODIgNy4wNSA4LjYyNVpNMTguMyAxOC4zSDE1LjZWMTQuMDM0QzE1LjYgMTIuNzU2IDE1LjA2IDEyLjI5NyAxNC4zNTggMTIuMjk3QzE0LjE1MjIgMTIuMzEwNyAxMy45NTExIDEyLjM2NDkgMTMuNzY2MyAxMi40NTY2QzEzLjU4MTUgMTIuNTQ4MiAxMy40MTY2IDEyLjY3NTUgMTMuMjgxMSAxMi44MzFDMTMuMTQ1NyAxMi45ODY2IDEzLjA0MjIgMTMuMTY3NCAxMi45NzY4IDEzLjM2M0MxMi45MTE0IDEzLjU1ODYgMTIuODg1MyAxMy43NjUyIDEyLjkgMTMuOTcxQzEyLjg5NTUgMTQuMDEyOSAxMi44OTU1IDE0LjA1NTEgMTIuOSAxNC4wOTdWMTguM0gxMC4yVjEwLjJIMTIuODFWMTEuMzdDMTMuMDczMyAxMC45Njk1IDEzLjQzNSAxMC42NDMzIDEzLjg2MDUgMTAuNDIyN0MxNC4yODYgMTAuMjAyMSAxNC43NjEgMTAuMDk0NCAxNS4yNCAxMC4xMUMxNi42MzUgMTAuMTEgMTguMjY0IDEwLjg4NCAxOC4yNjQgMTMuNDA0TDE4LjMgMTguM1oiIGZpbGw9IiMwQzVCQkEiLz4KPC9zdmc+Cg==',
}
