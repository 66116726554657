import React, { useContext } from 'react'
import { DEFAULT_LANGUAGE_CODE } from '@typeform/ginger/dist/lib/i18n'

export const LocaleContext = React.createContext({
  locale: DEFAULT_LANGUAGE_CODE,
  language: DEFAULT_LANGUAGE_CODE,
})

LocaleContext.displayName = 'LocaleContext'

export const useLocaleContext = () => useContext(LocaleContext)
