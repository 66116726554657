import styled, { css } from 'styled-components'
import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { BOX_SHADOWS } from '@typeform/ginger/dist/constants/shadows'

import { backgroundColors, SelectPositions } from './constants'

const Distribute = styled.div`
  display: flex;
`

export const Text = styled.span<{
  locale?: string
  label?: string
  href?: string
}>`
  color: inherit;
  font-size: ${fontSize.xSmall};
  line-height: ${lineHeight.large};
  font-weight: ${fontWeight.regular};
`

export const Prefix = styled.div`
  margin-right: 8px;
  line-height: 0;
`

export const Suffix = styled.div`
  line-height: 0;
  margin-left: 4px;
`

// Useful for styles overrides
export const Container = styled.div`
  line-height: 0;
  position: relative;
`

export const focusedBgColor = '#f0f0f0'

type TOptionLinkContainerProps = {
  isFirstLanguageSelected: boolean
}

export const OptionLinkContainer = styled(
  Distribute
)<TOptionLinkContainerProps>`
  appearance: none;
  margin: 0;
  border: none;
  max-width: 100%;
  text-align: left;
  color: inherit;
  cursor: pointer;
  min-height: 32px;
  background-color: ${({ isFirstLanguageSelected }) =>
    isFirstLanguageSelected ? focusedBgColor : backgroundColors.positive};

  &:hover,
  &:focus[data-focus-visible] {
    background-color: ${focusedBgColor};
  }

  text-decoration: none;
  outline: none;
  width: 100%;
  &[aria-disabled='true'] {
    pointer-events: none;
    cursor: default;
  }
  &:hover {
    text-decoration: none;
  }
`

export const OptionLink = styled(Text)`
  color: ${NeutralColors.Ink};
  line-height: 2.4;
  font-weight: 200;
  font-size: ${fontSize.small};
  padding: 6px 23px;
`

export const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  color: inherit;

  &:hover {
    opacity: 0.8;
  }
`

type TMenuWrapperProps = {
  position: SelectPositions
  isOpen: boolean
}

export const MenuWrapper = styled.div<TMenuWrapperProps>`
  background-color: ${NeutralColors.White};
  border-radius: 8px;
  box-shadow: ${BOX_SHADOWS.medium};
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  transform: scale(0.85);
  transform-origin: bottom left;
  will-change: transform, opacity;
  visibility: hidden;
  opacity: 0;
  transition: transform 150ms ease-in, opacity 150ms ease-in,
    visibility 150ms ease-in;

  ${({ position }: { position: SelectPositions }) =>
    position === SelectPositions.Top ? 'bottom: 2rem' : 'top: 2rem'};

  ${({ isOpen }: { isOpen: boolean }) =>
    isOpen &&
    css`
      pointer-events: initial;
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    `}
`
