import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import React from 'react'

type TCloseIcon = {
  width?: number
  height?: number
  fill?: string
}
export const CloseIcon = ({
  width = 17,
  height = 17,
  fill = NeutralColors.Ink,
}: TCloseIcon) => {
  return (
    <svg
      fill='none'
      height={height}
      viewBox='0 0 17 17'
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Close</title>
      <path
        fill={fill}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.4248 16.1751C15.9253 16.6745 15.1153 16.6745 14.6158 16.1751L1.07485 2.63402C0.57531 2.13449 0.57531 1.32455 1.07485 0.825002C1.5744 0.325461 2.38432 0.325461 2.88387 0.825014L16.4248 14.366C16.9244 14.8656 16.9244 15.6755 16.4248 16.1751Z'
      />
      <path
        fill={fill}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.07485 16.1751C0.57531 15.6755 0.57531 14.8656 1.07485 14.366L14.6158 0.825012C15.1153 0.32546 15.9253 0.32546 16.4248 0.825012C16.9244 1.32455 16.9244 2.13448 16.4248 2.63402L2.88387 16.1751C2.38432 16.6745 1.5744 16.6745 1.07485 16.1751Z'
      />
    </svg>
  )
}
