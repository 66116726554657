import {
  NeutralColors,
  UIPalette,
} from '@typeform/ginger/dist/constants/colors'
import React from 'react'
import { CloseIcon } from 'components/atoms/common/close-icon'

import {
  StyledContainer,
  StyledCtaPrimaryLink,
  StyledMessage,
  StyledMessageContainer,
} from './banner-primary.styles'
import { StyledDismissContainer } from './base.styles'
import { TBannerCtaLink, TBanner } from './banner.types'

const CtaPrimary = ({
  backgroundColor,
  color,
  onClick,
  target,
  text,
  url,
}: TBannerCtaLink) => {
  if (!url || !text) {
    return null
  }

  return (
    <StyledCtaPrimaryLink
      href={url}
      onClick={onClick}
      onAuxClick={onClick}
      rel={target ? 'noopener noreferrer' : undefined}
      target={target}
      $color={color}
      $backgroundColor={backgroundColor}
      data-gtm-event='cta_promo'
    >
      {text}
    </StyledCtaPrimaryLink>
  )
}

const BannerPrimary = ({
  backgroundColor = UIPalette.Emerald,
  textColor = NeutralColors.White,
  cta,
  dismissible = false,
  dismissButtonLabel = 'Dismiss banner',
  message = '',
  onDismiss,
}: TBanner) => {
  return (
    <StyledContainer
      $backgroundColor={backgroundColor}
      $color={textColor}
      $dismissible={dismissible}
    >
      <StyledMessageContainer>
        <StyledMessage dangerouslySetInnerHTML={{ __html: message }} />
        <CtaPrimary
          backgroundColor={backgroundColor}
          color={textColor}
          {...cta}
        />
      </StyledMessageContainer>

      {dismissible && (
        <StyledDismissContainer
          data-testid='banner-primary-dismissible-icon'
          onClick={onDismiss}
          aria-label={dismissButtonLabel}
        >
          <CloseIcon fill={textColor} />
        </StyledDismissContainer>
      )}
    </StyledContainer>
  )
}

export default BannerPrimary
