import React from 'react'

import { JS_CLASSNAME, NO_JS_CLASSNAME } from './constants'

const JS_SCRIPT = `if("undefined"!=typeof window&&"undefined"!=typeof document){const e=document.documentElement;e.classList.add("${JS_CLASSNAME}"),e.classList.remove("${NO_JS_CLASSNAME}")}`

const JSFeatureDetector = () => {
  return (
    /* Avoiding async/defer so that the script is executed before the page is rendered.  */
    <script
      data-testid='js-feature-detector'
      dangerouslySetInnerHTML={{
        __html: JS_SCRIPT,
      }}
    />
  )
}

JSFeatureDetector.displayName = 'JSFeatureDetector'

export default JSFeatureDetector
