import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'

export enum BannerType {
  Default = 'default',
  Pricing = 'pricing',
}

export enum DisplayType {
  Fixed = 'Fixed',
  Sticky = 'Sticky',
}

export enum LocaleAudience {
  // @ts-expect-error will be fixed when LOCALES is migrated from ginger
  En = LANGUAGE_CODES.english,
  // @ts-expect-error will be fixed when LOCALES is migrated from ginger
  Es = LANGUAGE_CODES.spanish,
}

export const DEFAULT_AUDIENCES = {
  shouldCookieBannerBeVisible: null,
  isAuthenticationRequired: null,
  locales: null,
  urls: null,
  experimentVariations: null,
}

export const DEFAULT_RENDER_DELAY = 0
