/**
 * Generates a random id of length n.
 * @param {number} n - The length of the id generated.
 * @return {string} The generated id.
 */
export const generateId = (n = 22) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let text = ''
  for (let i = 0; i < n; i++) {
    text += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return text
}
