import camelCase from 'lodash.camelcase'

import {
  DATA_FIELD_ID,
  CONSENTS_OPTIONS,
  CONSENTS_FIELDS_EMBED_DOM,
  CHECKBOX_DATA_SELECTOR,
  MARKETING,
  TAILOR_NEEDS_AGREED,
  THIRD_PARTIES_AGREED,
  SIGNUP_CONSENTS_STORAGE_KEY,
} from './constants'

/**
 * Generate html data attribute to store consents in the DOM.
 * This is because the state in <Consents /> cannot be lifted up
 * as this causes the widget to re-render.
 * The final result will be to sync <Consents /> state into the DOM
 * leveraging data attributes (data-marketing=true|false, etc)
 */
export const generateConsentsDataFields = fields => {
  return CONSENTS_FIELDS_EMBED_DOM.reduce((acc, consentId) => {
    acc[`data-${consentId}`.toLowerCase()] = Boolean(fields[consentId])
    return acc
  }, {})
}

export const areTermsAndConditionsValid = () => {
  const checkboxes = [...document.querySelectorAll(CHECKBOX_DATA_SELECTOR)]
  return checkboxes.every(element => element.dataset?.['checked'] === 'true')
}

const formatConsentKeyForBackend = key => camelCase(key)

export const getConsentsStatus = () => {
  const radioButtonsContainer = document.querySelector(
    `[${DATA_FIELD_ID}=${CONSENTS_OPTIONS}]`
  )
  return [MARKETING, TAILOR_NEEDS_AGREED, THIRD_PARTIES_AGREED]
    .map(
      field =>
        `"${formatConsentKeyForBackend(field)}":${
          radioButtonsContainer?.dataset?.[field]
        }`
    )
    .join(',')
}

export const getAcceptedConsents = () => ({
  [MARKETING]: true,
  [TAILOR_NEEDS_AGREED]: true,
  [THIRD_PARTIES_AGREED]: true,
})

export const storeConsentsStatus = consentsFields => {
  window.sessionStorage.setItem(
    SIGNUP_CONSENTS_STORAGE_KEY,
    JSON.stringify(consentsFields)
  )
}
