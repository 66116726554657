import { API_BASE_URL } from 'constants/url'

import { TUser } from './types'

export * from './types'

const TYPEFORM_USER_ENDPOINT = `${API_BASE_URL}/me`

export const getUser = (): Promise<TUser> => {
  return fetch(TYPEFORM_USER_ENDPOINT, {
    credentials: 'include',
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(
          `Error happened while fetching user details with the status ${response.status}:${response.statusText}`
        )
      }
      return response.json()
    })
    .then(({ email, alias, tracking_id, user_id }) => ({
      email,
      name: alias,
      userId: user_id,
      trackingId: tracking_id,
    }))
}
