import { StyledVerticalCaretSvg } from './vertical-caret.styles'

const VerticalCaret = () => (
  <StyledVerticalCaretSvg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='16'
    fill='none'
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      d='M12.75 5.33 8.854 9.226a.5.5 0 0 1-.708 0L4.25 5.33'
    />
  </StyledVerticalCaretSvg>
)

export default VerticalCaret
