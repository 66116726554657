import { css } from 'styled-components'

import { Axis, Origin } from './constants'
import { getAnimationKeyframe } from './animated-container.styles'
import { TEasing, TAnimationProps } from './animated-container.types'

export const Easing: TEasing = {
  ease: 'ease',
  easeIn: 'ease-in',
  easeOut: 'ease-out',
  easeInOut: 'ease-in-out',
  outExpo: 'cubic-bezier(0.16, 1, 0.3, 1)',
  inOutExpo: 'cubic-bezier(0.87, 0, 0.13, 1)',
  outSine: 'cubic-bezier(0.61, 1, 0.88, 1)',
  inOutSine: 'cubic-bezier(0.37, 0, 0.63, 1)',
  outQuart: 'cubic-bezier(0.25, 1, 0.5, 1)',
  inOutQuart: 'cubic-bezier(0.76, 0, 0.24, 1)',
}

export const SHARED_ANIMATION_OPTIONS: TAnimationProps = {
  duration: '0.3s',
  ease: Easing.easeIn,
  delay: '0s',
}

export const getOrigin = (
  originY: string = Origin['Center'],
  originX: string = Origin['Center']
) => `${originY} ${originX}`

export const getAnimationProps = ({
  property,
  duration,
  ease,
  delay,
  base,
  active,
}: TAnimationProps) => ({
  property,
  animationKeyframe: getAnimationKeyframe({
    base,
    active,
  }),
  duration,
  ease,
  delay,
  base,
  active,
})

const fade = (customOptions = {}) => {
  const options = {
    from: 0,
    to: 1,
    ...SHARED_ANIMATION_OPTIONS,
    ...customOptions,
  }

  const { from, to } = options

  const props = {
    ...options,
    property: 'opacity',
    base: css`
      opacity: ${from};
    `,
    active: css`
      opacity: ${to};
    `,
  }

  return getAnimationProps(props)
}

const slide = (customOptions = {}) => {
  const options = {
    ...SHARED_ANIMATION_OPTIONS,
    ...customOptions,
  }

  const { from, to, axis } = options

  const translate = axis === Axis.X ? 'translateX' : 'translateY'

  const props: TAnimationProps = {
    ...options,
    property: 'transform',
    base: css`
      transform: ${translate}(${from});
    `,
    active: css`
      transform: ${translate}(${to});
    `,
  }

  return getAnimationProps(props)
}

const scale = (customOptions = {}) => {
  const options = {
    from: 0,
    to: 1,
    originY: Origin['Top'],
    originX: Origin['Left'],
    ...SHARED_ANIMATION_OPTIONS,
    ...customOptions,
  }

  const { from, to, axis, originY, originX } = options

  let scale = 'scale'
  if (axis) {
    scale = axis === Axis.X ? 'scaleX' : 'scaleY'
  }

  const transformOrigin = getOrigin(originY, originX)

  const props = {
    ...options,
    property: 'transform',
    base: css`
      transform-origin: ${transformOrigin};
      transform: ${scale}(${from});
    `,
    active: css`
      transform-origin: ${transformOrigin};
      transform: ${scale}(${to});
    `,
  }

  return getAnimationProps(props)
}

export const Animations = {
  fade,
  scale,
  slide,

  fadeIn: (options: TAnimationProps = {}) =>
    fade({ from: 0, to: 1, ...options }),
  fadeOut: (options: TAnimationProps) => fade({ from: 1, to: 0, ...options }),

  slideFromLeft: ({ from, ...options }: TAnimationProps) =>
    slide({ from: `-${from}`, to: 0, axis: Axis.X, ...options }),
  slideFromRight: ({ from, ...options }: TAnimationProps) =>
    slide({ from, to: 0, axis: Axis.X, ...options }),
  slideFromTop: ({ from, ...options }: TAnimationProps) =>
    slide({ from: `-${from}`, to: 0, axis: Axis.Y, ...options }),
  slideFromBottom: ({ from, ...options }: TAnimationProps = {}) =>
    slide({ from, to: 0, axis: Axis.Y, ...options }),
  slideToLeft: ({ to, ...options }: TAnimationProps) =>
    slide({ from: 0, to: `-${to}`, axis: Axis.X, ...options }),
  slideToRight: ({ to, ...options }: TAnimationProps) =>
    slide({ from: 0, to, axis: Axis.X, ...options }),
  slideToTop: ({ to, ...options }: TAnimationProps) =>
    slide({ from: 0, to: `-${to}`, axis: Axis.Y, ...options }),
  slideToBottom: ({ to, ...options }: TAnimationProps) =>
    slide({ from: 0, to, axis: Axis.Y, ...options }),

  scaleUp: (options: TAnimationProps) =>
    scale({
      from: 0,
      to: 1,
      axis: Axis.Y,
      originY: Origin['Bottom'],
      originX: Origin['Center'],
      ...options,
    }),
  scaleDown: (options: TAnimationProps) =>
    scale({
      from: 1,
      to: 0,
      axis: Axis.Y,
      originY: Origin['Bottom'],
      originX: Origin['Center'],
      ...options,
    }),
}
