import { EXIT_INTENT_FORM } from 'constants/query-strings'

import React, { ReactNode, memo, useEffect, useMemo, useState } from 'react'
import dynamic from 'next/dynamic'
import { TTypeformEmbed } from 'components/landing-page/contentful-media/types'
import { Device } from '@typeform/ginger/dist/constants/device'
import { hasFeedbackFormResponse } from 'components/floating-feedback-form/floating-feedback-form.helpers'
import { useAppContext } from 'components/app-context'
import usePageScrollThreshold from 'hooks/use-page-scroll-threshold'
import { trackExperimentEvent } from 'components/tracking'

import Banners from '../../banners'

const ExitIntentSurvey = dynamic(() => import('components/exit-intent-survey'))

const FloatingFeedbackFormLoader = dynamic(
  () =>
    import('components/floating-feedback-form/floating-feedback-form.loader'),
  { ssr: false }
)

type TPageContainerProps = {
  children: ReactNode
  currentUrl: string
  // TODO: Update banner type when banner provider is moved to public main site
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  banners: any[]
  showBanners: boolean
  feedbackForm?: {
    form: TTypeformEmbed
    trackingItemName?: string
    scrollPercentage?: number
  }
  visitorType?: string
  userCountryCode?: string
  language?: string
  device?: string
}

const PageContainer = ({
  children,
  banners,
  currentUrl,
  showBanners,
  feedbackForm,
  visitorType,
  userCountryCode,
  language,
  device,
  ...rest
}: TPageContainerProps) => {
  const [exitIntentForm, setExitIntentForm] = useState<string | null>(null)
  const { isCookieBannerVisible } = useAppContext()

  usePageScrollThreshold(scrollThreshold => {
    trackExperimentEvent({
      name: `page_scrolled_${scrollThreshold * 100}_percent`,
    })
  })

  const shouldShowFeedbackForm = useMemo(() => {
    if (typeof window === 'undefined') {
      return false
    }

    if (device === Device.Mobile) {
      return false
    }

    if (isCookieBannerVisible) {
      return false
    }

    return !hasFeedbackFormResponse()
  }, [device, isCookieBannerVisible])

  useEffect(() => {
    const url = new URL(document.location.href)
    const formId = url.searchParams.get(EXIT_INTENT_FORM)

    if (formId) {
      setExitIntentForm(formId)
    }
  }, [])

  return (
    <Banners
      banners={showBanners ? banners : []}
      currentUrl={currentUrl}
      {...rest}
    >
      {children}
      {exitIntentForm && <ExitIntentSurvey id={exitIntentForm} />}
      {feedbackForm && shouldShowFeedbackForm && (
        <FloatingFeedbackFormLoader
          feedbackForm={feedbackForm}
          visitorType={visitorType}
          userCountryCode={userCountryCode}
          currentUrl={currentUrl}
          language={language}
        />
      )}
    </Banners>
  )
}

PageContainer.defaultProps = {
  banners: [],
  currentUrl: null,
  showBanners: true,
}

export default memo(PageContainer)
