// Derived from Kitt
// https://github.com/Typeform/kitt/blob/05d879218730c3562ab6a47329e5e12b4e2acd35/src/components/avatar/avatar.tsx
import { GRID_UNIT } from '@typeform/ginger/dist/constants/design'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import React, { useMemo } from 'react'

import getAvatarSources from './helpers/get-avatar-sources'
import { getElementByText } from './helpers/getElementByText'
import avatarColors from './helpers/avatar-colors'
import { avatarBackgrounds } from './helpers/avatar-background'
import { getInitials } from './helpers/getInitials'
import {
  AvatarText,
  AvatarWrapper,
  PictureWrapper,
  ShapeWrapper,
  Skeleton,
  Gravatar,
} from './avatar.styles'
import { TAvatarProps, AvatarSize } from './avatar.types'

const Avatar = ({
  email,
  name = '',
  size = AvatarSize.Medium,
  ...props
}: TAvatarProps) => {
  const [avatarColor] = useMemo(
    () => getElementByText(email, avatarColors),
    [email]
  )
  const [avatarBackgroundShape, selectionIndex] = useMemo(
    () => getElementByText(email, avatarBackgrounds),
    [email]
  )
  const initials = useMemo(() => getInitials(name || email), [name, email])

  const maskId = `avatar-mask-${selectionIndex}`

  return (
    <AvatarWrapper>
      <PictureWrapper
        backgroundColor={avatarColor?.background}
        size={size}
        {...props}
      >
        <AvatarText size={size}>{initials}</AvatarText>
        <Gravatar alt={`Gravatar for ${email}`} {...getAvatarSources(email)} />
        <ShapeWrapper
          fill={avatarColor?.shape}
          size={size}
          height={GRID_UNIT * 4}
          width={GRID_UNIT * 4}
        >
          <mask
            height={GRID_UNIT * 4}
            id={maskId}
            maskUnits='userSpaceOnUse'
            width={GRID_UNIT * 4}
            x='0'
            y='0'
          >
            <circle
              cx={GRID_UNIT * 2}
              cy={GRID_UNIT * 2}
              fill={NeutralColors.White}
              r={GRID_UNIT * 2}
            />
          </mask>
          {React.isValidElement(avatarBackgroundShape) && (
            <g fill='inherit' mask={`url(#${maskId})`}>
              {avatarBackgroundShape}
            </g>
          )}
        </ShapeWrapper>
      </PictureWrapper>
    </AvatarWrapper>
  )
}

Avatar.Skeleton = Skeleton

export default Avatar
