export const trackPageView = async (body = {}, rollbar) => {
  try {
    const response = await fetch('/api/track/page/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    return await response.json()
  } catch (e) {
    rollbar.error(
      'useNavigationTrackingMiddleware: error while tracking the page view',
      e
    )

    return {}
  }
}
