import { SIGNUP_WITH_LINKEDIN } from 'constants/experiments'

import React, { useMemo } from 'react'
import { isExperimentVariationActive } from 'utils/optimizely'
import { useOptimizelyContext } from 'components/optimizely'

import { SocialButtonsWrapper } from '../okta-signup-page.styles'

import SocialButton from './social-button'
import {
  EXPERIMENTAL_LINKEDIN_PROVIDER,
  SOCIAL_PROVIDERS,
  TSocialProviderName,
} from './constants'

type TSocialButtonsProps = {
  socialIdpIds: Record<TSocialProviderName, string>
  clientId: string
  oktaDomain: string
  state: string
  embedAdminAppName?: string | null
  device?: string | null
}

const SocialButtons = ({
  socialIdpIds,
  clientId,
  oktaDomain,
  state,
  embedAdminAppName,
  device,
}: TSocialButtonsProps) => {
  const { experiments } = useOptimizelyContext()

  const socialProviders = useMemo(() => {
    const isSignupWithLinkedInActive = isExperimentVariationActive({
      experiments,
      experimentId: SIGNUP_WITH_LINKEDIN.ID,
      variationId: SIGNUP_WITH_LINKEDIN.VARIATIONS.VARIATION,
    })

    return isSignupWithLinkedInActive
      ? [...SOCIAL_PROVIDERS, EXPERIMENTAL_LINKEDIN_PROVIDER]
      : SOCIAL_PROVIDERS
  }, [experiments])

  return (
    <SocialButtonsWrapper data-testid='social-buttons'>
      {socialProviders.map(({ name: providerName, icon }) => (
        <SocialButton
          key={`${providerName}-social-button`}
          icon={icon}
          providerName={providerName}
          idpId={socialIdpIds[providerName]}
          clientId={clientId}
          oktaDomain={oktaDomain}
          state={state}
          embedAdminAppName={embedAdminAppName}
          device={device}
        />
      ))}
    </SocialButtonsWrapper>
  )
}

export default SocialButtons
