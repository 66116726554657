import { createPortal } from 'react-dom'
import { object } from 'prop-types'

import Consents from './consents'

// We use this portal to render Consents component inside of the form that Okta-Widget creates for us
// so we still have access to the same React tree (life-cycle, translations...) on Consents component
const ConsentsPortal = ({ node }) => {
  return createPortal(<Consents />, node)
}

ConsentsPortal.displayName = 'ConsentsPortal'

ConsentsPortal.propTypes = {
  node: object,
}

export default ConsentsPortal
