import { DEFAULT_PROPERTY, PROPERTIES } from 'constants/tracking'
import { DEFAULT_COUNTRY_CODE } from 'constants/server'

import { memo } from 'react'
import PropTypes from 'prop-types'

import useNavigationTrackingMiddleware from './use-navigation-tracking-middleware'

const NavigationTrackingWrapper = memo(props => {
  useNavigationTrackingMiddleware(props)
  return null
})

NavigationTrackingWrapper.propTypes = {
  typeformProperty: PropTypes.oneOf(PROPERTIES),
  userCountryCode: PropTypes.string,
}

NavigationTrackingWrapper.defaultProps = {
  typeformProperty: DEFAULT_PROPERTY,
  userCountryCode: DEFAULT_COUNTRY_CODE,
}

export { NavigationTrackingWrapper }
