import { memo, useMemo } from 'react'

import { COOKIE_BANNER_SAFE_AREA_CLASSNAME } from './constants'
import { useCookieBannerHeight } from './cookie-safe-area-provider'
import { TCookieSafeAreaSpacer } from './cookie-banner.types'

export const CookieSafeAreaSpacer = ({
  style,
  className,
  ...props
}: TCookieSafeAreaSpacer) => {
  const cookieBannerHeight = useCookieBannerHeight()

  const computed = useMemo(
    () => ({
      className: className
        ? `${className} ${COOKIE_BANNER_SAFE_AREA_CLASSNAME}`
        : COOKIE_BANNER_SAFE_AREA_CLASSNAME,
      style: {
        ...style,
        height: cookieBannerHeight,
      },
    }),
    [style, className, cookieBannerHeight]
  )

  if (typeof cookieBannerHeight === 'undefined') {
    return null
  }

  return (
    <div
      data-testid={COOKIE_BANNER_SAFE_AREA_CLASSNAME}
      className={computed.className}
      style={computed.style}
      {...props}
    />
  )
}

export default memo(CookieSafeAreaSpacer)
