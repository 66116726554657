const NODE_ENV = process.env.NODE_ENV || 'development'

module.exports = {
  HOST: process.env.HOST || '0.0.0.0',
  PORT: parseInt(process.env.PORT, 10) || 3000,
  STATSD_HOST: process.env.STATSD_HOST || '127.0.0.1',
  STATSD_PORT: parseInt(process.env.STATSD_PORT, 10) || 9125,
  IS_DEV: NODE_ENV === 'development',
  IS_PROD: NODE_ENV === 'production',
  IS_TEST: NODE_ENV === 'test',
  USER_ID_HEADER_NAME: 'x-userid',
  USER_COUNTRY_CODE_HEADER: 'CloudFront-Viewer-Country',
  FORCED_COUNTRY_CODE_HEADER: 'x-country-code',
}
