// Note this file is not an ES-module as it is
// consumed directly by PMS node scripts

const LANGUAGE_CODES = {
  english: 'en',
  spanish: 'es',
  french: 'fr',
  german: 'de',
}
const COUNTRY_CODES = {
  usa: 'US',
  spain: 'ES',
  france: 'FR',
  germany: 'DE',
}
const LOCALES = {
  en_US: `${LANGUAGE_CODES.english}-${COUNTRY_CODES.usa}`,
  es_ES: `${LANGUAGE_CODES.spanish}-${COUNTRY_CODES.spain}`,
  fr_FR: `${LANGUAGE_CODES.french}-${COUNTRY_CODES.france}`,
  de_DE: `${LANGUAGE_CODES.german}-${COUNTRY_CODES.germany}`,
}
const LANGUAGE_CODES_ARRAY = Object.values(LANGUAGE_CODES)
const COUNTRY_CODES_ARRAY = Object.values(COUNTRY_CODES)
const LOCALES_ARRAY = Object.values(LOCALES)
const DEFAULT_LOCALE = LOCALES.en_US
const DEFAULT_LANGUAGE_CODE = LANGUAGE_CODES.english
const DEFAULT_COUNTRY_CODE = COUNTRY_CODES.usa
const X_DEFAULT_LOCALE = 'x-default'
const getLocaleByLanguageCode = languageCode => {
  switch (languageCode) {
    case LANGUAGE_CODES.english:
      return LOCALES.en_US

    case LANGUAGE_CODES.spanish:
      return LOCALES.es_ES

    case LANGUAGE_CODES.french:
      return LOCALES.fr_FR

    case LANGUAGE_CODES.german:
      return LOCALES.de_DE

    default:
      return DEFAULT_LOCALE
  }
}
const getLocaleByCountryCode = countryCode => {
  switch (countryCode) {
    case COUNTRY_CODES.usa:
      return LOCALES.en_US

    case COUNTRY_CODES.spain:
      return LOCALES.es_ES

    case COUNTRY_CODES.france:
      return LOCALES.fr_FR

    case COUNTRY_CODES.germany:
      return LOCALES.de_DE

    default:
      return DEFAULT_LOCALE
  }
}
const getLanguageCodeFromLocale = locale => {
  switch (locale) {
    case LOCALES.en_US:
      return LANGUAGE_CODES.english

    case LOCALES.es_ES:
      return LANGUAGE_CODES.spanish

    case LOCALES.fr_FR:
      return LANGUAGE_CODES.french

    case LOCALES.de_DE:
      return LANGUAGE_CODES.german

    default:
      return DEFAULT_LANGUAGE_CODE
  }
}
const getCountryCodeFromLocale = locale => {
  switch (locale) {
    case LOCALES.en_US:
      return COUNTRY_CODES.usa

    case LOCALES.es_ES:
      return COUNTRY_CODES.spain

    case LOCALES.fr_FR:
      return COUNTRY_CODES.france

    case LOCALES.de_DE:
      return COUNTRY_CODES.germany

    default:
      return DEFAULT_COUNTRY_CODE
  }
}

module.exports = {
  LANGUAGE_CODES,
  COUNTRY_CODES,
  LOCALES,
  LANGUAGE_CODES_ARRAY,
  COUNTRY_CODES_ARRAY,
  LOCALES_ARRAY,
  DEFAULT_LOCALE,
  DEFAULT_LANGUAGE_CODE,
  DEFAULT_COUNTRY_CODE,
  X_DEFAULT_LOCALE,
  getLocaleByLanguageCode,
  getLocaleByCountryCode,
  getLanguageCodeFromLocale,
  getCountryCodeFromLocale,
}
