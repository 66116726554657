import { TYPEFORM_ROOT_DOMAIN } from 'constants/public-envs'

import qs from 'qs'

export const getLinkWithPreservedQueryParams = (
  linkString,
  queryParamsToPreserve
) => {
  // Avoid to attach query params to links to typeforms since receiving unexpected params breaks leads tracking
  if (linkString.includes(`${TYPEFORM_ROOT_DOMAIN}/to/`)) {
    return linkString
  }

  let computedParamsToPreserve =
    queryParamsToPreserve ||
    (typeof window !== 'undefined' && window.location.search)
  if (!computedParamsToPreserve) {
    return linkString
  }

  computedParamsToPreserve = qs.parse(computedParamsToPreserve, {
    ignoreQueryPrefix: true,
  })

  const [linkWithoutQueryParams, linkQueryParams] = linkString.split('?')
  const hrefParams = qs.parse(linkQueryParams, { ignoreQueryPrefix: true })

  const combinedQueryParams = qs.stringify(
    {
      ...computedParamsToPreserve,
      ...hrefParams,
    },
    { addQueryPrefix: true }
  )

  return `${linkWithoutQueryParams}${combinedQueryParams}`
}
