const { LANGUAGE_CODES } = require('@typeform/ginger/dist/lib/i18n')

const NAMESPACES = {
  COMMON: 'common',
  GINGER: 'ginger',
  HOME: 'home',
  PRICING: 'pricing',
  HELP: 'help',
  CONTACT_FORM_ZENDESK: 'contact-form-zendesk',
  AUTH: 'auth',
  ERROR_PAGE: 'error-page',
  PTG: 'ptg',
  ENTERPRISE_SECTION: 'enterprise-section',
}

const prefixesForNamespaces = [
  {
    namespace: NAMESPACES.HOME,
    prefixes: ['front-page.', 'frontpage.', 'ab-test.frontpage.', 'homepage.'],
  },
  {
    namespace: NAMESPACES.PRICING,
    prefixes: [
      'pricing-page.',
      'pricing.',
      'billing-period.',
      'AB-test-pricing-page.',
    ],
  },
  {
    namespace: NAMESPACES.HELP,
    prefixes: ['help-center.', 'help-hub.'],
  },
  {
    namespace: NAMESPACES.AUTH,
    prefixes: ['auth.'],
  },
  {
    namespace: NAMESPACES.ERROR_PAGE,
    prefixes: ['error-page.'],
  },
  {
    namespace: NAMESPACES.PTG,
    prefixes: ['ptg.'],
  },
  {
    namespace: NAMESPACES.ENTERPRISE_SECTION,
    prefixes: ['enterprise-section.'],
  },
]

module.exports = {
  locales: [
    LANGUAGE_CODES.english,
    LANGUAGE_CODES.spanish,
    LANGUAGE_CODES.french,
    LANGUAGE_CODES.german,
  ],
  defaultLocale: LANGUAGE_CODES.english,
  keySeparator: false,
  loader: false,
  interpolation: {
    prefix: '${',
    suffix: '}',
  },
  pages: {
    '*': [
      NAMESPACES.COMMON,
      NAMESPACES.ERROR_PAGE,
      NAMESPACES.GINGER,
      NAMESPACES.PRICING,
      NAMESPACES.ENTERPRISE_SECTION,
      NAMESPACES.AUTH,
    ],
    '/': [NAMESPACES.HOME],
    '/pricing': [NAMESPACES.PRICING],
    'rgx:^/help': [NAMESPACES.HELP],
    'rgx:^/guide': [NAMESPACES.HELP],
    'rgx:^/help/contact': [NAMESPACES.CONTACT_FORM_ZENDESK],
    'rgx:^/templates': [NAMESPACES.PTG],
  },
  defaultNS: NAMESPACES.COMMON,
  prefixesForNamespaces,
}
