import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'

export const LOCALE_DISPLAY_NAME_MAP = {
  [LANGUAGE_CODES.english]: 'English',
  [LANGUAGE_CODES.spanish]: 'Español',
}

export const UPCOMING_LOCALE = {
  [LANGUAGE_CODES.french]: 'Français',
  [LANGUAGE_CODES.german]: 'Deutsch',
}

export const colors = {
  positive: NeutralColors.Grey700,
  negative: NeutralColors.White,
}

export const backgroundColors = {
  positive: NeutralColors.White,
  negative: NeutralColors.Grey500,
}

export const ALL_LOCALE = {
  ...LOCALE_DISPLAY_NAME_MAP,
  ...UPCOMING_LOCALE,
}

export const ALL_LOCALE_CODES = Object.keys(ALL_LOCALE)

export enum SelectPositions {
  Top = 'top',
  Bottom = 'bottom',
}
