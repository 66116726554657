import React, { memo } from 'react'
import OktaSignupPage from 'components/okta-signup-page'
import { useAppContext } from 'components/app-context'
import { TOktaConfig } from 'components/revamp/widget-sign-up/types'
import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'

import { OktaWidgetStyles } from './okta-widget.styles'

const OktaWidget = ({
  onRegistrationComplete,
  oktaConfig,
}: {
  onRegistrationComplete: () => void
  oktaConfig: TOktaConfig
}) => {
  const { device } = useAppContext()

  return (
    <>
      <OktaWidgetStyles />
      <OktaSignupPage
        justWidget
        device={device}
        onRegistrationComplete={onRegistrationComplete}
        oktaConfig={oktaConfig}
        locale={LANGUAGE_CODES.english}
      />
    </>
  )
}

export default memo(OktaWidget)
