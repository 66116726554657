import React from 'react'

const CaretUp = () => (
  <svg
    width='12'
    height='7'
    viewBox='0 0 12 7'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.00008 2.94976L8.87876 5.82845C9.65981 6.6095 10.9261 6.60949 11.7072 5.82844L6.00008 0.121338L0.292969 5.82844C1.07402 6.60949 2.34035 6.60949 3.1214 5.82844L6.00008 2.94976Z'
    />
  </svg>
)

CaretUp.displayName = 'CaretUp'

export default CaretUp
