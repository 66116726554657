import { createGlobalStyle } from 'styled-components'
import {
  ForegroundColors,
  NeutralColors,
} from '@typeform/ginger/dist/constants/colors'
import {
  fontFamily,
  fontSize,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import { getStaticAsset } from 'utils/assets'
import { SeeOptions } from 'components/okta-signup-page/consents/consents.styles'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OktaWidgetStyles = createGlobalStyle<any>`
  #terms_and_consents:not(:checked) {
    background-color: ${NeutralColors.White};
    border-radius: 4px;
  }

  #okta-form-children {
    ${SeeOptions} {
      justify-content: flex-start;
    }
  }

  .registration-complete,
  .okta-form-label,
  .auth-footer,
  .required-fields-label,
  .okta-form-infobox-error,
  .subschema-unsatisfied,
  .subschema-satisfied {
    display: none;
  }

  .okta-form-infobox-error.visible,
  .subschema-unsatisfied.visible,
  .subschema-satisfied.visible {
    display: block;
  }

  .okta-form-infobox-error.visible {
    border: none;
  }

  .o-form-button-bar {
    display: flex;
    justify-content: center;

    /* stylelint-disable-next-line media-feature-range-notation */
    @media screen and (min-width: 600px) {
      max-width: 100%;
    }
  }

  .button-primary {
    height: 44px;
    width: 100%;
    color: ${NeutralColors.White};
    background-color: ${NeutralColors.Ink};
    border-radius: 12px;
    font-family: ${fontFamily.apercuPro};
    border: none;
    cursor: pointer;
    appearance: none;
  }


  /* Email/password inputs */
  .okta-form-input-field {
    &:hover {
      border-color: ${NeutralColors.Grey600};
    }

    input::placeholder {
      color: ${NeutralColors.Grey300};
      font-family: ${fontFamily.apercuPro};
    }
  }

  .okta-form-title {
    display: none;
  }

  /* Input fields */
  .o-form-fieldset {
    margin-bottom: 12px;
  }

  .o-form-input-name-email,
  .o-form-input-name-password {
    display: inline-block;
    width: 100%;
    height: 44px;
    border: 1px solid ${NeutralColors.Grey400};
    border-radius: 12px;

    input {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 6px 8px;
      border-radius: 12px;
      outline-color: ${NeutralColors.Grey400};

      /* Inputs need to have a minimum font-size of 16px to avoid Mobile Safari zooming in when the field is focused/blurred. */
      font-size: ${fontSize.small};
      line-height: 16px;
      border: none;
    }
  }

  .o-form-input-name-password {
    position: relative;

    input {
      padding-right: 35px;
    }
  }

  .o-form-input-password-toggle {
    background: none;
    border: none;
    color: inherit;
    outline: inherit;
    height: 0;
    width: 0;
    padding: 0;
  }

  .o-form-input-eyeicon {
    right: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 0;
    user-select: none;
  }

  .o-form-input-eyeicon:before {
    content: ' ';
    display: block;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .o-form-input-eyeicon-show:before {
    background-image: url(${getStaticAsset(
      'signup-page/revamp/eye-open-icon.svg'
    )});
  }

  .o-form-input-eyeicon-hide {
    display: none;

    &:before {
      background-image: url(${getStaticAsset(
        'signup-page/revamp/eye-crossed-icon.svg'
      )});
    }
  }

  .o-form-content {
    margin-bottom: 15px;
  }

  /* Errors */
  .o-form-error-container {
    margin-bottom: 4px;
  }

  .okta-form-infobox-error,
  .okta-form-input-error {
    color: ${ForegroundColors.CriticalEmphasis};
    display: none;
    font-size: ${fontSize.xSmall};
    line-height: ${lineHeight.large};
    margin: 0;
    padding: 8px 0 0 20px;
    position: relative;

    p {
      margin: 0;
    }

    .o-form-has-errors & {
      display: inherit;
    }

    span:before {
      content: '';
      background: url(${getStaticAsset(
        'signup-page/revamp/warning-icon.svg'
      )}) no-repeat center center;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 8px;
    }
  }

  .default-schema {
    display: none;
  }

  .subschema-unsatisfied,
  .subschema-satisfied {
    p {
      font-size: ${fontSize.xSmall};
      line-height: ${lineHeight.large};
      margin: 0;
      padding: 8px 0 6px 20px;
      position: relative;

      span:before {
        content: '';
        width: 14px;
        height: 14px;
        position: absolute;
        left: 0;
      }
    }
  }

  .subschema-satisfied {
    p {
      color: ${NeutralColors.Grey500};

      span:before {
        background: url(${getStaticAsset(
          'signup-page/check.svg'
        )}) no-repeat 50%;
        top: 10px;
      }
    }
  }

  .subschema-unsatisfied {
    p {
      color: ${ForegroundColors.CriticalEmphasis};

      span:before {
        width: 16px;
        height: 16px;
        background: url('${getStaticAsset(
          'signup-page/revamp/warning-icon.svg'
        )}') no-repeat center center;
      }
    }
  }

  #label-terms_and_consents {
    margin-bottom: 0;
  }

  #custom-password-validation .subschema-unsatisfied.visible p {
    padding-block: 0;
  }
`
