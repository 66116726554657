import {
  fontSize,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import {
  NeutralColors,
  UIPalette,
} from '@typeform/ginger/dist/constants/colors'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import {
  BREAKPOINTS,
  MEGA_MENU_Z_INDEX,
  MOBILE_HEADER_HEIGHT,
} from '@typeform/ginger/dist/constants/design'
import styled, { css } from 'styled-components'
import { DisplayType } from 'components/molecules/banner/constants'
import { NavigationContainer } from '@typeform/ginger/dist/components/mega-menu/mega-menu.styles'
import { TopMenuList } from '@typeform/ginger/dist/components/mega-menu/components/top-menu/top-menu.styles'

import { TContainerProps } from './banner-provider.types'

export const Banner = styled.div`
  background-color: ${UIPalette.Emerald};
  color: ${NeutralColors.GreyPaper};
  text-align: center;
  padding: 19px 32px;
  font-size: ${fontSize.xSmall};
  line-height: ${lineHeight.medium};

  ${mediaQueries.sm`
    font-size: ${fontSize.small};
    line-height: ${lineHeight.large};
  `}

  & > a {
    color: ${UIPalette.Yellow400};
    text-decoration: underline;
    font-weight: 600;
  }
`

const bannerPosition = (displayType?: DisplayType) => {
  if (displayType === DisplayType['Sticky']) return 'sticky'
  return 'relative'
}

const navStyles = (
  { displayType, bannerHeight }: TContainerProps,
  menuHeight: number
) => {
  if (displayType === DisplayType['Fixed']) {
    return css`
      top: 0;
      position: sticky;
      /* height 0 ensures that the page content still goes behind the mega-menu as it would if position="absolute" */
      height: 0;
    `
  }
  return css`
    top: ${bannerHeight || menuHeight}px;
  `
}

export const Container = styled.div<TContainerProps>`
  position: relative;

  ${Banner} {
    position: ${({ displayType }) => bannerPosition(displayType)};
    top: 0;
    /* Use MEGA_MENU_Z_INDEX + 1 to make sure the banner is rendered over the menu */
    z-index: ${MEGA_MENU_Z_INDEX + 1};
  }

  ${NavigationContainer} {
    ${props => navStyles(props, 72)};

    ${mediaQueries.sm`
      ${(props: TContainerProps) => navStyles(props, 62)};
    `}
  }

  ${TopMenuList} {
    @media (max-width: ${BREAKPOINTS.md - 1}px) {
      top: ${({ bannerHeight }) => bannerHeight + MOBILE_HEADER_HEIGHT}px;
    }
  }
`
