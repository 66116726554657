import { Section as GingerSection } from 'components/atoms/layout/'
import styled from 'styled-components'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { BORDER_RADIUS } from '@typeform/ginger/dist/constants/borders'
import { fontSize } from '@typeform/ginger/dist/constants/typography'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

export const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`

export const SignupPanel = styled(GingerSection)`
  border-radius: ${BORDER_RADIUS.large} 0 0 ${BORDER_RADIUS.large};
  padding: 0;
  flex: 10% 1;
  display: grid;
  overflow: auto;
  height: 100%;

  ${mediaQueries.md`
    margin-left: -16px;
  `}
`

export const AuthSection = styled.div`
  background-color: ${NeutralColors.White};
  display: grid;
  grid-template-columns: [first] 0 [second] auto;
  grid-template-rows: [first] 52px [second] auto [third] 52px;
  position: relative;
  width: 100%;
`

export const LanguageSwitcherWrapper = styled.div`
  padding: 16px 0 0 24px;
  grid-column-start: first;
  grid-column-end: first;
  grid-row-start: third;
  grid-row-end: third;

  ${mediaQueries.xs`
    grid-row-start: first;
    grid-row-end: first;
  `}
`

export const Container = styled.div`
  flex-direction: column;
  max-width: 256px;
  justify-self: center;
  display: flex;
  justify-content: center;
  grid-column-start: second;
  grid-column-end: second;
  grid-row-start: second;
  grid-row-end: second;
  align-items: center;
  ${mediaQueries.sm`
      max-width: 542px;
      > * {
        max-width: 256px;
      }
  `}
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
`

export const SocialButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const FormHeadline = styled.h2`
  font-weight: 200;
  font-size: ${fontSize.large};
  line-height: 36px;
  color: ${NeutralColors.Grey700};
  margin-bottom: 24px;
  text-align: center;
  max-width: 100%;
`
