export const JS_CLASSNAME = 'js'
export const NO_JS_CLASSNAME = 'no-js'

export enum Axis {
  X = 'x',
  Y = 'y',
}

export enum Origin {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}
