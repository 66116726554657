import { Palette } from '@typeform/ginger/dist/constants/palettes'

import { ButtonSize, ButtonVariant } from './types'

export const defaultProps = {
  palette: Palette.Positive,
  size: ButtonSize.Large,
  fullWidth: false,
  hasMinWidth: true,
  helperText: '',
  variant: ButtonVariant.Primary,
  href: undefined,
}
