import { TYPEFORM_ROOT_DOMAIN } from 'constants/public-envs'

import { routes } from 'utils'

const { LANGUAGE_CODES } = require('@typeform/ginger/dist/lib/i18n')

export const OKTA_SIGNUP_STATUSES = {
  SUCCESS: 'SUCCESS',
  ACTIVATION_EMAIL_SENT: 'ACTIVATION_EMAIL_SENT',
}

export const PAGE_LOCALES = [
  {
    title: LANGUAGE_CODES.english,
    href: routes(LANGUAGE_CODES.english).signup,
    trackingName: 'english',
  },
  {
    title: LANGUAGE_CODES.spanish,
    href: routes(LANGUAGE_CODES.spanish).signup,
    trackingName: 'spanish',
  },
  {
    title: LANGUAGE_CODES.german,
    href: routes(LANGUAGE_CODES.german).signup,
    trackingName: 'german',
  },
  {
    title: LANGUAGE_CODES.french,
    href: routes(LANGUAGE_CODES.french).signup,
    trackingName: 'french',
  },
]

export const OKTA_LOCALES = {
  [LANGUAGE_CODES.english]: 'en_US',
  [LANGUAGE_CODES.spanish]: 'es_ES',
  [LANGUAGE_CODES.spanish]: 'de_DE',
  [LANGUAGE_CODES.spanish]: 'fr_FR',
}

export const FIRST_LAST_NAME_REGEX = /^([^_.]+)[._]?(\w+)?/

export const SIGNUP_PROVIDER_COOKIE_NAME = 'signup_provider'
export const SIGNUP_PROVIDER_OKTA = 'okta'
export const COOKIE_CONFIG = {
  path: '/',
  domain: `.${TYPEFORM_ROOT_DOMAIN}`,
  secure: true,
  sameSite: 'none',
  partitioned: true,
}
export const SIGNUP_DEVICE_COOKIE_NAME = 'signup_device'
export const SIGNUP_USER_AGENT_COOKIE_NAME = 'signup_user_agent'
export const SIGNUP_EXTRA_COOKIE_NAME = 'signup_extra'

export const PARTNER_STACK_ENCODED_KEY = 'gspk'
export const PARTNER_STACK_SESSION_ID = 'gsxid'

export const CELLO_REFERRAL_CODE = 'cello-referral'

export const SAASQUATCH_REFERRAL_CODE = 'rsCode'

const GOOGLE_ANALYTICS = '_ga'
const FACEBOOK_PIXEL_COOKIE_NAME = '_fbp'
const FACEBOOK_PIXEL_CLICK_COOKIE_NAME = '_fbc'
const CJ_AFFILIATES_COOKIE = 'cje'
export const EXPERIMENT_FINGERPRINT = 'experiments-fingerprint'
export const COOKIES_TO_CHECK = [
  PARTNER_STACK_ENCODED_KEY,
  PARTNER_STACK_SESSION_ID,
  EXPERIMENT_FINGERPRINT,
  GOOGLE_ANALYTICS,
  FACEBOOK_PIXEL_COOKIE_NAME,
  FACEBOOK_PIXEL_CLICK_COOKIE_NAME,
  CJ_AFFILIATES_COOKIE,
]

export const SIGNUP_STATE_KEY = 'signup_state'
