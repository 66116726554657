import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ResizeObserver from 'resize-observer-polyfill'

type TContainerProps = {
  isOpened: boolean
  maxHeight?: number
}
export const Container = styled.div`
  max-height: ${({ isOpened, maxHeight }: TContainerProps) => {
    if (isOpened && maxHeight) return `${maxHeight}px`
    if (isOpened) return '100vh'
    return 0
  }};
  overflow: hidden;
  transition: max-height 200ms ease-in;
`

export const Content = styled.div`
  /* Prevent margin collapsing phenomenon */
  overflow: auto;
`

type TCollapseProps = {
  children: React.ReactNode | React.ReactNode[]
  isOpened: boolean
  onTransitionEnd?: (event: TransitionEvent, isOpened: boolean) => void
}

const Collapse = ({
  children,
  isOpened,
  onTransitionEnd = () => {},
  ...rest
}: TCollapseProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [maxHeight, setMaxHeight] = useState<number | undefined>()

  const updateMaxHeight = useCallback(() => {
    if (contentRef.current) {
      const { offsetHeight } = contentRef.current

      setMaxHeight(offsetHeight)
    }
  }, [])

  const handleTransitionEnd = useCallback(
    (event: TransitionEvent) => {
      onTransitionEnd(event, isOpened)
    },
    [onTransitionEnd, isOpened]
  )

  useEffect(() => {
    const { current: containerEle } = containerRef
    if (containerEle) {
      containerEle.addEventListener('transitionend', handleTransitionEnd)

      return () => {
        containerEle.removeEventListener('transitionend', handleTransitionEnd)
      }
    }
  }, [handleTransitionEnd])

  useEffect(() => {
    const { current: contentEle } = contentRef
    let animationFrameID: number | null
    const resizeObserver = new ResizeObserver(() => {
      animationFrameID = window.requestAnimationFrame(() => updateMaxHeight())
    })
    if (contentEle) {
      resizeObserver.observe(contentEle)
    }

    updateMaxHeight()

    return () => {
      if (contentEle) {
        resizeObserver.unobserve(contentEle)
      }
      if (animationFrameID !== null) {
        window.cancelAnimationFrame(animationFrameID)
      }
    }
  }, [updateMaxHeight])

  return (
    <Container
      isOpened={isOpened}
      maxHeight={maxHeight}
      ref={containerRef}
      aria-hidden={!isOpened}
      {...rest}
    >
      <Content ref={contentRef}>{children}</Content>
    </Container>
  )
}

Collapse.displayName = 'Collapse'

export default Collapse
