import { TYPEFORM_ROOT_DOMAIN } from './public-envs'

export const CATEGORY = {
  ADMIN: 'admin',
  PUBLIC_SITE: 'public_site',
}
export const ATTRIBUTION_ID_GLOBAL_KEY = 'tid'
export const CATEGORIES = Object.values(CATEGORY)

export const DEFAULT_CATEGORY = CATEGORY.PUBLIC_SITE

export const VERSION = {
  MOBILE: 'mobile',
  DESKTOP: 'v2',
}

export const VERSIONS = Object.values(VERSION)

export const DEFAULT_VERSION = VERSION.DESKTOP

export const PROPERTY = {
  ADMIN_ACCESS: 'admin_access',
  LANDING_PAGE: 'landing_page',
  WEB_PLATFORM: 'web_platform',
  HELP_CENTER: 'help_center',
  DEVELOPER_PORTAL: 'developer_portal',
  BLOG: 'blog',
  PUBLIC_SITE: 'public_site',
  CONNECT: 'typeform_connect',
  LABS: 'typeform_labs',
  PUBLIC_TEMPLATE_GALLERY: 'public_template_gallery',
  FOUR_O_FOUR: '404_pages',
}

export const DEFAULT_PROPERTY = PROPERTY.PUBLIC_SITE

export const PROPERTIES = Object.values(PROPERTY)

export const UTM_PARAMS_ALLOWLIST = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
]

export const SEARCH_PARAMS_ALLOWLIST = [
  ...UTM_PARAMS_ALLOWLIST,
  'tf_source',
  'tf_medium',
  'tf_campaign',
  'tf_term',
  'tf_content',
  'referrer',
  'optimizely-experiments',
  'optimizely_experiments_fingerprint',
  'redirect_uri',
  ATTRIBUTION_ID_GLOBAL_KEY,
]

export const HOSTNAMES_ALLOWLIST = ['localhost', TYPEFORM_ROOT_DOMAIN]
export const HOSTNAMES_BLOCKLIST = [`auth.${TYPEFORM_ROOT_DOMAIN}`]

export const OWNER = 'site_team'

export const EVENT = {
  NAME: {
    __TEST_NAME__: '__test_name__',
    ITEM_CLICKED: 'item_clicked',
    MESSAGE_SHOWN: 'message_shown',
    SEARCH_QUERY_ENTERED: 'search_query_entered',
    SEARCH_QUERY_REMOVED: 'search_query_removed',
    EXPERIMENT_VIEWED: 'experiment_viewed',
  },
  PROPERTY: {
    ITEM: {
      TRY_TEMPLATE: 'try_template',
      START_WITH_TEMPLATE: 'start_with_template',
      SIGN_UP: 'sign_up',
      PREVIEW_TEMPLATE: 'preview_template',
      MENU_SELECTION: 'menu_selection',
      MENU_CATEGORY: 'menu_category',
      MENU_SUB_CATEGORY: 'menu_sub_category',
      EXPAND_MENU: 'expand_menu',
      TEMPLATE_PAGE: 'template_page',
      FOOTER: 'footer',
    },
    ITEM_TYPE: {
      TYPEFORM: 'typeform',
      BUTTON: 'button',
      MENU: 'menu',
      LINK: 'link',
      ACCORDION: 'accordion',
    },
    LOCATION: {
      TEMPLATE_HERO: 'template_hero',
      TEMPLATE_HERO_IMAGE: 'hero_image',
      TEMPLATE_PREVIEW: 'template_preview',
      TEMPLATE_EMBEDDED_EXPAND_PREVIEW: 'expand_preview',
      TEMPLATE_HERO_EXPAND_PREVIEW: 'hero_cta',
      TEMPLATE_BODY: 'template_body',
      HEADER_MENU: 'header_menu',
      INTERACTIVE_TEMPLATE: 'interactive_template',
      MOBILE_FULLSCREEN_PREVIEW: 'mobile_full_screen_preview',
    },
  },
}
