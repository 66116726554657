import { FocusEvent, MouseEvent } from 'react'
import { ButtonVariant } from 'components/atoms/button'
import { TTopMenuItem } from 'models/top-menu-item-model.types'
import { TTopMenuItemColumnItem } from 'models/top-menu-item-column-item-model.types'
import { TTopMenuItemGalleryItem } from 'models/top-menu-item-gallery-item-model.types'
import { TTopMenuItemSeeMoreLink } from 'models/top-menu-item-see-more-link.types'

export enum MegaMenuType {
  Mobile = 'Mobile',
  Desktop = 'Desktop',
  Mimimal = 'Minimal',
}

export type TUserMenuItem = {
  id: string
  label: string
  destructive?: boolean
  link: string
}

export type TUserMenuItems = Array<{
  id: string
  items: TUserMenuItem[]
}>

export type TCtaData = {
  label: string
  link: string
  onClick?: (
    event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
    params: {
      menuType: MegaMenuType
    }
  ) => void
  variant?: ButtonVariant
  dataQa?: string
  isSignupLink?: boolean
  linkTarget?: string
  [key: string]: unknown
}

export type TUserMenuClickEventObject = {
  dropdownState: boolean
  event: MouseEvent<HTMLButtonElement>
}

export type TUserMenuClickEventHandler = (
  userMenuClickEventObject: TUserMenuClickEventObject
) => void

export type TUserMenuItemClickEventObject = {
  event: MouseEvent<HTMLAnchorElement>
  item: TUserMenuItem
}

export type TUserMenuItemClickEventHandler = (
  userMenuItemClickEventObject: TUserMenuItemClickEventObject
) => void

export type TMenuItemClickHandler = (menuItem: TTopMenuItem) => void

export type TMenuItemClickWithEventObject = {
  item: TTopMenuItem
  event:
    | MouseEvent<HTMLLIElement | HTMLAnchorElement | HTMLButtonElement>
    | FocusEvent<HTMLLIElement>
    | KeyboardEvent
}

export type TSubMenuItemClickEventObject = {
  item:
    | TTopMenuItem
    | TTopMenuItemColumnItem
    | TTopMenuItemGalleryItem
    | TTopMenuItemSeeMoreLink
  event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>
}

export type TMenuItemClickWithEventHandler = (
  menuItemClickWithEventObject: TMenuItemClickWithEventObject
) => void

export type TSubMenuItemClickEventHandler = (
  subMenuItemClickEventObject: TSubMenuItemClickEventObject
) => void
