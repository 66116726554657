import styled, { css } from 'styled-components'
import { ImageWithLazyAttributes } from 'components/atoms/images/utils'

export const PictureContainer = styled.picture`
  ${({ width, height }: { width?: number; height?: number }) =>
    width &&
    height &&
    css`
      display: inline-block;
      position: relative;
      width: 100%;
      height: 100%;
      max-width: ${width}px;
      max-height: ${height}px;
      overflow: hidden;
      padding-bottom: ${(height / width) * 100}%;
    `}
`

export const Image = styled(ImageWithLazyAttributes)`
  ${({ width, height }: { width?: number; height?: number }) =>
    width &&
    height &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    `}
`
