// Copied from Kitt
// https://github.com/Typeform/kitt/blob/05d879218730c3562ab6a47329e5e12b4e2acd35/src/components/avatar/avatar-colors.ts

import { TAvatarColor, TColors } from '../avatar.types'

const colors: TColors = {
  deYork: '#7DBB91',
  pineGlade: '#BBD396',
  tulipTree: '#e4b83a',
  terracotta: '#E26d5a',
  sage: '#94ae89',
  eastSide: '#a086c4',
  glacier: '#74a4bf',
  seaPink: '#f09f97',
  eastBay: '#58457a',
  brandyRose: '#bf9677',
  sanMarino: '#3C6997',
  morningGlory: '#96CEDC',
}

export const avatarColors: TAvatarColor[] = [
  {
    background: colors.deYork,
    shape: colors.eastSide,
  },
  {
    background: colors.terracotta,
    shape: colors.seaPink,
  },
  {
    background: colors.sage,
    shape: colors.pineGlade,
  },
  {
    background: colors.eastSide,
    shape: colors.tulipTree,
  },
  {
    background: colors.glacier,
    shape: colors.seaPink,
  },
  {
    background: colors.brandyRose,
    shape: colors.tulipTree,
  },
  {
    background: colors.eastBay,
    shape: colors.eastSide,
  },
  {
    background: colors.seaPink,
    shape: colors.morningGlory,
  },
  {
    background: colors.morningGlory,
    shape: colors.sanMarino,
  },
  {
    background: colors.pineGlade,
    shape: colors.glacier,
  },
]

export default avatarColors
