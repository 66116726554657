import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import styled from 'styled-components'

export const RadioContainer = styled.div``

export const Label = styled.label`
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: inline-flex;
`

export const RadioDescription = styled.div`
  margin-left: 12px;
`

export const RadioInput = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
  appearance: none;
  border-radius: 50%;
  margin-bottom: 0;
  margin-top: 0;
  outline: 0;
  transition: 0.2s ease-in-out;
  background-color: ${NeutralColors.White};
  border: 1px solid ${NeutralColors.Grey400};

  &:focus-visible {
    box-shadow: 0 0 0 2px ${NeutralColors.White}, 0 0 0 4px ${NeutralColors.Ink};
  }

  &:hover {
    background-color: ${NeutralColors.Grey100};
  }

  &:disabled {
    background-color: ${NeutralColors.White};
    border-color: ${NeutralColors.Grey400};
    cursor: default;
  }

  &:checked:enabled {
    background-color: ${NeutralColors.White};
    border: 6px solid ${NeutralColors.Ink};
  }

  &:checked:disabled {
    box-shadow: inset 0 0 0 5px ${NeutralColors.Grey400};
    background-color: ${NeutralColors.White};
    border-color: ${NeutralColors.Grey400};
  }
`
