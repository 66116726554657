import { useMemo } from 'react'

import { useMenuContext } from '../../mega-menu-provider'
import { HEADER_HEIGHT } from '../../mega-menu.constants'

import { MenuBackgroundContainer } from './menu-background.styles'
const MenuBackground = () => {
  const {
    palette,
    transparentBackground,
    isMobileMenu,
    isMobileMenuOpen,
    isMenuScrolled,
    activeSubMenuItem,
    subMenuElementsRef,
  } = useMenuContext()
  const computedProps = useMemo(() => {
    let contentHeight = ''
    if (isMobileMenuOpen) {
      contentHeight = `100vh`
    } else if (!isMobileMenu && activeSubMenuItem?.id) {
      const subMenuContainerElement =
        subMenuElementsRef?.current[activeSubMenuItem.id]

      contentHeight = subMenuContainerElement?.offsetHeight
        ? `${subMenuContainerElement.offsetHeight + HEADER_HEIGHT}px`
        : ''
    }

    return {
      contentHeight,
      isExpanded: !!contentHeight,
    }
  }, [isMobileMenu, isMobileMenuOpen, activeSubMenuItem, subMenuElementsRef])

  return (
    <MenuBackgroundContainer
      {...computedProps}
      isMenuScrolled={isMenuScrolled}
      transparentBackground={transparentBackground}
      palette={palette}
    />
  )
}

export default MenuBackground
