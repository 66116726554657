import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { fontSize } from '@typeform/ginger/dist/constants/typography'

export const Container = styled.div`
  align-items: center;
  color: ${NeutralColors.Grey800};
  display: flex;
  grid-column-start: second;
  grid-column-end: second;
  justify-content: flex-end;
  padding: 8px 24px;
`

export const Text = styled.p`
  margin: 0 8px 0 0;
  font-size: ${fontSize.xSmall};
`
