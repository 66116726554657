import Cookies from 'js-cookie'
import { deleteNonStrictlyNecessaryCookies as originalDeleteNonStrictlyNecessaryCookies } from '@typeform/js-tracking/lib/cookiesUtil'

import { IS_PROD } from '../server/constants'
import { TYPEFORM_ROOT_DOMAIN } from '../constants/public-envs'

export const getDomain = () =>
  IS_PROD ? `.${TYPEFORM_ROOT_DOMAIN}` : 'localhost'

const cookies = Cookies.withAttributes({
  path: '/',
  domain: getDomain(),
})

export const deleteNonStrictlyNecessaryCookies = () => {
  originalDeleteNonStrictlyNecessaryCookies()

  // Cello attribution library sets all of its cookies twice for both .typeform.com and www.typeform.com domain
  // .typeform.com cookies can be deleted via js-tracking util but not www.typeform.com domain cookies.
  // Therefore, we need to delete cello related cookies our selves without touching other ones.
  // We have raised the issue to Cello and we will clean this code when they fix this issue on their side
  const allLeftOverCookies = Object.keys(Cookies.get())
  const celloCookies = allLeftOverCookies.filter(cookie =>
    cookie.includes('cello-')
  )

  celloCookies.forEach(celloCookie => {
    Cookies.remove(celloCookie)
  })
}

export default cookies
