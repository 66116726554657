export const GRID_MAX_WIDTH = 1535
export const GRID_UNIT = 8
export const GRID_COLUMNS = 12
export const CONTAINER_WIDTH = 1200
export const CONTAINER_WIDTH_INNER = 1158 // 1200 - (24 * 2)
export const AVATAR_WIDTH = 50
export const ARTICLE_SECTION_VERTICAL_PADDING = 2 * GRID_UNIT
export const ARTICLE_SECTION_HORIZONTAL_PADDING = 3 * GRID_UNIT
export const ARTICLE_COLUMN_WIDTH = 640
export const ARTICLE_SECTION_WIDTH =
  ARTICLE_COLUMN_WIDTH + 2 * ARTICLE_SECTION_HORIZONTAL_PADDING
export const HEADER_HEIGHT = 88
export const MOBILE_HEADER_HEIGHT = 72
export const HEADER_MAX_WIDTH = GRID_MAX_WIDTH
export const FONTS = {
  apercu: 'apercu',
}
export const MEGA_MENU_Z_INDEX = 1001

export const BREAKPOINTS = {
  base: CONTAINER_WIDTH,
  xs: 360,
  sm: 720,
  md: 1024,
  lg: 1280,
  xl: 1535,
} as const

export enum BreakpointKeys {
  base = 'base',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export type TBreakpointConfig<T> = {
  [k in BreakpointKeys]?: T
}

export const TEXT_ALIGNMENT = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
}
