import React from 'react'
import PropTypes from 'prop-types'
import {
  Context as RollbarContext,
  getRollbarFromContext,
} from '@rollbar/react'

class ErrorBoundary extends React.Component {
  static contextType = RollbarContext

  state = {
    hasError: false,
    error: null,
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    const { location, extraInfo } = this.props
    const errorLog = `${location ? location : 'Mamon'}: ${error.toString()}${
      extraInfo ? ` extras: ${JSON.stringify(extraInfo)}` : ''
    } componentStack: ${errorInfo.componentStack}`

    const rollbar = getRollbarFromContext(this.context)
    rollbar.error(errorLog, error)
  }

  render() {
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  location: PropTypes.string,
  extraInfo: PropTypes.object,
}

ErrorBoundary.defaultProps = {
  children: null,
  location: '',
  extraInfo: null,
}

ErrorBoundary.displayName = 'ErrorBoundary'

export default ErrorBoundary
