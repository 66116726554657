import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { Box } from '@typeform/echo-ui'
import {
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'

export const Heading = styled(Box).attrs(() => ({
  as: 'h2',
}))`
  font-family: sans-serif;
  line-height: ${lineHeight.large};
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.medium};
`

export const BottomText = styled(Box)`
  line-height: ${lineHeight.medium};
  font-size: ${fontSize.xSmall};
  font-weight: ${fontWeight.regular};
`

export const SocialsButtonText = styled(Box)`
  font-family: ${fontFamily.SFProDisplay};
  line-height: ${lineHeight.medium};
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.medium};
  margin-left: auto;
  margin-right: auto;
`

export const AuthWrapper = styled(Box)`
  min-width: 257px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`

export const AuthNavBarContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`

export const AuthHeaderContainer = styled(Box)`
  margin: 24px 0px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const AuthThroughSocials = styled(Box)<{ showOktaWidget: boolean }>`
  display: ${({ showOktaWidget }) => (showOktaWidget ? 'none' : 'flex')};
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-left: 36px;
  margin-right: 36px;

  a {
    border: 1.25px solid ${NeutralColors.Ink};
  }
`

export const AuthThroughEmail = styled(Box)<{ showOktaWidget: boolean }>`
  display: ${({ showOktaWidget }) => (showOktaWidget ? 'flex' : 'none')};
  overflow-y: scroll;
  padding: 24px 36px;
  flex: 1;

  p {
    margin-top: 32px;
    line-height: ${lineHeight.medium};
    font-size: ${fontSize.small};
    font-weight: ${fontWeight.regular};

    strong {
      word-break: break-word;
    }
  }
`

export const AuthBottomContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px 36px;

  a {
    border: 1px solid ${NeutralColors.Ink};
  }
`
