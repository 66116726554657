import styled from 'styled-components'

import { StyledContainer as Container } from './base.styles'

export const StyledContainer = styled(Container)``

export const StyledMessageContainer = styled.div`
  display: inline-block;
  flex-grow: 1;
`

export const StyledMessage = styled.p`
  display: inline;
  margin: 0;
`

export const StyledCtaLink = styled.a`
  text-decoration: underline;
  font-weight: 600;
  white-space: nowrap;
`
