/*
 * *********************************************************************************************************************
 * ****                                !!!!!!!!!!!!!!!!!️  WARNING  !!!!!!!!!!!!!!!!!                                ****
 * ****            EVERY TOKEN USED IN THIS FILE COULD BE EXPOSED IN OUR CLIENT-SIDE CODE, AS IT IS USED            ****
 * ****   BY NEXT JS TO INLINE TOKENS https://nextjs.org/docs/api-reference/next.config.js/environment-variables.   ****
 * ****                   ONLY USE TOKENS IN THIS FILE IF IT'S SAFE TO EXPOSE THEM TO THE CLIENT.                   ****
 * ****                             SERVER-SIDE-ONLY SECRETS SHOULD NEVER BE USED HERE.                             ****
 * *********************************************************************************************************************
 */

const {
  TYPEFORM_COM_DEFAULT_ROOT_DOMAIN,
  VIDEOASK_COM_DEFAULT_ROOT_DOMAIN,
} = require('./root-domains')

// NextJS environment variables cannot be destructured
// This is because during the build process, next performs a naive find-replace, replacing process.env.XXX with the value of the environment variable
// These are all build-time variables. For future addition please favour runtime variables via k8s instead of build-time ones.
/* eslint-disable prefer-destructuring */
const APP_ASSETS_PATH = process.env.APP_ASSETS_PATH
const APP_BASE_PATH = process.env.APP_BASE_PATH
const TYPEFORM_ROOT_DOMAIN =
  process.env.TYPEFORM_ROOT_DOMAIN || TYPEFORM_COM_DEFAULT_ROOT_DOMAIN
const ENABLE_WDYR = process.env.ENABLE_WDYR === 'true'
const NODE_ENV = process.env.NODE_ENV
const USE_CONTENTFUL_PREVIEW_API =
  process.env.USE_CONTENTFUL_PREVIEW_API === 'true'
const VIDEOASK_ROOT_DOMAIN =
  process.env.VIDEOASK_ROOT_DOMAIN || VIDEOASK_COM_DEFAULT_ROOT_DOMAIN

module.exports = {
  APP_ASSETS_PATH,
  APP_BASE_PATH,
  ENABLE_WDYR,
  NODE_ENV,
  TYPEFORM_ROOT_DOMAIN,
  USE_CONTENTFUL_PREVIEW_API,
  VIDEOASK_ROOT_DOMAIN,
}
