import Head from 'next/head'
import Script from 'next/script'
import PropTypes from 'prop-types'
import React, { memo } from 'react'

const script = `!function(w){var clearbit=w.clearbit=w.clearbit||[];if(!clearbit.initialize)if(clearbit.invoked)w.console&&console.error&&console.error("Clearbit snippet included twice.");else{clearbit.invoked=!0;clearbit.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","page","once","off","on"];clearbit.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);clearbit.push(e);return clearbit}};for(var t=0;t<clearbit.methods.length;t++){var e=clearbit.methods[t];clearbit[e]=clearbit.factory(e)}clearbit.load=function(t){var e=document.createElement("script");e.async=!0;e.src=("https:"===document.location.protocol?"https://":"http://")+"x.clearbitjs.com/v1/"+t+"/clearbit.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(e,n)};clearbit.SNIPPET_VERSION="3.1.0"; clearbit.load("pk_76b17f79dd398468af3f36d637ba1002"); clearbit.page(); }}(window);`

export const ClearbitScript = memo(({ hasTargetingConsent }) => {
  if (!hasTargetingConsent) {
    return null
  }

  return (
    <Head>
      <Script id='clearbit' key='clearbit'>
        {script}
      </Script>
    </Head>
  )
})

ClearbitScript.displayName = 'ClearbitScript'

ClearbitScript.propTypes = {
  hasTargetingConsent: PropTypes.bool.isRequired,
}
