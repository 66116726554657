import React, {
  ReactNode,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { TUser, getUser } from 'services/user-service'
import { useRollbar } from '@rollbar/react'

export enum UserState {
  Initial = 'initial',
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error',
}

type TUserProviderValue = {
  isUserAuthenticated: boolean
  user?: TUser
  loading: boolean
  error?: unknown
  setUserState: (userState: UserState) => void
}

const UserProviderContext = React.createContext<TUserProviderValue>({
  isUserAuthenticated: false,
  user: undefined,
  loading: false,
  error: null,
  setUserState: () => {},
})

type TUserProviderProps = {
  isUserAuthenticated: boolean
  children: ReactNode
}

export const UserProvider = ({
  isUserAuthenticated = false,
  children,
}: TUserProviderProps) => {
  const rollbar = useRollbar()
  const [userState, setUserState] = useState<UserState>(UserState.Initial)
  const [error, setError] = useState<unknown>()
  const [user, setUser] = useState<TUser>()

  const handleGetUser = useCallback(() => {
    setUserState(UserState.Loading)
    // We are using regular promises here as we want to run two requests in parallel
    // They do not depend on each other and we want to set user state as soon as it is fulfilled
    // without waiting for the feature flag request's settlement
    getUser()
      .then(userResponse => {
        setUser(userResponse)
        setUserState(UserState.Loaded)
      })
      .catch(error => {
        setUserState(UserState.Error)
        setError(error)
        rollbar.warning(
          `Error while loading user info: ${error.message}`,
          error
        )
      })
  }, [rollbar])

  useEffect(() => {
    if (userState === UserState.Initial && isUserAuthenticated) {
      handleGetUser()
    }
  }, [handleGetUser, isUserAuthenticated, userState])

  const contextValue = useMemo(
    () => ({
      isUserAuthenticated,
      loading: userState === UserState.Loading,
      error,
      user,
      setUserState,
    }),
    [userState, error, user, isUserAuthenticated]
  )

  return (
    <UserProviderContext.Provider value={contextValue}>
      {children}
    </UserProviderContext.Provider>
  )
}

export default memo(UserProvider)

export const useUser = () => useContext(UserProviderContext)
