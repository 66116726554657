import { NAMESPACES } from 'constants/locales'
import { TYPEFORM_ROOT_DOMAIN } from 'constants/public-envs'

import Button, { ButtonSize, ButtonVariant } from 'components/atoms/button'
import React, { useCallback } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { trackLoginButtonOnSignupPageClick } from 'components/tracking'

import { Container, Text } from './styled-components'

type TLoginLinkProps = {
  embedAdminAppName?: string | null
}

const LoginLink = ({ embedAdminAppName }: TLoginLinkProps) => {
  const { t } = useTranslation(NAMESPACES.AUTH)

  const handleLoginButtonClick = useCallback(() => {
    trackLoginButtonOnSignupPageClick({ ctaText: t('auth.form.login-button') })
  }, [t])

  const baseUrl = t('auth.form.login-url', { TYPEFORM_ROOT_DOMAIN })
  const url = embedAdminAppName
    ? `${baseUrl}?isEmbedAdmin=${embedAdminAppName}`
    : baseUrl

  return (
    <Container>
      <Text>{t('auth.form.login-text')}</Text>
      <Button
        variant={ButtonVariant.Secondary}
        size={ButtonSize.Small}
        href={url}
        onClick={handleLoginButtonClick}
      >
        {t('auth.form.login-button')}
      </Button>
    </Container>
  )
}

export default LoginLink
