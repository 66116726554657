import React, { MouseEvent, memo, useCallback, useState } from 'react'
import Avatar, { AvatarSize } from 'components/atoms/avatar'
import DropdownMenu, {
  DropdownContainer,
  DropdownLink,
  DropdownSection,
  DropdownTrigger,
} from 'components/atoms/dropdown-menu'
import { TUser } from 'services/user-service/types'

import {
  TUserMenuClickEventHandler,
  TUserMenuItem,
  TUserMenuItemClickEventHandler,
  TUserMenuItems,
} from '../../mega-menu.types'

import {
  UserDetails,
  UserEmail,
  UserInfoBox,
  UserName,
} from './user-dropdown.styles'

export type TUserDropdownProps = {
  menuItems?: TUserMenuItems
  user?: TUser
  onUserMenuClick?: TUserMenuClickEventHandler
  onUserMenuItemClick?: TUserMenuItemClickEventHandler
}

const UserDropdown = ({
  menuItems,
  user,
  onUserMenuClick,
  onUserMenuItemClick,
}: TUserDropdownProps) => {
  const [showDropdown, setShowDropdown] = useState(false)

  const handleDropdownTrigger = useCallback(
    (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      const dropdownState = !showDropdown
      onUserMenuClick?.({ event, dropdownState })

      setShowDropdown(dropdownState)
    },
    [onUserMenuClick, showDropdown]
  )

  const handleDropdownClose = useCallback(() => setShowDropdown(false), [])

  const handleMenuItemClick = useCallback(
    (menuItem: TUserMenuItem) => (event: MouseEvent<HTMLAnchorElement>) => {
      onUserMenuItemClick?.({ item: menuItem, event })
    },
    [onUserMenuItemClick]
  )

  if (!user) {
    return <Avatar.Skeleton size={AvatarSize.Large} />
  }

  const triggerId = 'user-dropdown-toggle'
  const menuId = 'user-dropdown'

  return (
    <DropdownContainer>
      <DropdownTrigger
        data-testid='mega-menu-user'
        id={triggerId}
        menuId={menuId}
        open={showDropdown}
        onClick={handleDropdownTrigger}
      >
        <Avatar
          email={user.email}
          name={user.name || ''}
          size={AvatarSize.Large}
        />
      </DropdownTrigger>

      <DropdownMenu
        id={menuId}
        triggerId={triggerId}
        open={showDropdown}
        onRequestClose={handleDropdownClose}
        alignment='Right'
      >
        <DropdownSection>
          <UserInfoBox>
            <Avatar
              email={user.email}
              name={user.name || ''}
              size={AvatarSize.Medium}
            />
            <UserDetails>
              <UserName>{user.name}</UserName>
              <UserEmail>{user.email}</UserEmail>
            </UserDetails>
          </UserInfoBox>
        </DropdownSection>
        {menuItems?.map(({ id, items }) => (
          <DropdownSection key={id}>
            {items.map(menuItem => (
              <DropdownLink
                key={menuItem.id}
                href={menuItem.link}
                destructive={menuItem.destructive}
                onClick={handleMenuItemClick(menuItem)}
              >
                {menuItem.label}
              </DropdownLink>
            ))}
          </DropdownSection>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  )
}

export default memo(UserDropdown)
