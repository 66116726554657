import styled, { keyframes } from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

const spinningAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  align-items: center;
  display: flex;
`

export const SpinnerCircle = styled.div`
  animation: ${spinningAnimation} 0.8s infinite linear;
  width: 20px;
  height: 20px;
  border-style: solid;
  border-color: ${NeutralColors.Grey700} ${NeutralColors.Grey700}
    ${NeutralColors.Grey200} ${NeutralColors.Grey200};
  border-width: 2px;
  border-radius: 50%;
`
