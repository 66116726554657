import React, { useCallback, useEffect, useState, MouseEvent } from 'react'

import { SelectPositions, UPCOMING_LOCALE } from '../constants'
import {
  OptionLinkContainer,
  MenuWrapper,
  OptionLink,
} from '../language-switcher.styles'

export type TOption = {
  locale: string
  label: string
  href: string
}

type TLanguagesMenuProps = {
  options: TOption[]
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onLanguageSwitcherOptionClick: (optionEvent: {
    option: TOption
    event: React.MouseEvent<HTMLAnchorElement>
  }) => void
  position: SelectPositions
}

const LanguagesMenu: React.FC<TLanguagesMenuProps> = ({
  options,
  isOpen,
  setIsOpen,
  onLanguageSwitcherOptionClick,
  position,
}) => {
  const [isFirstLanguageSelected, setIsFirstLanguageSelected] =
    useState<boolean>(true)

  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  const handleMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.currentTarget.focus({
        preventScroll: true,
      })
      setIsFirstLanguageSelected(false)
    },
    []
  )

  const handleMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.currentTarget.blur()
      setIsFirstLanguageSelected(false)
    },
    []
  )

  const handleOptionClick = useCallback(
    (option: TOption) => (event: React.MouseEvent<HTMLAnchorElement>) => {
      onLanguageSwitcherOptionClick({ option, event })
    },
    [onLanguageSwitcherOptionClick]
  )

  return (
    <MenuWrapper role='menu' isOpen={isOpen} position={position}>
      {options.map(({ href, label, locale }, index) => {
        const rel = Object.keys(UPCOMING_LOCALE).includes(locale)
          ? 'noindex,nofollow'
          : undefined

        return (
          <OptionLinkContainer
            as='a'
            key={label}
            href={href}
            onClick={handleOptionClick({ href, label, locale })}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            isFirstLanguageSelected={isFirstLanguageSelected && index === 0}
            role='menuitem'
            data-testid='language-item'
            rel={rel}
          >
            <OptionLink href={href} key={locale}>
              {label}
            </OptionLink>
          </OptionLinkContainer>
        )
      })}
    </MenuWrapper>
  )
}

export default LanguagesMenu
