import mediaQueries from 'util/mediaqueries'
import { spacing } from 'util/spacing'
import { MOBILE_HEADER_HEIGHT } from 'constants/design'

import styled, { css } from 'styled-components'

export type TMenuTriggerSvgProps = {
  isVisible: boolean
  id?: string
}

export const MobileMenuTriggerContainer = styled.div`
  order: 1;
  position: relative;
  ${mediaQueries.md`
    display: none;
  `}
`

export const TriggerButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: 0;
  position: relative;
  color: inherit;
  width: ${spacing(7)};
  height: ${MOBILE_HEADER_HEIGHT}px;
  margin-right: -${spacing(2)};

  ${mediaQueries.sm`
    margin-right: -${spacing(3)};
    width: ${spacing(9)};
  `}
`
export const NoJsMenuTrigger = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: -${spacing(3)};
  bottom: 0;
  margin: 0;
  background: none;
  appearance: none;
  border: none;
  cursor: pointer;
`

export const triggerSvgVisibleStyles = css`
  visibility: visible;
  opacity: 1;
`
export const TriggerSvg = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg',
  width: '24',
  height: '24',
  fill: 'none',
  viewBox: '0 0 24 24',
})<TMenuTriggerSvgProps>`
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  will-change: opacity, visibility;
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;

  ${({ isVisible }) => isVisible && triggerSvgVisibleStyles}
`
