import { TBackEndPricingPlan, TDiscount } from 'constants/pricing/types'

import { CurrencyCode } from '@typeform/feature-set-matrix/lib/generated/graphql'
import { Device } from '@typeform/ginger/dist/constants/device'
import { TConditionalModule } from 'models/conditional-module-model.types'

export type TFaq = {
  contentType?: string
  title: string
  description: { html: object }
}

export enum PlanSet {
  Core = 'core',
  Growth = 'growth',
}

export type TPricingPropTypes = {
  faqs?: Array<TFaq>
  headline?: TConditionalModule
  featureToggles?: object
  corePricingPlans: TBackEndPricingPlan[]
  growthPricingPlans: TBackEndPricingPlan[]
  discounts: {
    yearly: TDiscount[]
    monthly: TDiscount[]
  }
  currencyCode: CurrencyCode
  device: Device
  locale: string
  initialPlanSet?: PlanSet
  isGrowthPlansActive: boolean
}
