export const getLocalizedHref = (
  link = '',
  t,
  defaultLink = '',
  translationOptions = {}
) => {
  if (link.startsWith('/') || link.startsWith('http')) {
    return link
  }

  const translatedLink = t(link, translationOptions)

  if (link === translatedLink) {
    return defaultLink
  }

  return translatedLink.startsWith('/') || translatedLink.startsWith('http')
    ? translatedLink
    : `/${translatedLink}`
}
