import styled from 'styled-components'
import { fontSize } from '@typeform/ginger/dist/constants/typography'
import Button from 'components/atoms/button'

export const Text = styled.p`
  font-size: ${fontSize.medium};
  line-height: 32px;
`

export const StyledButton = styled(Button)`
  width: 100%;
`
