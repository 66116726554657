export { default as JSFeatureDetector } from './js-feature-detector'
export { JS_CLASSNAME, NO_JS_CLASSNAME, Origin, Axis } from './constants'
export {
  getCombinedTransitionString,
  StyledAnimatedContainer,
} from './animated-container.styles'
export * from './animations'

export { default as AnimatedContainerNoJSReset } from './animated-container-no-js-reset'
export { default } from './animated-container'
