import { NAMESPACES } from 'constants/locales'

import React from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Divider, Text } from './auth-divider.styles'

const AuthDivider = () => {
  const { t } = useTranslation(NAMESPACES.AUTH)
  return (
    <Divider className='auth-divider'>
      <Text className='auth-divider-text'>{t('auth.auth-divider')}</Text>
    </Divider>
  )
}

AuthDivider.displayName = 'AuthDivider'

export default AuthDivider
