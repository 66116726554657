import { GRID_UNIT } from 'constants/design'

/**
 * @typedef {number|'auto'} Factor
 */

/**
 * @param {Factor} factor
 */
const getSpacing = factor => {
  if (factor === 'auto') return factor

  if (typeof factor === 'number') {
    return factor === 0 ? factor : `${GRID_UNIT * factor}px`
  }

  return ''
}

/**
 * @param {Factor} [f1=1]
 * @param {Factor} [f2]
 * @param {Factor} [f3]
 * @param {Factor} [f4]
 * @returns {string}
 */
export const spacing = (f1 = 1, f2, f3, f4) => {
  return `${getSpacing(f1)} ${getSpacing(f2)} ${getSpacing(f3)} ${getSpacing(
    f4
  )}`.trim()
}
