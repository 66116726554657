export const IMAGE_LOADING = {
  eager: 'eager',
  lazy: 'lazy',
} as const

export const IMAGE_DECODING = {
  default: 'auto',
  sync: 'sync',
  async: 'async',
} as const

export const IMAGE_FETCHPRIORITY = {
  default: 'auto',
  low: 'low',
  high: 'high',
} as const
