import styled, { css } from 'styled-components'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import {
  fontSize,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import mediaQueries, {
  mediaQueriesDown,
} from '@typeform/ginger/dist/util/mediaqueries'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

import {
  HEADER_HEIGHT,
  MOBILE_CTAS_CONTAINER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  TOP_MENU_MAX_WIDTH_MOBILE,
} from '../../mega-menu.constants'

type TTopMenuListProps = {
  isUserDropdownVisible: boolean
  isMobileMenuOpen: boolean
  cookieBannerHeight: number
  bannerHeight?: number
}

export const mobileMenuOpenStyles = css`
  visibility: visible;
  z-index: 1;
  max-height: 100%;
`

export const TopMenuList = styled.ul<TTopMenuListProps>`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  ${mediaQueriesDown.md`
    flex-direction: column;
    position: fixed;
    top: ${({ bannerHeight }: TTopMenuListProps) =>
      `${MOBILE_HEADER_HEIGHT + (bannerHeight || 0)}px`};
    left: 0;
    right: 0;
    bottom: ${({
      isUserDropdownVisible,
      cookieBannerHeight,
    }: TTopMenuListProps) =>
      `${
        (isUserDropdownVisible ? 0 : MOBILE_CTAS_CONTAINER_HEIGHT) +
        cookieBannerHeight
      }px`};
    overflow-y: auto;
    z-index: -1;
    padding-left: ${spacing(2)};
    padding-right: ${spacing(2)};
    transition: max-height 200ms ease-in-out, visibility 200ms ease-in-out;
    visibility: hidden;
    max-height: 0;

    ${({ isMobileMenuOpen }: TTopMenuListProps) =>
      isMobileMenuOpen && mobileMenuOpenStyles}
  `}
`

export const TopMenuButton = styled.a.attrs(({ href }) => ({
  as: !href ? 'button' : 'a',
}))`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: ${fontSize.xLarge};
  line-height: ${lineHeight.xSmall};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${spacing(1)};
  color: inherit;
  flex-grow: 1;
  justify-content: space-between;
  min-height: ${MOBILE_HEADER_HEIGHT}px;
  transition: color 100ms;
  text-align: left;
  z-index: 1;

  svg {
    width: ${spacing(3)};
    height: ${spacing(3)};
  }

  ${mediaQueries.md`
    padding: 8px;
    min-height: ${HEADER_HEIGHT}px;
    font-size: ${fontSize.small};
    text-align: center;

    svg {
      width: ${spacing(2)};
      height: ${spacing(2)};
    }
  `}
`
type TTopMenuItemContainerProps = {
  isActive: boolean
  palette: Palette
}

export const topMenuActiveStyles = css<TTopMenuItemContainerProps>`
  ${TopMenuButton} {
    color: ${({ palette }) =>
      palette === Palette.Negative
        ? NeutralColors.Grey500
        : NeutralColors.Grey600};
    svg {
      transform: scaleY(-1);
    }
  }
`

export const TopMenuItemContainer = styled.li<TTopMenuItemContainerProps>`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  max-width: ${TOP_MENU_MAX_WIDTH_MOBILE}px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  ${mediaQueries.md`
    width: auto;
    margin-left: unset;
    margin-right: unset;
    max-width: unset;
    height: ${HEADER_HEIGHT}px;
    padding-left: ${spacing(1)};
    padding-right: ${spacing(1)};
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  `}

  ${({ isActive }) => isActive && topMenuActiveStyles}
`
