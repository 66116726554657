import { CTA_VARIANT } from '@typeform/ginger/dist/constants/cta'
import React, { memo } from 'react'
import {
  NeutralColors,
  UIPalette,
} from '@typeform/ginger/dist/constants/colors'
import { TEXT_ALIGNMENT } from '@typeform/ginger/dist/constants/design'

import BannerLink from './banner-link'
import BannerPrimary from './banner-primary'
import { StyledBannerContainer } from './base.styles'
import { TBanner } from './banner.types'
import { DisplayType } from './constants'

const Banner = React.forwardRef(
  (
    {
      backgroundColor = UIPalette.Emerald,
      textColor = NeutralColors.White,
      cta,
      // @ts-expect-error will be fixed when CTA_VARIANT is migrated from ginger
      ctaVariant = CTA_VARIANT.Link,
      dismissible = false,
      dismissButtonLabel = 'Dismiss banner',
      displayType = DisplayType['Fixed'],
      message,
      onDismiss,
      // @ts-expect-error will be fixed when TEXT_ALIGNMENT is migrated from ginger
      textAlign = TEXT_ALIGNMENT['LEFT'],
    }: TBanner,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    let banner

    switch (ctaVariant) {
      case CTA_VARIANT.Link:
        banner = (
          <BannerLink
            backgroundColor={backgroundColor}
            textColor={textColor}
            cta={cta}
            dismissible={dismissible}
            dismissButtonLabel={dismissButtonLabel}
            message={message}
            onDismiss={onDismiss}
          />
        )
        break

      case CTA_VARIANT.Primary:
        banner = (
          <BannerPrimary
            backgroundColor={backgroundColor}
            textColor={textColor}
            cta={cta}
            dismissible={dismissible}
            dismissButtonLabel={dismissButtonLabel}
            message={message}
            onDismiss={onDismiss}
          />
        )
        break

      default:
        banner = null
    }

    return (
      <StyledBannerContainer
        displayType={displayType}
        ref={ref}
        textAlign={textAlign}
      >
        {banner}
      </StyledBannerContainer>
    )
  }
)

export default memo(Banner)
