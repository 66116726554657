import kebabCase from 'lodash.kebabcase'
import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'
import { PlanSet } from 'components/pricing-page/types'

import { getAppPublicBaseUrl } from '../environment-variables'
import {
  HELP_PATH,
  CONNECT_PATH,
  TEMPLATES_PATH,
  SPANISH_TEMPLATES_PATH,
  API_PATH,
  CAREERS_PATH,
  GUIDE_PATH,
  SIGNUP_PATH,
  PRICING_PATH,
  CATEGORY_PATH,
  TEMPLATE_PATH,
  SECTION_PATH,
  ARTICLE_PATH,
  ADMIN_BASE_URL,
  SPANISH_PRICING_PATH,
  EXPLORE_PATH,
} from '../../constants/url'

export const getPathLang = lang => {
  if (
    [
      LANGUAGE_CODES.spanish,
      LANGUAGE_CODES.french,
      LANGUAGE_CODES.german,
    ].includes(lang)
  ) {
    return lang
  }
  return ''
}

const formatRoute =
  (lang, baseUrl) =>
  (path = '') => {
    const baseUrlWithOutTrailingSlash = baseUrl.replace(/\/$/, '')
    const pathLang = getPathLang(lang)
    return `${baseUrlWithOutTrailingSlash}${
      pathLang ? '/' : ''
    }${pathLang}${path}/`
  }

const getArticleSlug = article => article?.slug
const getCategorySlug = category => category?.slug
const getArticleCategorySlug = article => article?.category?.slug
const getAuthorSlug = author => author?.slug

export const routes = (lang = LANGUAGE_CODES.english, baseUrl = '') => {
  const route = formatRoute(lang, baseUrl)
  return {
    home: route(),
    try: {
      home: '/try/typeformbrand/',
    },
    api: {
      health: `/${API_PATH}/_health/`,
      oldHcCatchall: `/${API_PATH}/old-hc-catchall/`,
      sitemap: `/${API_PATH}/sitemap/`,
      help: {
        deleteAttachment: token =>
          `/${API_PATH}/${HELP_PATH}/contact/attachment/${token}/`,
      },
    },
    login: route(`/login`),
    privateTypeform: route('/private-typeform'),
    guide: {
      path: route(`/${GUIDE_PATH}`),
    },
    help: {
      path: route(`/${HELP_PATH}`),
      category: category => route(`/${getHCCategorySlug(category)}`),
      section: section => route(`/${getHCSectionSlug(section)}`),
      article: section => route(`/${getHCArticleSlug(section, 'title')}`),
      contact: {
        path: route(`/${HELP_PATH}/contact`),
        form: formId => route(`/${HELP_PATH}/contact/${formId}`),
      },
      search: route(`/${HELP_PATH}/search`),
    },
    careers: {
      path: route(`/${CAREERS_PATH}`),
      department: department => route(`/${CAREERS_PATH}/${department}`),
    },
    connect: {
      path: route(`/${CONNECT_PATH}`),
      category: category => route(`${getAppDirectoryCategorySlug(category)}`),
      app: app => route(`${getAppDirectoryAppSlug(app)}`),
      integration: ({ app, integration }) =>
        route(
          `${getAppDirectoryIntegrationSlug({
            app,
            integration,
          })}`
        ),
    },
    signup: route(`/${SIGNUP_PATH}`),
    pricing: route(`/${getPricingPath(lang)}`),
    pricingWithPlanSet: planSet => {
      if (planSet === PlanSet.Core) {
        return route(`/${getPricingPath(lang)}`)
      }

      return route(`/${getPricingPath(lang)}/${planSet}`)
    },
    oldSignup: route('/old-signup'),
    templates: {
      path: route(`/${getTemplateGalleryPath(lang)}`),
      category: categorySlug =>
        route(`/${getPtgCategorySlug({ lang, categorySlug })}`),
      subcategory: slug => route(`/${getPtgSubcategorySlug({ lang, slug })}`),
      template: templateSlug =>
        route(
          `/${getTemplateGalleryPath(lang)}/${TEMPLATE_PATH}/${templateSlug}`
        ),
      arbitrarySlug: slug =>
        slug
          ? route(`/${getTemplateGalleryPath(lang)}/${slug}`)
          : route(`/${getTemplateGalleryPath(lang)}`),
    },
    templatesRevamp: {
      subCategory: (categorySlug, subCategorySlug) =>
        route(
          `/${getTemplateGalleryPath(
            lang
          )}/${CATEGORY_PATH}/${categorySlug}/${subCategorySlug}`
        ),
    },
    blog: {
      path: route('/blog'),
      article: article =>
        route(
          `/blog/${getArticleCategorySlug(article)}/${getArticleSlug(article)}`
        ),
      category: category => route(`/blog/${getCategorySlug(category)}`),
      author: author => route(`/blog/author/${getAuthorSlug(author)}`),
    },
    explore: route(`/${EXPLORE_PATH}`),
  }
}
export const getPricingPath = language => {
  return language === LANGUAGE_CODES.spanish
    ? SPANISH_PRICING_PATH
    : PRICING_PATH
}
export const getTemplateGalleryPath = language => {
  return language === LANGUAGE_CODES.spanish
    ? SPANISH_TEMPLATES_PATH
    : TEMPLATES_PATH
}

export const getHCCategorySlug = category =>
  `${HELP_PATH}/${CATEGORY_PATH}/${entityToSlug(category)}`
export const getHCSectionSlug = section =>
  `${HELP_PATH}/${SECTION_PATH}/${entityToSlug(section)}`
export const getHCArticleSlug = section =>
  `${HELP_PATH}/${ARTICLE_PATH}/${entityToSlug(section, 'title')}`
export const getAppDirectoryCategorySlug = category => {
  return `/${CONNECT_PATH}/${CATEGORY_PATH}/${category?.slug}`
}
export const getAppDirectoryAppSlug = app => {
  return `/${CONNECT_PATH}/${app?.slug}`
}
export const getAppDirectoryIntegrationSlug = ({ app, integration }) => {
  return `/${CONNECT_PATH}/${app?.slug}/${integration?.slug}`
}
export const getPtgCategorySlug = ({ lang, categorySlug }) =>
  `${getTemplateGalleryPath(lang)}/${CATEGORY_PATH}/${categorySlug}`
export const getPtgSubcategorySlug = ({ lang, slug }) => {
  const parts = slug.split('/')
  if (parts.length === 2) {
    return `${getTemplateGalleryPath(lang)}/${CATEGORY_PATH}/${parts[0]}/${
      parts[1]
    }`
  }
  return getPtgCategorySlug({ lang, categorySlug: slug })
}

export const parseIdFromSlug = slug => {
  if (typeof slug !== 'string') {
    return null
  }
  const regex = /(\d+)$/i
  const match = slug.match(regex)
  if (!Array.isArray(match)) {
    return null
  }
  return match?.[1]
}
export const parseContentfulIdFromSlug = slug => {
  if (typeof slug !== 'string') {
    return null
  }
  const parts = slug.split('-')
  return parts?.[parts.length - 1]
}

const entityToSlug = (entity, titleKey = 'name') => {
  return encodeURIComponent(kebabCase(`${entity[titleKey]}-${entity.id}`))
}

export const doArticleSlugsMatch = ({ articleSlug, article }) => {
  const canonicalArticleSlug = entityToSlug(article, 'title')
  if (canonicalArticleSlug !== articleSlug) {
    return false
  }
  return true
}
export const getAuthRedirectDestination = ({ currentUrl, locale }) => {
  let pathLocale = ''
  if (locale) {
    pathLocale = locale === LANGUAGE_CODES.english ? '' : `/${locale}`
  }

  const destination = `${ADMIN_BASE_URL}/login${pathLocale}?redirect_uri=${encodeURIComponent(
    currentUrl || getAppPublicBaseUrl()
  )}&message=login_support`
  return destination
}
