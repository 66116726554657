// Copied from Kitt
// https://github.com/Typeform/kitt/blob/05d879218730c3562ab6a47329e5e12b4e2acd35/src/utils/get-element-by-text.ts

import { TAvatarColor, TAvatarBackground } from '../avatar.types'

export type TGetElementByText = (
  text: string,
  elements: TAvatarColor[] | TAvatarBackground[]
) => [TAvatarColor | TAvatarBackground, number]

export const getElementByText: TGetElementByText = (text, elements) => {
  const num = text.split('').reduce((acum, val) => val.charCodeAt(0) + acum, 0)
  const selectionIndex = num % elements.length
  return [elements[selectionIndex], selectionIndex]
}
