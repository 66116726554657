import { Palette } from '@typeform/ginger/dist/constants/palettes'
import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import {
  MutableRefObject,
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useCookieBannerHeight } from 'components/organisms/cookie-banner'
import { TTopMenuItem } from 'models/top-menu-item-model.types'

import { useIsMenuScrolled } from './helpers/use-is-menu-scrolled'
import { useIsMobileMenu } from './helpers/use-is-mobile-menu'
import {
  TMenuItemClickHandler,
  TSubMenuItemClickEventHandler,
  TUserMenuClickEventHandler,
  TUserMenuItemClickEventHandler,
} from './mega-menu.types'
import {
  DEBOUNCE_THRESHOLD,
  TRACKING_THROTTLE_INTERVAL,
} from './mega-menu.constants'

type TSubMenuElementsRefObject = {
  [key: string]: HTMLDivElement | null
}

type TMenuContextObject = {
  palette: Palette
  transparentBackground: boolean
  isMobileMenu: boolean
  isMobileMenuOpen: boolean
  isMenuScrolled: boolean
  isUserDropdownVisible: boolean
  activeSubMenuItem: TTopMenuItem | null
  cookieBannerHeight: number
  subMenuElementsRef?: MutableRefObject<TSubMenuElementsRefObject>
  setActiveSubMenuItem: (menuItem: TTopMenuItem | null) => void
  setIsMobileMenuOpen: (isMenuOpen: boolean) => void
  onUserMenuClick?: TUserMenuClickEventHandler
  onUserMenuItemClick?: TUserMenuItemClickEventHandler
  onMenuItemClick?: TMenuItemClickHandler
  onSubMenuItemClick?: TSubMenuItemClickEventHandler
}

const MenuContext = createContext<TMenuContextObject>({
  palette: Palette.Positive,
  transparentBackground: true,
  isMobileMenu: false,
  isMobileMenuOpen: false,
  isMenuScrolled: false,
  isUserDropdownVisible: false,
  activeSubMenuItem: null,
  cookieBannerHeight: 0,
  setActiveSubMenuItem: () => {},
  setIsMobileMenuOpen: () => {},
})

export const useMenuContext = () => useContext(MenuContext)

type TMegaMenuProviderProps = {
  palette: Palette
  children: ReactNode
  transparentBackground: boolean
  hasUser: boolean
  hasUserMenuItems: boolean
  isFetchingUser: boolean
  onUserMenuClick?: TUserMenuClickEventHandler
  onUserMenuItemClick?: TUserMenuItemClickEventHandler
  onMenuItemClick?: TMenuItemClickHandler
  onSubMenuItemClick?: TSubMenuItemClickEventHandler
}

const MegaMenuProvider = ({
  children,
  palette,
  transparentBackground,
  hasUser,
  hasUserMenuItems,
  isFetchingUser,
  onUserMenuClick,
  onUserMenuItemClick,
  onMenuItemClick,
  onSubMenuItemClick,
}: TMegaMenuProviderProps) => {
  const isMenuScrolled = useIsMenuScrolled()
  const isMobileMenu = useIsMobileMenu()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [activeSubMenuItem, setActiveSubMenuItem] =
    useState<TTopMenuItem | null>(null)
  const subMenuElementsRef = useRef<TSubMenuElementsRefObject>({})
  const cookieBannerHeight = useCookieBannerHeight() || 0
  const throttledOnMenuItemClick = useMemo(
    () =>
      onMenuItemClick
        ? throttle(onMenuItemClick, TRACKING_THROTTLE_INTERVAL, {
            leading: false,
          })
        : null,
    [onMenuItemClick]
  )

  const debouncedSetActiveSubMenuItem = useMemo(
    () => debounce(setActiveSubMenuItem, DEBOUNCE_THRESHOLD),
    []
  )

  const isUserDropdownVisible = useMemo(() => {
    if (!hasUserMenuItems) {
      return false
    }
    return hasUser || isFetchingUser
  }, [hasUserMenuItems, hasUser, isFetchingUser])

  useEffect(() => {
    if (!isMobileMenu) {
      setIsMobileMenuOpen(false)
    }
  }, [isMobileMenu])

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [isMobileMenu, isMobileMenuOpen])

  useEffect(() => {
    throttledOnMenuItemClick?.cancel?.()
    debouncedSetActiveSubMenuItem?.cancel?.()

    if (!!activeSubMenuItem && throttledOnMenuItemClick) {
      throttledOnMenuItemClick(activeSubMenuItem)
    }
  }, [
    activeSubMenuItem,
    debouncedSetActiveSubMenuItem,
    throttledOnMenuItemClick,
  ])

  const contextValue = useMemo(() => {
    return {
      palette,
      transparentBackground,
      isMobileMenu,
      isMobileMenuOpen,
      isMenuScrolled,
      isUserDropdownVisible,
      activeSubMenuItem,
      cookieBannerHeight,
      subMenuElementsRef,
      setIsMobileMenuOpen,
      setActiveSubMenuItem: isMobileMenu
        ? setActiveSubMenuItem
        : debouncedSetActiveSubMenuItem,
      onUserMenuClick,
      onUserMenuItemClick,
      onMenuItemClick,
      onSubMenuItemClick,
    }
  }, [
    activeSubMenuItem,
    cookieBannerHeight,
    debouncedSetActiveSubMenuItem,
    isMenuScrolled,
    isMobileMenu,
    isMobileMenuOpen,
    isUserDropdownVisible,
    onMenuItemClick,
    onSubMenuItemClick,
    onUserMenuClick,
    onUserMenuItemClick,
    palette,
    transparentBackground,
  ])

  return (
    <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>
  )
}

MegaMenuProvider.displayName = 'MegaMenuProvider'

export default memo(MegaMenuProvider)
