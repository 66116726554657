import { TYPEFORM_ROOT_DOMAIN } from 'constants/public-envs'
import { NAMESPACES } from 'constants/locales'

import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import React, { useState } from 'react'
import Collapse from 'components/atoms/common/collapse'
import useTranslation from 'next-translate/useTranslation'

import {
  trackSignupFormElement,
  trackSignupOpenConsentsOptions,
} from '../../tracking'

import { RadioToggleGroup, InputCheckboxGdpr } from './form-elements'
import {
  CaretUpWrapper,
  ConsentsOptions,
  SeeOptions,
  SmallText,
} from './consents.styles'
import CaretUp from './caret-up'
import {
  formFields,
  consentFields,
  CONSENTS_OPTIONS,
  TERMS_AND_CONSENTS,
  MARKETING,
  TAILOR_NEEDS_AGREED,
  THIRD_PARTIES_AGREED,
  FORM_ELEMENT_TYPES_MAP,
} from './constants'
import {
  generateConsentsDataFields,
  getAcceptedConsents,
  storeConsentsStatus,
} from './helpers'
import TermsAndConditionsValidation from './terms-and-conditions-validation'

const Consents = () => {
  const { t } = useTranslation(NAMESPACES.AUTH)
  const [isConsentOptionsOpen, setIsConsentOptionsOpen] = useState(false)
  const [isConsentOptionsAlreadyChecked, setIsConsentOptionsAlreadyChecked] =
    useState(false)

  const [fields, setFields] = useState({
    [TERMS_AND_CONSENTS]: false,
    [MARKETING]: null,
    [TAILOR_NEEDS_AGREED]: null,
    [THIRD_PARTIES_AGREED]: null,
  })

  const updateState = (name, value) => {
    let consentsValues = {}

    if (name === TERMS_AND_CONSENTS) {
      if (!isConsentOptionsAlreadyChecked && value) {
        consentsValues = getAcceptedConsents()
        setIsConsentOptionsAlreadyChecked(true)
      }
    }

    trackSignupFormElement(name, value, FORM_ELEMENT_TYPES_MAP)

    const consentsFields = { ...fields, [name]: value, ...consentsValues }

    setFields(consentsFields)
    storeConsentsStatus(consentsFields)
  }

  const handleChange = (name, value) => {
    updateState(name, value)
  }

  const handleConsentOptionsClick = e => {
    e.preventDefault()

    if (!isConsentOptionsOpen) {
      trackSignupOpenConsentsOptions()
    }
    setIsConsentOptionsOpen(isOpen => {
      return !isOpen
    })
  }

  return (
    <>
      {formFields.map(({ label, ...fieldProps }) => (
        <InputCheckboxGdpr
          {...fieldProps}
          key={fieldProps.id}
          label={t(label, { TYPEFORM_ROOT_DOMAIN })}
          onChange={handleChange}
          value={fields[fieldProps.id]}
        />
      ))}
      <TermsAndConditionsValidation
        isConsentGranted={fields[TERMS_AND_CONSENTS]}
      />
      <ConsentsOptions>
        <SeeOptions
          {...generateConsentsDataFields(fields)}
          data-field-id={CONSENTS_OPTIONS}
          data-testid={CONSENTS_OPTIONS}
          onClick={handleConsentOptionsClick}
        >
          <SmallText>{t('auth.see-options')}</SmallText>
          <CaretUpWrapper rotateDegrees={isConsentOptionsOpen ? 0 : 180}>
            <CaretUp />
          </CaretUpWrapper>
        </SeeOptions>
        <Collapse isOpened={isConsentOptionsOpen}>
          {consentFields.map(({ label, id, link }) => (
            <RadioToggleGroup
              accepted={fields[id]}
              key={id}
              label={t(label)}
              link={link}
              onChange={handleChange}
              radioId={id}
            />
          ))}
          <SmallText color={NeutralColors.Grey600}>
            {t('auth.consents.information')}
          </SmallText>
        </Collapse>
      </ConsentsOptions>
    </>
  )
}

export default Consents
