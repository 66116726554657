import { createGlobalStyle, css } from 'styled-components'

import { NO_JS_CLASSNAME } from './constants'
import { StyledAnimatedContainer } from './animated-container.styles'

const AnimatedContainerNoJSResetStyle = createGlobalStyle`
  .${NO_JS_CLASSNAME} ${StyledAnimatedContainer} {
    ${({ propertiesToReset }) =>
      propertiesToReset.map(
        (property: string) => css`
          ${property}: unset;
        `
      )}
  }
`

const AnimatedContainerNoJSReset = ({
  propertiesToReset = ['transform', 'opacity', 'transition', 'animation'],
}: {
  propertiesToReset?: string[]
}) => {
  return (
    <AnimatedContainerNoJSResetStyle propertiesToReset={propertiesToReset} />
  )
}

export default AnimatedContainerNoJSReset
