import React, { memo } from 'react'

import { TOktaConfig } from '../../types'

import OktaWidget from './okta-widget'

const ViaEmail = ({
  handleRegistrationComplete,
  oktaConfig,
}: {
  handleRegistrationComplete: () => void
  oktaConfig: TOktaConfig
}) => {
  return (
    <OktaWidget
      oktaConfig={oktaConfig}
      onRegistrationComplete={handleRegistrationComplete}
    />
  )
}

export default memo(ViaEmail)
