import React, { memo } from 'react'

export const BodyTracking = memo(({ GTM_ID }: { GTM_ID: string }) => {
  if (!GTM_ID) {
    // eslint-disable-next-line no-console
    console.error('GTM_ID is missing in BodyTracking')
    return null
  }
  const googleTagManagerIframe = `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  return (
    <noscript
      data-testid='gtm-no-script'
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={{ __html: googleTagManagerIframe }}
      // We add this id so that js-tracking does not load its own container
      // js-tracking checks for the presence of this id
      id={`gtm-no-script-${GTM_ID}`}
    />
  )
})

BodyTracking.displayName = 'BodyTracking'
