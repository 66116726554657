import {
  NeutralColors,
  UIPalette,
} from '@typeform/ginger/dist/constants/colors'
import React from 'react'
import { CloseIcon } from 'components/atoms/common/close-icon'

import {
  StyledContainer,
  StyledCtaLink,
  StyledMessage,
  StyledMessageContainer,
} from './banner-link.styles'
import { StyledDismissContainer } from './base.styles'
import { TBanner, TBannerCtaLink } from './banner.types'

const CtaLink = ({ url, text, target, onClick }: TBannerCtaLink) => {
  if (!url || !text) {
    return null
  }

  return (
    <>
      {' '}
      <StyledCtaLink
        href={url}
        onClick={onClick}
        onAuxClick={onClick}
        rel={target ? 'noopener noreferrer' : undefined}
        target={target}
        data-gtm-event='cta_promo'
      >
        {text}
      </StyledCtaLink>
    </>
  )
}

CtaLink.displayName = 'CtaLink'

const BannerLink = ({
  backgroundColor = UIPalette['Emerald'],
  textColor = NeutralColors['White'],
  cta,
  dismissible = false,
  dismissButtonLabel = 'Dismiss banner',
  message = '',
  onDismiss,
}: TBanner) => {
  return (
    <StyledContainer
      $backgroundColor={backgroundColor}
      $color={textColor}
      $dismissible={dismissible}
    >
      <StyledMessageContainer>
        <StyledMessage dangerouslySetInnerHTML={{ __html: message }} />
        <CtaLink {...cta} />
      </StyledMessageContainer>

      {dismissible && (
        <StyledDismissContainer
          data-testid='banner-link-dismissible-icon'
          onClick={onDismiss}
          aria-label={dismissButtonLabel}
        >
          <CloseIcon fill={textColor} />
        </StyledDismissContainer>
      )}
    </StyledContainer>
  )
}

BannerLink.displayName = 'BannerLink'

export default BannerLink
