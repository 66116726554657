import md5 from 'md5'

const getAvatarSource = (hash: string, size: number) => {
  return `https://www.gravatar.com/avatar/${hash}?s=${size}&r=g&d=blank`
}

const getAvatarSources = (
  email?: string
): {
  src?: string
  srcSet?: string
} => {
  if (!email) return {}

  const formattedEmail = email.trim().toLowerCase()
  const hash = md5(formattedEmail, { encoding: 'binary' })

  return {
    src: getAvatarSource(hash, 50),
    srcSet: [
      `${getAvatarSource(hash, 100)} 2x`,
      `${getAvatarSource(hash, 150)} 3x`,
    ].join(),
  }
}

export default getAvatarSources
