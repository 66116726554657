import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from 'constants/design'
import mediaQueries from 'util/mediaqueries'
import { NeutralColors, transparent } from 'constants/colors'
import {
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
} from 'constants/typography'

import { createGlobalStyle, css } from 'styled-components'

const additionalHeaderScrollPadding = 40

const CommonGlobalStyle = css`
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    height: 100%;
    scroll-behavior: smooth;
    scroll-padding-top: ${HEADER_HEIGHT + additionalHeaderScrollPadding}px;

    ${mediaQueries.md`
      scroll-padding-top: ${
        MOBILE_HEADER_HEIGHT + additionalHeaderScrollPadding
      }px;
    `}

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    background-color: ${NeutralColors.White};
    color: ${NeutralColors.Ink};
    font-family: ${fontFamily.apercuPro};
    font-size: ${fontSize.small};
    line-height: ${lineHeight.xSmall};
    margin: 0;
    min-height: 100%;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    background: ${transparent};
    color: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    text-decoration: none;
    vertical-align: baseline;
  }

  strong {
    font-weight: ${fontWeight.bold};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    text-rendering: optimizeSpeed;
    margin: 0;
  }

  ${({ applyTextWrapBalance }) =>
    applyTextWrapBalance &&
    css`
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        text-wrap: balance;
      }
    `}
`

const selectionStyles = css`
  background: ${NeutralColors.Grey300};
  color: ${NeutralColors.Ink};
  text-shadow: none;
`

export const StorybookGlobalStyle = createGlobalStyle`
  ${CommonGlobalStyle}
`
export const GlobalStyle = createGlobalStyle`
  ${CommonGlobalStyle}

  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  /* a hack for hiding an empty space at the bottom created by g2crowd script */
  img[src*='tracking.g2crowd'] {
    position: fixed;
    bottom: 0;
  }

  /* has to be state for each one, otherwise it doesn't work */
  ::-moz-selection {
    ${selectionStyles}
  }

  ::selection {
    ${selectionStyles}
  }

  ${props => props.customGlobalStyle}
`
