import {
  HOSTNAMES_ALLOWLIST,
  HOSTNAMES_BLOCKLIST,
  VERSION,
} from 'constants/tracking'

import isMobile from 'ismobilejs'

export const isAllowedHostname = (hostname = '') => {
  const isBlocked = HOSTNAMES_BLOCKLIST.some(hn => hn === hostname)
  if (isBlocked) {
    return false
  }

  return HOSTNAMES_ALLOWLIST.some(hn => hostname.endsWith(hn))
}

export const getTypeformVersion = () => {
  const isMobileDevice = isMobile(window.navigator).any

  return isMobileDevice ? VERSION.MOBILE : VERSION.DESKTOP
}

export const shouldTrackSearchQueryInput = (input: string): boolean => {
  const inputLength = input.length
  if (input.length > 100 || inputLength === 0) {
    return false
  }
  const regex = /^[a-z0-9,.'!?\s]+$/i
  const shouldTrack = regex.test(input)
  return shouldTrack
}
