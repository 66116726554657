import React from 'react'

import { Container, SpinnerCircle } from './spinner.styles'

const Spinner = props => (
  <Container {...props}>
    <SpinnerCircle />
  </Container>
)

Spinner.displayName = 'Spinner'

export default Spinner
