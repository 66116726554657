import { TBanner } from 'components/molecules/banner/banner.types'
import { transparent } from '@typeform/ginger/dist/constants/colors'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import {
  fontSize,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import styled, { css } from 'styled-components'
import { MEGA_MENU_Z_INDEX } from '@typeform/ginger/dist/components/mega-menu/constants'

import { DisplayType } from './constants'

export const StyledContainer = styled.div<{
  $backgroundColor?: string
  $color?: string
  $dismissible?: boolean
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
  padding: 16px;
  font-size: ${fontSize.xSmall};
  line-height: ${lineHeight.medium};
  display: flex;
  align-items: center;
  gap: 16px;

  ${mediaQueries.sm`
    font-size: ${fontSize.small};
    line-height: ${lineHeight.large};
    padding: 24px;
    gap: 24px;
 `}
`

export const StyledDismissContainer = styled.button`
  cursor: pointer;
  background-color: ${transparent};
  border: none;

  svg {
    height: 9px;
    width: 9px;

    ${mediaQueries.sm`
    height: 13px;
    width: 13px;
  `}
  }
`

export const StyledBannerContainer = styled.div<TBanner>`
  text-align: ${({ textAlign }) => textAlign};
  ${({ displayType }) => {
    if (displayType === DisplayType['Sticky']) {
      return css`
        position: sticky;
        top: 0;

        /* Use MEGA_MENU_Z_INDEX + 1 to make sure the banner is rendered over the menu */
        z-index: ${MEGA_MENU_Z_INDEX + 1};
      `
    }

    return css`
      position: relative;

      /* Use MEGA_MENU_Z_INDEX + 1 to make sure the banner is rendered over the menu */
      z-index: ${MEGA_MENU_Z_INDEX + 1};
    `
  }}
`
