const isPathValid = path => path && typeof path === 'string'

export const getStaticAsset = (path = '', isUsingImageOptimization = false) => {
  const basePath = getAssetsBasePath()
  const validPath = isPathValid(path) ? path : ''
  const updatedPath = validPath.startsWith('/') ? validPath.slice(1) : validPath

  return `${basePath}/static/${
    isUsingImageOptimization ? 'images/' : ''
  }${updatedPath}`
}

/**
 * Resolves the correct path for assets
 * @return {string}
 */
const getAssetsBasePath = () => {
  return process.env.APP_ASSETS_PATH !== '/' ? process.env.APP_ASSETS_PATH : ''
}
