import { NAMESPACES } from 'constants/locales'

import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import useTranslation from 'next-translate/useTranslation'
import { getStaticAsset } from 'utils/assets'

import {
  Section,
  Wrapper,
  Headline,
  Image,
  Copyright,
} from './side-panel.styles'

const SidePanel = () => {
  const { t } = useTranslation(NAMESPACES.AUTH)
  const headlineHtml = t('auth.signup.product-sample.headline')

  return (
    <Section padding='0' backgroundColor={NeutralColors.Ink}>
      <Wrapper>
        <Headline dangerouslySetInnerHTML={{ __html: headlineHtml }} />
        <Image
          src={getStaticAsset('signup-page/product-sample.webp', true)}
          alt='Typeform product sample'
        />
        <Copyright>© Typeform</Copyright>
      </Wrapper>
    </Section>
  )
}

SidePanel.displayName = 'SidePanel'

export default SidePanel
