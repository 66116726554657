import { ForwardedRef, TransitionEvent, forwardRef, memo, useRef } from 'react'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import { useCookieBannerHeight } from 'components/organisms/cookie-banner'
import { MenuLayout } from 'models/top-menu-item-model.types'
import { TTopMenuItemGalleryItem } from 'models/top-menu-item-gallery-item-model.types'
import { TTopMenuItemColumnItem } from 'models/top-menu-item-column-item-model.types'
import { TTopMenuItemColumn } from 'models/top-menu-item-column-model.types'
import { TTopMenuItemSeeMoreLink } from 'models/top-menu-item-see-more-link.types'
import { TNullable } from 'types/generics'

import SeeMoreLink from '../see-more-link/see-more-link'
import { TSubMenuItemClickEventHandler } from '../../mega-menu.types'

import { SubMenuContainer, SubMenuContent } from './sub-menu.styles'
import SubMenuWithColumns from './sub-menu-with-columns'
import { SubMenuWithGallery } from './sub-menu-with-gallery'

type TSubMenuProps = {
  items: TTopMenuItemGalleryItem[] | TTopMenuItemColumn[]
  seeMoreLink?: TNullable<TTopMenuItemSeeMoreLink>
  isActive: boolean
  layout?: MenuLayout
  palette: Palette
  onTransitionEnd?: (event: TransitionEvent<HTMLDivElement>) => void
  onItemClick?: TSubMenuItemClickEventHandler
}
const SubMenu = forwardRef(
  (
    {
      items,
      isActive,
      layout,
      seeMoreLink,
      onTransitionEnd,
      onItemClick,
      palette,
    }: TSubMenuProps,
    ref: ForwardedRef<HTMLDivElement | null>
  ) => {
    const contentRef = useRef<HTMLDivElement | null>(null)
    const cookieBannerHeight = useCookieBannerHeight() || 0
    const maxHeight =
      isActive && contentRef.current ? contentRef.current.offsetHeight : 0

    return (
      <SubMenuContainer
        ref={ref}
        isActive={isActive}
        onTransitionEnd={onTransitionEnd}
        cookieBannerHeight={cookieBannerHeight}
        maxHeight={maxHeight}
      >
        <SubMenuContent ref={contentRef}>
          {layout === MenuLayout.Gallery ? (
            <SubMenuWithGallery
              items={items as TTopMenuItemGalleryItem[]}
              onItemClick={onItemClick}
            />
          ) : (
            <SubMenuWithColumns
              items={items as TTopMenuItemColumnItem[]}
              onItemClick={onItemClick}
              palette={palette}
            />
          )}
          {seeMoreLink && (
            <SeeMoreLink onClick={onItemClick} {...seeMoreLink} />
          )}
        </SubMenuContent>
      </SubMenuContainer>
    )
  }
)

export default memo(SubMenu)
