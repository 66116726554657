import React, { createContext, useContext, useEffect, useMemo } from 'react'
import Banner from 'components/molecules/banner'

import { Container } from './banner.styles'
import { useBanner } from './hooks'
import { DISMISS_ACTIVE_BANNER_EVENT_NAME } from './constants'
import { dispatchDismissActiveBannerEvent } from './utils'
import { TBannerProviderProps, TUseBannerReturn } from './banner-provider.types'

export const BannerContext = createContext<TUseBannerReturn>({
  hasActiveBanner: false,
})
export const useBannerContext = () => useContext(BannerContext)

const BannerProvider = (props: TBannerProviderProps) => {
  const { children } = props

  const {
    activeBanner,
    bannerHeight,
    setBannerRef,
    cta,
    handleDismiss,
    hasActiveBanner,
    targetedBanners,
  } = useBanner(props)

  useEffect(() => {
    // @ts-ignore: using custom event
    window.addEventListener(DISMISS_ACTIVE_BANNER_EVENT_NAME, handleDismiss)

    return () =>
      // @ts-ignore: using custom event
      window.removeEventListener(
        DISMISS_ACTIVE_BANNER_EVENT_NAME,
        handleDismiss
      )
  }, [handleDismiss])

  const value = useMemo(
    () => ({
      bannerHeight,
      hasActiveBanner,
      activeBanner,
      targetedBanners,
      dismissActiveBanner: handleDismiss,
      dispatchDismissActiveBannerEvent,
    }),
    [
      activeBanner,
      bannerHeight,
      handleDismiss,
      hasActiveBanner,
      targetedBanners,
    ]
  )

  return (
    <BannerContext.Provider value={value}>
      {!hasActiveBanner && children}

      {!!activeBanner && hasActiveBanner && (
        <Container
          bannerHeight={bannerHeight}
          displayType={activeBanner?.displayType}
          hasActiveBanner={hasActiveBanner}
        >
          <Banner
            backgroundColor={activeBanner.backgroundColor}
            textColor={activeBanner.textColor}
            cta={cta}
            ctaButtonType={activeBanner.ctaButtonType}
            dismissible={activeBanner.dismissible}
            dismissButtonLabel={activeBanner.dismissButtonLabel}
            displayType={activeBanner?.displayType}
            message={activeBanner.message}
            textAlign={activeBanner.textAlign}
            onDismiss={handleDismiss || (() => {})}
            ref={setBannerRef}
          />
          {children}
        </Container>
      )}
    </BannerContext.Provider>
  )
}

BannerProvider.displayName = 'BannerProvider'

export default BannerProvider
