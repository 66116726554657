const { TYPEFORM_ROOT_DOMAIN, VIDEOASK_ROOT_DOMAIN } = require('./public-envs')

const ADMIN_BASE_URL = `https://admin.${TYPEFORM_ROOT_DOMAIN}`
const LOGOUT_URL = `${ADMIN_BASE_URL}/login/logout`
const API_BASE_URL = `https://api.${TYPEFORM_ROOT_DOMAIN}`
const TEMPLATES_API_URL = `https://api.${TYPEFORM_ROOT_DOMAIN}/templates` // TODO: Remove if not used
const IMAGE_CDN_URL = 'https://images.ctfassets.net'
const WISTIA_URL = 'https://fast.wistia.net'
const WISTIA_EMBED_URL = `${WISTIA_URL}/embed/iframe`
const COMMUNITY_BASE_URL = `https://community.${TYPEFORM_ROOT_DOMAIN}`
const STATUS_BASE_URL = `https://status.${TYPEFORM_ROOT_DOMAIN}`
const PUBLIC_ASSETS_BASE_URL = `https://public-assets.${TYPEFORM_ROOT_DOMAIN}`
const VIDEOASK_BASE_URL = `https://www.${VIDEOASK_ROOT_DOMAIN}`
// We can't use APP_PUBLIC_BASE_URL here because /fonts/ route redirected on cdn
const FONT_BASE_URL = `https://www.${TYPEFORM_ROOT_DOMAIN}/fonts`
const BLOG_PATH = 'blog'
const BLOG_AUTHOR_PATH = 'author'
const HELP_PATH = 'help'
const GUIDE_PATH = 'guide'
const TEMPLATES_PATH = 'templates'
const SPANISH_TEMPLATES_PATH = 'plantillas'
const CONNECT_PATH = 'connect'
const CAREERS_PATH = 'careers'
const CATEGORY_PATH = 'c'
const TEMPLATE_PATH = 't'
const SECTION_PATH = 's'
const ARTICLE_PATH = 'a'
const SIGNUP_PATH = 'signup'
const API_PATH = 'api'
const PRICING_PATH = 'pricing'
const DEVELOPERS_PATH = 'developers'
const SPANISH_PRICING_PATH = 'precios'
const TRY_PATH = 'try'
const EXPLORE_PATH = 'explore'
const CELLO_PATH = '/refer-a-friend/invite/'
const HOMEPAGE_PATH = '/'

const ALL_PRICING_PATHS = [PRICING_PATH, SPANISH_PRICING_PATH]

module.exports = {
  ADMIN_BASE_URL,
  LOGOUT_URL,
  API_BASE_URL,
  TEMPLATES_API_URL,
  WISTIA_URL,
  WISTIA_EMBED_URL,
  COMMUNITY_BASE_URL,
  STATUS_BASE_URL,
  PUBLIC_ASSETS_BASE_URL,
  VIDEOASK_BASE_URL,
  FONT_BASE_URL,
  IMAGE_CDN_URL,
  BLOG_PATH,
  BLOG_AUTHOR_PATH,
  HELP_PATH,
  GUIDE_PATH,
  TEMPLATES_PATH,
  SPANISH_TEMPLATES_PATH,
  CONNECT_PATH,
  CAREERS_PATH,
  CATEGORY_PATH,
  TEMPLATE_PATH,
  SECTION_PATH,
  ARTICLE_PATH,
  SIGNUP_PATH,
  API_PATH,
  PRICING_PATH,
  DEVELOPERS_PATH,
  SPANISH_PRICING_PATH,
  ALL_PRICING_PATHS,
  TRY_PATH,
  EXPLORE_PATH,
  CELLO_PATH,
  HOMEPAGE_PATH,
}
