import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import { ImageWithLazyAttributes } from 'components/atoms/images'
import styled, { css } from 'styled-components'
import OptimizedImage from 'components/optimized-media/optimized-image'

import {
  HEADER_HEIGHT,
  HEADER_MAX_WIDTH,
  SUB_MENU_COLUMN_GAP,
  SUB_MENU_MAX_IMAGE_WIDTH,
  SUB_MENU_MAX_IMAGE_WIDTH_MOBILE,
  SUB_MENU_MIN_WIDTH,
} from '../../mega-menu.constants'

export const SubMenuContent = styled.div`
  ${mediaQueries.md`
    max-width: ${HEADER_MAX_WIDTH}px;
    margin: auto;
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: ${spacing(8)};
    visibility: hidden;
    will-change: visibility, opacity, transform;
    transition: visibility 300ms, opacity 300ms, transform 300ms;
    opacity: 0;
    transform: translateX(-${spacing(3)});
  `}
`
export const subMenuExpandedStyles = css`
  visibility: visible;

  ${SubMenuContent} {
    ${mediaQueries.md`
      will-change: visibility, opacity, transform;
      transition: visibility 300ms 150ms, opacity 300ms 150ms, transform 300ms 150ms;
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
    `}
  }
`
type TSubMenuContainerProps = {
  isActive: boolean
  cookieBannerHeight: number
  maxHeight: number
}
export const SubMenuContainer = styled.div<TSubMenuContainerProps>`
  visibility: hidden;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  max-height: 0;
  will-change: max-height, visibility;
  transition: max-height 200ms ease-in-out, visibility 200ms ease-in-out;

  ${mediaQueries.md`
    display: flex;
    max-height: unset;
    overflow-y: auto;
    position: absolute;
    top: ${HEADER_HEIGHT}px;
    left: 0;
    right: 0;
    max-height: calc(100vh - ${HEADER_HEIGHT}px - ${({
    cookieBannerHeight,
  }: {
    cookieBannerHeight: number
  }) => cookieBannerHeight}px);
    justify-content: flex-start;
    align-items: center;
    padding-top: ${spacing(5)};
    padding-bottom: ${spacing(5)};
  `}

  ${({ isActive, maxHeight }) =>
    isActive &&
    css`
      max-height: ${maxHeight}px;
      ${subMenuExpandedStyles}
    `}
`
type TMenuGroupProps = {
  showColumnSeparator?: boolean
}
export const MenuGroup = styled.div<TMenuGroupProps>`
  ${mediaQueries.md`
    ${({ showColumnSeparator }: TMenuGroupProps) =>
      showColumnSeparator &&
      css`
        margin-left: ${spacing(3)};
        padding-left: ${spacing(7)};
        border-left: solid 1px rgba(0, 0, 0, 0.07);
      `}
  `}
`
type TMenuGroupTitleProps = {
  palette: Palette
  hideOnMobile?: boolean
}
export const MenuGroupTitle = styled.span<TMenuGroupTitleProps>`
  display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  gap: ${spacing(1)};
  align-items: center;
  color: ${({ palette }) =>
    palette === Palette.Negative
      ? NeutralColors.Grey500
      : NeutralColors.Grey600};
  font-size: ${fontSize.xSmall};
  font-weight: ${fontWeight.regular};
  letter-spacing: 0.025em;
  line-height: 1.125;
  margin-bottom: ${spacing(4)};
  text-transform: uppercase;

  ${mediaQueries.md`
    display: flex;
    font-size: ${fontSize.xSmall};
    font-weight: ${fontWeight.regular};
  `}
`

export const MenuGroupTitleIcon = styled(OptimizedImage)`
  margin-bottom: 3px;
`

export const MenuGroupList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  gap: ${spacing(3)};
  flex-direction: column;

  ${mediaQueries.md`
    min-width: ${SUB_MENU_MIN_WIDTH}px;
  `}
`

export const MenuGalleryList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  row-gap: ${spacing(4)};
  flex-direction: column;
  margin-top: ${spacing(3)};
  margin-bottom: ${spacing(4)};

  ${mediaQueries.md`
    margin-top: 0;
    margin-bottom: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: ${spacing(6)};
    column-gap: ${SUB_MENU_COLUMN_GAP}px;
    min-width: ${SUB_MENU_MIN_WIDTH}px;
  `}
`

export const MenuListItem = styled.li`
  margin: 0;
  padding: 0;
`

const commonLinkStyles = css`
  display: flex;
  font-style: normal;
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.small};
  line-height: ${lineHeight.medium};
  color: inherit;
  will-change: transform;
  transition: transform 0.2s ease-in;
  &:hover {
    transform: translateX(${spacing(1)});
  }
`

export const MenuGalleryLink = styled.a`
  ${commonLinkStyles}

  flex-direction: row;
  align-items: center;
  gap: 16px;
  font-size: ${fontSize.medium};
  padding-left: 16px;
  ${mediaQueries.md`
    font-size: ${fontSize.small};
    padding-left: 0;
  `}
`

export const MenuLinkImage = styled(ImageWithLazyAttributes)`
  display: none;
  margin-bottom: ${spacing(3)};
  will-change: transform;
  transition: transform 150ms ease-in-out;
  width: 100%;
  height: auto;
  aspect-ratio: 1.54;

  ${mediaQueries.md`
    display: block;
  `}
`

type TMenuLinkDescriptionProps = {
  palette: Palette
}
export const MenuLinkDescription = styled.span<TMenuLinkDescriptionProps>`
  margin-top: ${spacing(0.5)};
  font-size: ${fontSize.x2Small};
  line-height: ${lineHeight.small};
  color: ${({ palette }) =>
    palette === Palette.Negative
      ? NeutralColors.Grey100
      : NeutralColors.Grey700};
`

export const MenuLink = styled.a<{
  hasImage: boolean
}>`
  ${commonLinkStyles}

  flex-direction: column;
  align-items: flex-start;

  ${({ hasImage }) =>
    hasImage &&
    css`
      font-size: ${fontSize.xSmall};
      max-width: ${SUB_MENU_MAX_IMAGE_WIDTH_MOBILE}px;

      ${mediaQueries.md`
      max-width: ${SUB_MENU_MAX_IMAGE_WIDTH}px;
    `}

      &:hover {
        transform: none;
        ${MenuLinkImage} {
          transform: scale(1.05);
        }
      }
    `}
`

export const MenuLinkIcon = styled(ImageWithLazyAttributes)`
  width: 54px;
  height: 54px;
`

export const MenuLinkIconContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`

export const MenuLinkBody = styled.span`
  display: flex;
  flex-direction: column;
`

export const MenuWithColumnsContainer = styled.div`
  display: flex;
  row-gap: ${spacing(6)};
  flex-direction: column;
  margin-top: ${spacing(3)};
  margin-bottom: ${spacing(6)};

  ${mediaQueries.md`
    flex-direction: row;
    margin-bottom: 0;
    margin-top: 0;
    row-gap: 0;
    align-items: stretch;
    column-gap: ${SUB_MENU_COLUMN_GAP}px;

    &:has(${MenuLinkImage}) {
      column-gap: ${spacing(4)};
    }
  `}
`

export const RevampedMenuLinkBodyLabel = styled.span`
  text-decoration: underline;
  font-weight: ${fontWeight.bold};
`
