import { memo, useCallback } from 'react'
import { TTopMenuItem } from 'models/top-menu-item-model.types'

import { useMenuContext } from '../../mega-menu-provider'
import MobileMenuTrigger from '../mobile-menu-trigger/mobile-menu-trigger'
import { TMenuItemClickWithEventObject } from '../../mega-menu.types'

import { TopMenuList } from './top-menu.styles'
import TopMenuItem from './top-menu-item'

type TTopMenuProps = {
  menuItems: TTopMenuItem[]
  mobileMenuTriggerOpenLabel: string
  mobileMenuTriggerCloseLabel: string
  bannerHeight?: number
}

const TopMenu = ({
  menuItems,
  mobileMenuTriggerOpenLabel,
  mobileMenuTriggerCloseLabel,
  bannerHeight,
}: TTopMenuProps) => {
  const {
    palette,
    isMobileMenuOpen,
    isUserDropdownVisible,
    cookieBannerHeight,
    activeSubMenuItem,
    setIsMobileMenuOpen,
    setActiveSubMenuItem,
    onSubMenuItemClick,
    subMenuElementsRef,
  } = useMenuContext()

  const handleMobileMenuOpen = useCallback(() => {
    setIsMobileMenuOpen(true)
  }, [setIsMobileMenuOpen])

  const handleMobileMenuClose = useCallback(() => {
    setActiveSubMenuItem(null)
    setIsMobileMenuOpen(false)
  }, [setIsMobileMenuOpen, setActiveSubMenuItem])

  const handleSubMenuOpen = useCallback(
    ({ item }: TMenuItemClickWithEventObject) => {
      setActiveSubMenuItem(item)
    },
    [setActiveSubMenuItem]
  )

  const handleSubMenuClose = useCallback(() => {
    setActiveSubMenuItem(null)
  }, [setActiveSubMenuItem])

  return (
    <>
      <MobileMenuTrigger
        openLabel={mobileMenuTriggerOpenLabel}
        closeLabel={mobileMenuTriggerCloseLabel}
        isMobileMenuOpen={isMobileMenuOpen}
        onClick={
          isMobileMenuOpen ? handleMobileMenuClose : handleMobileMenuOpen
        }
      />
      <TopMenuList
        isMobileMenuOpen={isMobileMenuOpen}
        isUserDropdownVisible={isUserDropdownVisible}
        cookieBannerHeight={cookieBannerHeight}
        bannerHeight={bannerHeight}
      >
        {menuItems.map(menuItem => (
          <TopMenuItem
            key={menuItem.id}
            palette={palette}
            subMenuElementRef={element => {
              if (!menuItem.id || !subMenuElementsRef?.current) {
                return
              }

              subMenuElementsRef.current[menuItem.id] = element
            }}
            onMenuOpen={handleSubMenuOpen}
            onMenuClose={handleSubMenuClose}
            onSubMenuItemClick={onSubMenuItemClick}
            isActive={activeSubMenuItem?.id === menuItem.id}
            item={menuItem}
          />
        ))}
      </TopMenuList>
    </>
  )
}

export default memo(TopMenu)
