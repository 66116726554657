import { GRID_UNIT } from '@typeform/ginger/dist'

export const ARTICLE_SECTION_VERTICAL_PADDING = 2 * GRID_UNIT
export const ARTICLE_SECTION_HORIZONTAL_PADDING = 3 * GRID_UNIT
export const ARTICLE_COLUMN_WIDTH = 640
export const ARTICLE_SECTION_WIDTH =
  ARTICLE_COLUMN_WIDTH + 2 * ARTICLE_SECTION_HORIZONTAL_PADDING
export const SECTION_DIVIDER_WIDTH = ARTICLE_SECTION_WIDTH + 6 * GRID_UNIT

export const APPEARANCE = {
  LIGHT: 'light',
  DARK: 'dark',
}
