import { NAMESPACES } from 'constants/locales'

import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from 'next-translate/useTranslation'

import {
  RadioToggleTitle,
  RadioToggleOption,
  RadioToggleLabel,
  RadioToggleLink,
  SmallText,
  StyledRadio,
} from './radio-toggle-group.styles'

const radioButtons = [
  {
    translationKey: 'auth.consents.yes',
    gap: 1,
    onClickValue: true,
    isChecked: accepted => !!accepted,
  },
  {
    translationKey: 'auth.consents.no',
    gap: 0,
    onClickValue: false,
    isChecked: accepted => (accepted === null ? false : !accepted),
  },
]

const RadioToggleGroup = ({ radioId, accepted, onChange, label, link }) => {
  const { t } = useTranslation(NAMESPACES.AUTH)
  return (
    <div className='radio-toggle-group'>
      <RadioToggleTitle>
        <RadioToggleLabel id={radioId}>{t(label)}</RadioToggleLabel>
        {link && (
          <RadioToggleLink
            id={radioId}
            href={link.url}
            target='_blank'
            rel='noopener'
          >
            {t(link.label)}
          </RadioToggleLink>
        )}
      </RadioToggleTitle>
      <RadioToggleOption>
        {radioButtons.map(
          ({ isChecked, translationKey, gap, onClickValue }) => (
            <StyledRadio
              readOnly
              key={`${radioId}-${t(translationKey)}`}
              checked={isChecked(accepted)}
              onClick={() => onChange(radioId, onClickValue)}
              aria-labelledby={`${radioId}-${t(translationKey)}`}
              data-testid={radioId}
            >
              <SmallText gap={gap}>{t(translationKey)}</SmallText>
            </StyledRadio>
          )
        )}
      </RadioToggleOption>
    </div>
  )
}

RadioToggleGroup.propTypes = {
  radioId: PropTypes.string.isRequired,
  accepted: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.shape({
    url: PropTypes.string,
    label: PropTypes.string,
  }),
}
RadioToggleGroup.defaultProps = {
  accepted: null,
  link: null,
}
export default RadioToggleGroup
