import React, { useContext } from 'react'

export const EnvContext = React.createContext({
  COOKIE_DOMAIN: '',
  GOOGLE_SOCIAL_IDP_ID: '',
  GTM_ID: '',
  MICROSOFT_SOCIAL_IDP_ID: '',
  LINKEDIN_SOCIAL_IDP_ID: '',
  OKTA_CLIENT_ID: '',
  OKTA_DOMAIN: '',
  OPTIMIZELY_FS_SDK_KEY: '',
  SEGMENT_TRACKING_KEY: '',
  RECAPTCHA_KEY: '',
})

EnvContext.displayName = 'EnvContext'

export const useEnvContext = () => useContext(EnvContext)
