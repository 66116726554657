import { TYPEFORM_ROOT_DOMAIN } from 'constants/public-envs'

import { OKTA_WIDGET_ID } from '../okta-widget/constants'

const CLASS_NAMES = {
  SUBSCHEMA_SATISFIED: 'subschema-satisfied',
  SUBSCHEMA_UNSATISFIED: 'subschema-unsatisfied',
  SUBSCHEMA_PASSWORD_CONTAINS_USERNAME: 'subschema-3',
  SUBSCHEMA_VISIBLE: 'visible',
  ERROR_BOX: 'okta-form-infobox-error',
}

// This is a mapping of Okta error messages -> translation keys for the message we would prefer to show.
// The error messages we define can contain html so links/styling can be used.
const ERROR_OVERRIDES = {
  'Registration denied': 'auth.signup.submit.registration-denied-html',
}

export const getPasswordEle = () => {
  return document.querySelector(`#${OKTA_WIDGET_ID} input[name="password"]`)
}

export const isPasswordValid = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      const elems = [
        ...document.querySelectorAll(
          `#subschemas-password > div:not(.${CLASS_NAMES.SUBSCHEMA_PASSWORD_CONTAINS_USERNAME})`
        ),
      ]

      resolve(
        elems.every(ele =>
          ele.classList.contains(CLASS_NAMES.SUBSCHEMA_SATISFIED)
        )
      )
    }, 200)
  })
}

export const doesPasswordContainUsername = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      const schemaEle = document.querySelector(
        `.${CLASS_NAMES.SUBSCHEMA_PASSWORD_CONTAINS_USERNAME}`
      )

      resolve(schemaEle.classList.contains(CLASS_NAMES.SUBSCHEMA_UNSATISFIED))
    }, 200)
  })
}

export const getSubmitEle = () => {
  return document.querySelector(`#${OKTA_WIDGET_ID} input[type="submit"]`)
}

export const overrideErrorMessages = t => {
  const ele = document.querySelector(`.${CLASS_NAMES.ERROR_BOX}`)
  const textOverrideTranslationKey = ERROR_OVERRIDES[ele?.innerText]
  if (textOverrideTranslationKey) {
    ele.innerHTML = t(textOverrideTranslationKey, { TYPEFORM_ROOT_DOMAIN })
  }
}

export const toggleErrorBox = (toShow = true) => {
  const ele = document.querySelector(`.${CLASS_NAMES.ERROR_BOX}`)

  if (ele) {
    toShow
      ? ele.classList.add(CLASS_NAMES.SUBSCHEMA_VISIBLE)
      : ele.classList.remove(CLASS_NAMES.SUBSCHEMA_VISIBLE)
  }
}
