import throttle from 'lodash.throttle'
import { useEffect, useRef } from 'react'

const THROTTLE_INTERVAL = 500
const DEFAULT_SCROLL_THRESHOLDS = [0.25, 0.5, 0.75]

const usePageScrollThreshold = (
  callbackFn: (threshold: number) => void,
  thresholds: number[] = DEFAULT_SCROLL_THRESHOLDS
) => {
  if (!callbackFn || typeof callbackFn !== 'function') {
    throw new Error(
      'A callback function that accepts threshold value as a string should be defined for usePageScrollThreshold as first prameter'
    )
  }

  if (!thresholds || !Array.isArray(thresholds) || thresholds.length === 0) {
    throw new Error(
      'Array of thresholds should be defined as second parameter for usePageScrollThreshold'
    )
  }

  const isThresholdsInValid = thresholds.some(
    threshold => isNaN(threshold) || threshold < 0 || threshold > 1
  )

  if (isThresholdsInValid) {
    throw new Error(
      'Thresholds should be a number between 0 and 1 representing the page scroll percentage'
    )
  }

  const remainingThresholds = useRef([...thresholds])

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    const handleScroll = throttle(() => {
      const documentHeight = document.body.offsetHeight - window.innerHeight
      const scrollPercentage = window.scrollY / documentHeight

      const thresholdsToKeep: number[] = []

      remainingThresholds.current.forEach(threshold => {
        if (scrollPercentage >= threshold) {
          callbackFn(threshold)
        } else {
          thresholdsToKeep.push(threshold)
        }
      })

      remainingThresholds.current = thresholdsToKeep

      if (!remainingThresholds.current.length) {
        window.removeEventListener('scroll', handleScroll)
      }
    }, THROTTLE_INTERVAL)

    // runs handle scroll on load to handle initial scroll position
    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => {
      if (typeof window === 'undefined') {
        return
      }

      window.removeEventListener('scroll', handleScroll)
    }
  }, [callbackFn])
}

export default usePageScrollThreshold
