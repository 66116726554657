import { Palette } from 'constants/palettes'
import mediaQueries from 'util/mediaqueries'
import { spacing } from 'util/spacing'
import { NeutralColors } from 'constants/colors'

import styled, { createGlobalStyle } from 'styled-components'
import { NO_JS_CLASSNAME } from 'components/animation'

import {
  HEADER_HEIGHT,
  HEADER_MAX_WIDTH,
  MEGA_MENU_Z_INDEX,
  MOBILE_HEADER_HEIGHT,
} from './constants'
import {
  TopMenuItemContainer,
  TopMenuList,
  mobileMenuOpenStyles,
} from './components/top-menu/top-menu.styles'
import {
  HEADER_BACKGROUND_OFFSET,
  MenuBackgroundContainer,
  menuBackgroundCommonStyles,
  menuBackgroundExpandedStyles,
} from './components/menu-background/menu-background.styles'
import {
  SubMenuContainer,
  subMenuExpandedStyles,
} from './components/sub-menu/sub-menu.styles'
import {
  NoJsMenuTrigger,
  TriggerButton,
  triggerSvgVisibleStyles,
} from './components/mobile-menu-trigger/mobile-menu-trigger.styles'

type TNavigationContainerProps = {
  fixed?: boolean
  palette: Palette
}

export const NavigationContainer = styled.div<TNavigationContainerProps>`
  position: ${({ fixed }) => (fixed ? `fixed` : `relative`)};
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${MEGA_MENU_Z_INDEX};
  color: ${({ palette }: { palette: Palette }) =>
    palette === Palette.Negative ? NeutralColors.White : NeutralColors.Ink};
`

export const TopMenuContainer = styled.div`
  max-width: ${HEADER_MAX_WIDTH}px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${MOBILE_HEADER_HEIGHT}px;
  padding-left: ${spacing(2)};
  padding-right: ${spacing(2)};

  ${mediaQueries.sm`
    padding-left: ${spacing(3)};
    padding-right: ${spacing(3)};
  `}

  ${mediaQueries.md`
    min-height: ${HEADER_HEIGHT}px;
  `}
`

export const MegaMenuNoJsStyles = createGlobalStyle`
  .${NO_JS_CLASSNAME} {
    ${MenuBackgroundContainer} {
      height: ${MOBILE_HEADER_HEIGHT + HEADER_BACKGROUND_OFFSET}px;
      ${mediaQueries.md`
        height: ${HEADER_HEIGHT + HEADER_BACKGROUND_OFFSET}px;
      `}
    }
    ${TriggerButton} {
      pointer-events: none;
    }
    ${NoJsMenuTrigger} {
      display: block;
    }

    &:has(${NoJsMenuTrigger}:checked) {
      ${TriggerButton} {
        #close-menu-svg {
          ${triggerSvgVisibleStyles}
        }
        #open-menu-svg {
          visibility: hidden;
          opacity: 0;
        }
      }

      ${TopMenuList} {
        ${mobileMenuOpenStyles}

        bottom: 0;
      }

      ${MenuBackgroundContainer} {
        height: 100vh;

        ${menuBackgroundExpandedStyles}
      }

      ${SubMenuContainer} {
        ${subMenuExpandedStyles}

        max-height: unset;
      }
    }

    ${mediaQueries.md`
      ${SubMenuContainer} {
        &:before {
          content: '';
            bottom: 0;
          ${menuBackgroundCommonStyles}
        }
      }

      ${TopMenuItemContainer}:hover, ${TopMenuItemContainer}:has(*:focus) {
        ${SubMenuContainer} {
          ${subMenuExpandedStyles}
          &:before {
            ${menuBackgroundExpandedStyles}
          }
        }
      }
    `}
  }
`
