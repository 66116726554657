import styled from 'styled-components'
import BaseButton from 'components/atoms/button'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

export const Button = styled(BaseButton)<{ iconUrl?: string }>`
  background-image: url(${({ iconUrl }) => iconUrl});
  background-repeat: no-repeat;
  background-position: 10px 50%, 0 0;
  border-color: ${NeutralColors.Grey400};
  color: ${NeutralColors.Grey700};
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`
