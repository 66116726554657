import pathParse from 'path-parse'

export const getImageVariantPath = ({
  src,
  size,
  overrideExtension,
}: {
  src: string
  size: string
  overrideExtension?: string
}) => {
  if (typeof src !== 'string' || !size) {
    return null
  }

  const parts = pathParse(src)

  const { dir, ext, name } = parts

  if (!ext || !name) {
    return null
  }

  return `${dir}/${name}@${size}${overrideExtension || ext}`
}
