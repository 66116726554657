export const sendEventToGTMDataLayer = (name, trackingProps) => {
  if (!name || typeof name !== 'string') {
    throw new Error(
      'Event name should be provided for sending an event to GTM DataLayer.'
    )
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: name,
    ...trackingProps,
  })
}
