import { css } from 'styled-components'
import React from 'react'
// @ts-expect-error Not yet migrated to TS
import { GlobalStyle as GingerGlobalStyle } from '@typeform/ginger/dist/util/global'

const customGlobalStyle = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 20px;
  }

  p {
    margin: 0 0 20px;
  }

  body {
    overflow-x: hidden;
  }

  video {
    /*
      Fixes flickering black border issue on video elements in safari/webkit browsers
      https://stackoverflow.com/questions/52508598/html5-video-element-on-iphone-has-border
    */
    /* stylelint-disable-next-line property-no-vendor-prefix, value-no-vendor-prefix */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }

  /* Typeform Popup Embed */
  .tf-v1-popup .tf-v1-iframe-wrapper {
    width: 70% !important;
    max-width: 1333px;
  }

  /* Hide the Storybook loader so it's never seen in visual regression tests */
  .sb-loader {
    display: none !important;
  }
`

const GlobalStyles = (props: { applyTextWrapBalance?: boolean }) => (
  <GingerGlobalStyle customGlobalStyle={customGlobalStyle} {...props} />
)
GlobalStyles.displayName = 'GlobalStyles'
export default GlobalStyles
