import { Palette } from 'constants/palettes'
import { BOX_SHADOWS } from 'constants/shadows'
import { applyOpacityToHexColor, darkenHex } from 'util/color'
import mediaQueries from 'util/mediaqueries'
import { NeutralColors } from 'constants/colors'

import styled, { css } from 'styled-components'

import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../../constants'

// To ensure the `backdrop-filter` effect works in Safari, the content wrapper cannot have a height of 0.
// Therefore we give it a minimum height of 1px.
export const HEADER_BACKGROUND_OFFSET = 1

type TMenuBackgroundContainerProps = {
  palette: Palette
  fixed?: boolean
  transparentBackground: boolean
  contentHeight?: string
  isMenuScrolled?: boolean
  isExpanded?: boolean
}

const getTop = ({ fixed }: TMenuBackgroundContainerProps) => {
  if (!fixed) {
    return 0
  }

  return `-${HEADER_BACKGROUND_OFFSET}px`
}

const getPaddingTop =
  (forMobile = true) =>
  ({ transparentBackground }: TMenuBackgroundContainerProps) => {
    const headerHeight = forMobile ? MOBILE_HEADER_HEIGHT : HEADER_HEIGHT
    return transparentBackground
      ? `${HEADER_BACKGROUND_OFFSET}px`
      : `${HEADER_BACKGROUND_OFFSET + headerHeight}px`
  }

export const getBackgroundColor = ({
  palette,
}: TMenuBackgroundContainerProps) => {
  const color =
    palette === Palette.Negative
      ? darkenHex(35, NeutralColors.Ink)
      : NeutralColors.White

  const opacityRatio = palette === Palette.Negative ? 86 : 90

  return applyOpacityToHexColor(opacityRatio, color)
}

const getHeight =
  (forMobile = true) =>
  ({
    contentHeight,
    isMenuScrolled,
    transparentBackground,
    fixed,
  }: TMenuBackgroundContainerProps) => {
    if (contentHeight) {
      return contentHeight
    }

    if (forMobile) {
      return `${MOBILE_HEADER_HEIGHT}px`
    }

    return !isMenuScrolled && transparentBackground && fixed
      ? 0
      : `${HEADER_HEIGHT}px`
  }

export const menuBackgroundCommonStyles = css<TMenuBackgroundContainerProps>`
  position: absolute;
  z-index: -1;
  top: ${getTop};
  padding-top: ${getPaddingTop(true)};
  left: 0;
  right: 0;
  background-color: ${getBackgroundColor};
  backdrop-filter: saturate(180%) blur(20px);

  ${mediaQueries.md`
    padding-top: ${getPaddingTop(false)}
  `}
`
export const menuBackgroundExpandedStyles = css`
  transition-delay: 100ms;
  box-shadow: ${BOX_SHADOWS.small};
`
export const MenuBackgroundContainer = styled.div<TMenuBackgroundContainerProps>`
  ${menuBackgroundCommonStyles}

  height: ${getHeight(true)};
  transition: height 600ms cubic-bezier(0.165, 0.84, 0.44, 1),
    box-shadow 600ms cubic-bezier(0.165, 0.84, 0.44, 1);

  ${mediaQueries.md`
    height: ${getHeight(false)};

    ${({ isExpanded }: TMenuBackgroundContainerProps) =>
      isExpanded && menuBackgroundExpandedStyles}
  `}
`
