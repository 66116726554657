/* eslint-disable complexity */
import { get } from '@s-libs/micro-dash'

const {
  LANGUAGE_CODES,
  LOCALES,
  DEFAULT_LOCALE,
} = require('@typeform/ginger/dist/lib/i18n')

export const DEFAULT_PAGE_LOCALES = {
  [LANGUAGE_CODES.spanish]: '/es/',
  [LANGUAGE_CODES.english]: '/',
  [LANGUAGE_CODES.french]: '/fr/',
  [LANGUAGE_CODES.german]: '/de/',
}

// Since we're fetching all locales at once, we need to flatten out the response to only
// include elements keyed by the desired locale
export const getLocalizedField = (field, locale) => {
  // Accepts both en-US and en
  const localizationPattern = /^[a-z]{2}(-[A-Z]{2})?$/

  // If there are no nested fields, we can exit
  if (!field.fields) {
    // If each key of the current field is a locale, we need to localize the nested fields
    if (
      Object.keys(field).every(singleLocale =>
        singleLocale.match(localizationPattern)
      )
    ) {
      const subField = field[locale] || field[DEFAULT_LOCALE]

      if (!subField) {
        return null
      }

      if (Array.isArray(subField)) {
        return subField.map(fieldItem => getLocalizedField(fieldItem, locale))
      }

      // Rich-text requires a bit of extra digging
      if (subField.nodeType === 'document') {
        return {
          ...subField,
          content: subField.content.map(contentNode => {
            const newNode = { ...contentNode }
            const nestedData = get(newNode, 'data.target'.split('.'))

            if (nestedData) {
              newNode.data = {
                ...newNode.data,
                target: {
                  ...getLocalizedField(nestedData),
                },
              }
            }

            return newNode
          }),
        }
      }

      // Recursively check subfields for other locales and return the
      // properly localized subfields
      if (subField.fields) {
        return getLocalizedField(subField, locale)
      }

      return subField
    }

    return field
  }

  return {
    ...field,
    fields: Object.keys(field.fields).reduce((accum, curr) => {
      accum[curr] = getLocalizedField(field.fields[curr], locale)

      return accum
    }, {}),
  }
}

export const getPageLocales = pageLocales => [
  {
    href: pageLocales[LANGUAGE_CODES.english],
    title: LANGUAGE_CODES.english,
    locale: LOCALES.en_US,
  },
  {
    href: pageLocales[LANGUAGE_CODES.spanish],
    title: LANGUAGE_CODES.spanish,
    locale: LOCALES.es_ES,
  },
  {
    href: pageLocales[LANGUAGE_CODES.french],
    title: LANGUAGE_CODES.french,
    locale: LOCALES.fr_FR,
  },
  {
    href: pageLocales[LANGUAGE_CODES.german],
    title: LANGUAGE_CODES.german,
    locale: LOCALES.de_DE,
  },
]
