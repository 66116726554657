import { USE_CONTENTFUL_PREVIEW_API } from 'constants/public-envs'

import dynamic from 'next/dynamic'
import { ReactNode } from 'react'
import { useAppContext } from 'components/app-context'

const ContentfulLivePreviewProvider = dynamic(
  () => import('./contentful-live-preview-provider')
)

export type TContentfulLivePreviewWrapper = {
  locale: string
  children: ReactNode
}

const ContentfulLivePreviewWrapper = ({
  children,
  locale,
}: TContentfulLivePreviewWrapper) => {
  const { isEphemeral } = useAppContext()

  if (!isEphemeral) {
    return <>{children}</>
  }

  return (
    <ContentfulLivePreviewProvider
      locale={locale}
      enableInspectorMode={USE_CONTENTFUL_PREVIEW_API}
      enableLiveUpdates={USE_CONTENTFUL_PREVIEW_API}
    >
      {children}
    </ContentfulLivePreviewProvider>
  )
}

export default ContentfulLivePreviewWrapper
