import { planNamesByPlanCode } from 'constants/pricing/general'

export const getPricingVersionFromPlans = plans => {
  if (!plans?.length) {
    return null
  }

  return plans[0].pricing_version
}

/**
 * @param {string} planCode - For pricing 4.0, should be in the format `v4.0_tier1_starter`
 */
export const getPlanNameFromPlanCode = planCode => {
  return planNamesByPlanCode[planCode] || planCode
}
