import throttle from 'lodash.throttle'
import { useEffect, useState } from 'react'

import {
  MENU_SCROLL_THROTTLE_INTERVAL,
  HEADER_HEIGHT,
} from '../mega-menu.constants'

import { useIsMobileMenu } from './use-is-mobile-menu'

export const useIsMenuScrolled = (): boolean => {
  const isMobileMenu = useIsMobileMenu()
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = throttle(() => {
      const { scrollY } = window
      const visibleThreshold = isMobileMenu
        ? window.innerHeight / 2
        : HEADER_HEIGHT / 2

      setIsScrolled(scrollY >= visibleThreshold)
    }, MENU_SCROLL_THROTTLE_INTERVAL)

    // When the component is mounted, we should check to see if the page is already scrolled.
    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isMobileMenu])

  return isScrolled
}
