const IS_EMBED_ADMIN = 'isEmbedAdmin'

export const getEmbedAdminAppName = (url: string): string | null => {
  if (!url) {
    return null
  }
  const currentSearchParams = new URLSearchParams(url?.replace(/^.*\?/, ''))

  const embedAdminAppName = currentSearchParams.get(IS_EMBED_ADMIN)
  return embedAdminAppName
}

export const isEmbedAdmin = (url: string) => {
  return getEmbedAdminAppName(url) !== null
}
