import React, { memo } from 'react'
import { gtag } from '@typeform/js-tracking/lib/consents/consentUtil'

export const HeadTracking = memo(({ GTM_ID }: { GTM_ID: string }) => {
  if (!GTM_ID) {
    // eslint-disable-next-line no-console
    console.error('GTM_ID is missing in HeadTracking')
    return null
  }
  const googleTagManagerScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM_ID}');`
  return (
    <>
      <script
        data-testid='gtag'
        dangerouslySetInnerHTML={{ __html: gtag() }}
        key='gtag'
      />
      <script
        data-testid='gtm'
        dangerouslySetInnerHTML={{ __html: googleTagManagerScript }}
        key='gtm'
      />
    </>
  )
})

HeadTracking.displayName = 'HeadTracking'
