import { MouseEvent, useCallback } from 'react'
import { TTopMenuItemSeeMoreLink } from 'models/top-menu-item-see-more-link.types'

import { TSubMenuItemClickEventHandler } from '../../mega-menu.types'

import { StyledSeeMoreLink } from './see-more-link.styles'

type TSeeMoreLinkProps = TTopMenuItemSeeMoreLink & {
  onClick?: TSubMenuItemClickEventHandler
}

const SeeMoreLink = ({ onClick, ...seeMoreLink }: TSeeMoreLinkProps) => {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      onClick?.({ item: seeMoreLink, event })
    },
    [onClick, seeMoreLink]
  )

  return (
    <StyledSeeMoreLink
      href={seeMoreLink.link || ''}
      target={seeMoreLink.linkTarget || '_self'}
      onClick={handleClick}
    >
      {seeMoreLink.label}
      <svg
        fill='none'
        height='9'
        viewBox='0 0 10 9'
        width='10'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.12482 5.01677L5.04164 8.26826L5.73528 9L10 4.5L5.73528 0L5.04164 0.731741L8.12482 3.98323H0V5.01677H8.12482Z'
          fill='currentColor'
        />
      </svg>
    </StyledSeeMoreLink>
  )
}

export default SeeMoreLink
