import React, { memo, useMemo, useEffect, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { LANGUAGE_CODES } from '@typeform/ginger/dist/lib/i18n'
import { useMainContext } from 'components/molecules/main-context'

import { TCookieBanner, TAllTranslations } from './cookie-banner.types'
import { cookieCss } from './styles'
import {
  SHOWN_COOKIE_AREA_CLASSNAME,
  SHOWN_COOKIE_AREA_SELECTOR,
  ONETRUST_SDK_SELECTOR,
  ONETRUST_BANNER_CLOSED_COOKIE_NAME,
  COOKIE_ACCEPT_INTENT_KEY,
  ONE_TRUST_COOKIES_REJECTED_EVENT,
  ONE_TRUST_MODAL_REJECT_ALL_BUTTON_SELECTOR,
} from './constants'
import lastOnetrustUpdate from './last-onetrust-update'
import { useCookieBannerHeightSetter } from './cookie-safe-area-provider'

const allTranslations: TAllTranslations = {
  en: ({ TYPEFORM_ROOT_DOMAIN }) => ({
    title: 'Cookie consent',
    text: "We use our own and third-party cookies to show you more relevant content based on your browsing and navigation history. Please accept or manage your cookie settings below. Here's our",
    linkHref: `https://admin.${TYPEFORM_ROOT_DOMAIN}/to/dwk6gt/`,
    linkTitle: 'cookie policy',
    settingsLabel: 'Cookie settings',
    acceptLabel: 'Accept all cookies',
    acceptedLabel: 'Accepted',
  }),
  es: ({ TYPEFORM_ROOT_DOMAIN }) => ({
    title: 'Cookies',
    text: 'Usamos cookies propias y de terceros para mostrarte contenidos más relevantes con base en tu historial de navegación. Puedes acceptar o gestionar la configuración sobre cookies más abajo. También puedes consultar nuestra',
    linkHref: `https://admin.${TYPEFORM_ROOT_DOMAIN}/to/yFvrNI/`,
    linkTitle: 'política de cookies',
    settingsLabel: 'Configuración de cookies',
    acceptLabel: 'Aceptar todas las cookies',
    acceptedLabel: 'Aceptadas',
  }),
  fr: ({ TYPEFORM_ROOT_DOMAIN }) => ({
    title: 'Cookies',
    text: 'Nous utilisons nos propres cookies et ceux de tiers pour vous montrer des contenus plus pertinents en fonction de votre historique de navigation. Vous pouvez accepter ou gérer les paramètres relatifs aux cookies ci-dessous. Vous pouvez également consulter notre',
    linkHref: `https://admin.${TYPEFORM_ROOT_DOMAIN}/to/yFvrNI/`,
    linkTitle: 'politique de cookies',
    settingsLabel: 'Paramètres des cookies',
    acceptLabel: 'Accepter tous les cookies',
    acceptedLabel: 'Accepté',
  }),
  de: ({ TYPEFORM_ROOT_DOMAIN }) => ({
    title: 'Cookies',
    text: 'Wir verwenden eigene und Drittanbieter-Cookies, um Ihnen relevantere Inhalte basierend auf Ihrem Browserverlauf und Navigationsverhalten anzuzeigen. Bitte akzeptieren Sie oder verwalten Sie Ihre Cookie-Einstellungen unten. Hier ist unser',
    linkHref: `https://admin.${TYPEFORM_ROOT_DOMAIN}/to/yFvrNI/`,
    linkTitle: 'Cookie-Richtlinie',
    settingsLabel: 'Cookie-Einstellungen.',
    acceptLabel: 'Akzeptiere alle Cookies',
    acceptedLabel: 'Akzeptiert.',
  }),
}

export const consentScriptContent = `
var shouldShowBanner = false;
if(document.cookie.indexOf('${ONETRUST_BANNER_CLOSED_COOKIE_NAME}') === -1){
  shouldShowBanner = true;
} else {
  var cookieString = document.cookie;
  var i = document.cookie.indexOf('${ONETRUST_BANNER_CLOSED_COOKIE_NAME}') + ('${ONETRUST_BANNER_CLOSED_COOKIE_NAME}=').length;
  var dateString = '';

  while(cookieString[i] && cookieString[i] !== ';') {
    dateString += cookieString[i];
    i++;
  }

  // check if reconsent needed because of onetrust list update
  if(new Date('${lastOnetrustUpdate}') > new Date(dateString)) {
    shouldShowBanner = true;
  }
}

// user accepted cookie but left the page before onetrust was called
try {
  if(localStorage.getItem('${COOKIE_ACCEPT_INTENT_KEY}')) {
    shouldShowBanner = false;
  }
} catch (err) {
  console.warn('Localstorage was not accessible');
}


if(shouldShowBanner) {
  document.documentElement.classList.add('${SHOWN_COOKIE_AREA_CLASSNAME}');
}
`

export const ConsentScript = () => {
  return (
    <script
      dangerouslySetInnerHTML={{
        __html: consentScriptContent,
      }}
    />
  )
}

export const createCookieBannerScript = (locale: string) => `
  var settingsClicked = false;
  var isFirstCallbackCall = true;
  var oneTrustCookiesRejectedEvent = new Event('${ONE_TRUST_COOKIES_REJECTED_EVENT}');

  var callSdk = function(callback) {
    if (!document.querySelector('${ONETRUST_SDK_SELECTOR}')) {
      setTimeout(function() {
        callSdk(callback)
      }, 100)
    } else {
      callback()
    }
  }

  function hideBanner() {
    var shownBanner = document.querySelector('${SHOWN_COOKIE_AREA_SELECTOR}');

    if(shownBanner) {
      shownBanner.classList.remove('${SHOWN_COOKIE_AREA_CLASSNAME}')
    }
  }

  function onRejectAllCookies() {
    window.dispatchEvent(oneTrustCookiesRejectedEvent)
  }

  function onShowSettings() {
    settingsClicked = true;

    callSdk(function sdkSettingsCallback() {
      window.OneTrust.ToggleInfoDisplay()

      var rejectAllButton = document.querySelector('${ONE_TRUST_MODAL_REJECT_ALL_BUTTON_SELECTOR}');

      if (rejectAllButton) {
        rejectAllButton.addEventListener('click', onRejectAllCookies)
      }

      settingsClicked = false;
    })
  }

  function onAllowAllCookies() {
    try {
      localStorage.setItem('${COOKIE_ACCEPT_INTENT_KEY}', true)
    } catch(err) {
      console.warn('Localstorage is not accessible');
    }

    hideBanner();

    callSdk(function sdkAllowCallback() {
      window.OneTrust.AllowAll();
      window.OneTrust.TriggerGoogleAnalyticsEvent('Inhouse Cookie Consent', 'Banner Accept Cookies');

      try {
        localStorage.removeItem('${COOKIE_ACCEPT_INTENT_KEY}')
      } catch(err) {
        console.warn('Localstorage is not accessible');
      }
    });
  }

  window.OptanonWrapper = function OptanonWrapper() {
    if (isFirstCallbackCall) {
      isFirstCallbackCall = false;
      return;
    }

    if (!settingsClicked) {
      hideBanner();
    }
  }

  function attachCookieConsentListeners() {
    document.querySelector('.cookie-settings-link').addEventListener('click', onShowSettings);
    document.querySelector('.cookie-accept-btn').addEventListener('click', onAllowAllCookies);
  }

  try {
    if(localStorage.getItem('${COOKIE_ACCEPT_INTENT_KEY}')) {
      onAllowAllCookies();
    }
  } catch(err) {
    console.warn('Localstorage is not accessible');
  }

  callSdk(function setLanguage() {
    window?.OneTrust?.changeLanguage?.('${locale}')
  })
  attachCookieConsentListeners();
`

const CookieBanner = ({
  locale = LANGUAGE_CODES.english,
  onCookiesRejected = undefined,
}: TCookieBanner) => {
  const { sharedVariables } = useMainContext()
  const translations =
    allTranslations[locale]?.(sharedVariables) ||
    allTranslations[LANGUAGE_CODES.english](sharedVariables)
  const bannerBoundariesRef = useRef<HTMLDivElement>(null)
  const setCookieBannerHeight = useCookieBannerHeightSetter()
  const [cookiesAccepted, setCookiesAccepted] = useState(false)

  useEffect(() => {
    if (typeof window === 'undefined' || !setCookieBannerHeight) {
      return
    }
    // @ts-expect-error we are using a custom function that is not defined in the window object
    window.attachCookieConsentListeners?.()

    const bannerBoundariesElement = bannerBoundariesRef.current

    if (!bannerBoundariesElement) {
      return
    }

    let lastBannerSize: number | undefined = 0

    const resizeObserver = new ResizeObserver(entries => {
      const isEntriesIterable = Symbol.iterator in Object(entries)

      if (!isEntriesIterable) {
        return
      }

      let newBannerSize
      for (const entry of entries) {
        newBannerSize =
          entry.borderBoxSize?.length > 0
            ? entry.borderBoxSize[0].blockSize
            : entry.contentRect.height
      }

      if (newBannerSize === lastBannerSize) {
        return
      }

      setCookieBannerHeight(newBannerSize)

      lastBannerSize = newBannerSize
    })

    resizeObserver.observe(bannerBoundariesElement)

    return () => {
      resizeObserver.unobserve(bannerBoundariesElement)
    }
  }, [setCookieBannerHeight])

  useEffect(() => {
    const handleCookiesRejected = () => {
      onCookiesRejected?.()
    }

    window.addEventListener(
      ONE_TRUST_COOKIES_REJECTED_EVENT,
      handleCookiesRejected
    )

    return () => {
      window.removeEventListener(
        ONE_TRUST_COOKIES_REJECTED_EVENT,
        handleCookiesRejected
      )
    }
  }, [onCookiesRejected])

  const cookieBannerScript = useMemo(
    () => createCookieBannerScript(locale),
    [locale]
  )

  return (
    <>
      <style>{cookieCss}</style>
      <div className='cookie-consent-area'>
        <div
          className='cookie-consent-banner'
          role='alertdialog'
          aria-label={translations.title}
          aria-describedby='cookie-consent'
        >
          <div className='cookie-consent-banner-content'>
            <div>
              <h3 id='cookie-consent-title'>{translations.title}</h3>
              <p className='cookie-consent-text'>
                {translations.text}&nbsp;
                <a
                  href={translations.linkHref}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {translations.linkTitle}
                </a>
              </p>
            </div>
            <div className='cookie-buttons'>
              <button
                className='cookie-accept-btn'
                onClick={() => setCookiesAccepted(true)}
              >
                {cookiesAccepted
                  ? translations.acceptedLabel
                  : translations.acceptLabel}
              </button>
              <button className='cookie-settings-link'>
                {translations.settingsLabel}
              </button>
            </div>
          </div>
          <div
            data-testid='cookie-consent-banner-boundaries'
            className='cookie-consent-banner-boundaries'
            ref={bannerBoundariesRef}
          />
        </div>
      </div>
      <script
        dangerouslySetInnerHTML={{
          __html: cookieBannerScript,
        }}
        key='cookieBannerScript'
      />
    </>
  )
}

export default memo(CookieBanner)
