import { Device } from 'constants/device'

import { useMainContext } from 'components/main-context'
import { useMedia } from 'react-use'

const useMediaWithDevice = (
  query: string,
  trueForDevice: Device,
  defaultState?: boolean
) => {
  const { device } = useMainContext()

  return useMedia(query, defaultState ?? device === trueForDevice)
}

export default useMediaWithDevice
