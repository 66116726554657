import {
  FocusEvent,
  MouseEvent,
  Ref,
  TransitionEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import { Device } from '@typeform/ginger/dist/constants/device'
import useMediaWithDevice from '@typeform/ginger/dist/hooks/use-media-with-device'
import { TTopMenuItem } from 'models/top-menu-item-model.types'

import SubMenu from '../sub-menu/sub-menu'
import VerticalCaret from '../vertical-caret/vertical-caret'
import {
  TMenuItemClickWithEventHandler,
  TSubMenuItemClickEventHandler,
} from '../../mega-menu.types'
import { useMenuContext } from '../../mega-menu-provider'

import { TopMenuButton, TopMenuItemContainer } from './top-menu.styles'

type TTrimmedLabelProps = {
  label?: string
  trimmedLabel: string
  threshold?: number
}

const TrimmedLabel = memo(
  ({ label, trimmedLabel, threshold }: TTrimmedLabelProps) => {
    const shouldShowTrimmedLabel = useMediaWithDevice(
      `(max-width: ${threshold}px)`,
      Device.Mobile
    )

    return <>{shouldShowTrimmedLabel && trimmedLabel ? trimmedLabel : label}</>
  }
)

type TTopMenuItemProps = {
  palette: Palette
  item: TTopMenuItem
  isActive: boolean
  onMenuOpen?: TMenuItemClickWithEventHandler
  onMenuClose?: TMenuItemClickWithEventHandler
  onSubMenuItemClick?: TSubMenuItemClickEventHandler
  subMenuElementRef: Ref<HTMLDivElement | null>
}
const TopMenuItem = ({
  palette,
  item,
  isActive,
  onMenuOpen,
  onMenuClose,
  onSubMenuItemClick,
  subMenuElementRef,
}: TTopMenuItemProps) => {
  const {
    label,
    trimmedLabel,
    labelTrimThreshold,
    link,
    linkTarget,
    items,
    layout,
    seeMoreLink,
  } = item
  const { isMobileMenu } = useMenuContext()
  const elementRef = useRef<HTMLLIElement | null>(null)

  const handleMenuOpen = useCallback(
    (event: MouseEvent<HTMLLIElement | HTMLAnchorElement>) =>
      onMenuOpen?.({ item, event }),
    [item, onMenuOpen]
  )

  const handleMenuClose = useCallback(
    (
      event:
        | MouseEvent<HTMLLIElement | HTMLAnchorElement | HTMLButtonElement>
        | FocusEvent<HTMLLIElement>
        | KeyboardEvent
    ) => onMenuClose?.({ item, event }),
    [item, onMenuClose]
  )

  const handleMenuBlur = useCallback(
    (event: FocusEvent<HTMLLIElement>) => {
      if (event.currentTarget.contains(event.relatedTarget)) {
        return
      }
      handleMenuClose(event)
    },
    [handleMenuClose]
  )

  const { listProps, buttonProps } = useMemo(() => {
    if (!items) {
      return {
        buttonProps: {
          onClick: (event: MouseEvent<HTMLAnchorElement>) =>
            onSubMenuItemClick?.({ item, event }),
        },
      }
    }

    const buttonProps = {
      'aria-haspopup': true,
      'aria-expanded': isActive,
      'onClick': isActive ? handleMenuClose : handleMenuOpen,
    }

    let listProps = !isMobileMenu
      ? {
          onMouseEnter: handleMenuOpen,
          onMouseLeave: handleMenuClose,
          onBlur: handleMenuBlur,
        }
      : {}

    return {
      buttonProps,
      listProps,
    }
  }, [
    item,
    items,
    isActive,
    isMobileMenu,
    handleMenuOpen,
    handleMenuClose,
    handleMenuBlur,
    onSubMenuItemClick,
  ])

  useEffect(() => {
    if (!isActive || isMobileMenu) {
      return
    }

    const exitKeyHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleMenuClose(event)
      }
    }

    window.addEventListener('keydown', exitKeyHandler)

    return () => {
      window.removeEventListener('keydown', exitKeyHandler)
    }
  }, [isActive, handleMenuClose, isMobileMenu])

  const handleScrollToSubMenu = (event: TransitionEvent<HTMLDivElement>) => {
    if (event.propertyName === 'max-height' && elementRef.current) {
      elementRef.current.parentElement?.scrollTo({
        top: elementRef.current.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  const hasSubMenu = items && items.length > 0

  return (
    <TopMenuItemContainer
      ref={elementRef}
      palette={palette}
      isActive={isActive}
      {...listProps}
    >
      <TopMenuButton href={link} target={linkTarget} {...buttonProps}>
        {trimmedLabel && labelTrimThreshold ? (
          <TrimmedLabel
            label={label}
            trimmedLabel={trimmedLabel}
            threshold={labelTrimThreshold}
          />
        ) : (
          label
        )}
        {hasSubMenu && <VerticalCaret />}
      </TopMenuButton>
      {hasSubMenu && (
        <SubMenu
          ref={subMenuElementRef}
          layout={layout}
          palette={palette}
          items={items}
          seeMoreLink={seeMoreLink}
          isActive={isActive}
          onItemClick={onSubMenuItemClick}
          onTransitionEnd={
            isMobileMenu && isActive ? handleScrollToSubMenu : undefined
          }
        />
      )}
    </TopMenuItemContainer>
  )
}

export default memo(TopMenuItem)
