import React, { memo, useCallback, useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import PropTypes from 'prop-types'

import { NAMESPACES } from '../../../constants/locales'
import OktaValidationError from '../okta-validation-error'
import { getSubmitEle } from '../dom-utils'

const TermsAndConditionsValidation = ({ isConsentGranted }) => {
  const { t } = useTranslation(NAMESPACES.AUTH)
  const [shouldShow, setShouldShow] = useState(false)

  const handleSubmit = useCallback(() => {
    setShouldShow(!isConsentGranted)
  }, [isConsentGranted])

  useEffect(() => {
    const submitEle = getSubmitEle()

    if (submitEle) {
      submitEle.addEventListener('click', handleSubmit)

      return () => {
        return submitEle.removeEventListener('click', handleSubmit)
      }
    }
  }, [handleSubmit])

  useEffect(() => {
    if (isConsentGranted && shouldShow) {
      setShouldShow(false)
    }
  }, [isConsentGranted, shouldShow])

  return (
    shouldShow && (
      <OktaValidationError
        error={t('auth.signup.form.terms-and-condition.required')}
      />
    )
  )
}

TermsAndConditionsValidation.propTypes = {
  isConsentGranted: PropTypes.bool.isRequired,
}

export default memo(TermsAndConditionsValidation)
