import styled, { createGlobalStyle, css } from 'styled-components'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { NO_JS_CLASSNAME } from 'components/atoms/animated-container'

import {
  HEADER_HEIGHT,
  HEADER_MAX_WIDTH,
  MEGA_MENU_Z_INDEX,
  MOBILE_HEADER_HEIGHT,
} from './mega-menu.constants'
import {
  TopMenuItemContainer,
  TopMenuList,
  mobileMenuOpenStyles,
} from './components/top-menu/top-menu.styles'
import {
  HEADER_BACKGROUND_OFFSET,
  MenuBackgroundContainer,
  menuBackgroundCommonStyles,
  menuBackgroundExpandedStyles,
} from './components/menu-background/menu-background.styles'
import {
  SubMenuContainer,
  subMenuExpandedStyles,
} from './components/sub-menu/sub-menu.styles'
import {
  NoJsMenuTrigger,
  TriggerButton,
  triggerSvgVisibleStyles,
} from './components/mobile-menu-trigger/mobile-menu-trigger.styles'

type TNavigationContainerProps = {
  palette: Palette
  bannerHeight?: number
  bannerIsFixed?: boolean
}

export const NavigationContainer = styled.div<TNavigationContainerProps>`
  ${({ bannerIsFixed, bannerHeight }) => {
    if (bannerIsFixed) {
      return css`
        position: sticky;
        top: 0;
        margin-bottom: -${MOBILE_HEADER_HEIGHT}px;

        ${mediaQueries.md`
          margin-bottom: -${HEADER_HEIGHT}px;
        `}
      `
    }

    return css`
      position: fixed;
      top: ${bannerHeight ? `${bannerHeight}px` : 0};
    `
  }}
  left: 0;
  width: 100%;
  z-index: ${MEGA_MENU_Z_INDEX};
  color: ${({ palette }: { palette: Palette }) =>
    palette === Palette.Negative ? NeutralColors.White : NeutralColors.Ink};
`

export const TopMenuContainer = styled.div`
  max-width: ${HEADER_MAX_WIDTH}px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${MOBILE_HEADER_HEIGHT}px;
  padding-left: ${spacing(2)};
  padding-right: ${spacing(2)};

  ${mediaQueries.sm`
    padding-left: ${spacing(3)};
    padding-right: ${spacing(3)};
  `}

  ${mediaQueries.md`
    min-height: ${HEADER_HEIGHT}px;
  `}
`

export const MegaMenuNoJsStyles = createGlobalStyle<{
  palette: Palette
  transparentBackground?: boolean
}>`
  .${NO_JS_CLASSNAME} {
    ${MenuBackgroundContainer} {
      height: ${MOBILE_HEADER_HEIGHT + HEADER_BACKGROUND_OFFSET}px;
      ${mediaQueries.md`
        height: ${HEADER_HEIGHT + HEADER_BACKGROUND_OFFSET}px;
      `}
    }
    ${TriggerButton} {
      pointer-events: none;
    }
    ${NoJsMenuTrigger} {
      display: block;
    }

    &:has(${NoJsMenuTrigger}:checked) {
      ${TriggerButton} {
        #close-menu-svg {
          ${triggerSvgVisibleStyles}
        }
        #open-menu-svg {
          visibility: hidden;
          opacity: 0;
        }
      }

      ${TopMenuList} {
        ${mobileMenuOpenStyles}

        bottom: 0;
      }

      ${MenuBackgroundContainer} {
        height: 100vh;

        ${menuBackgroundExpandedStyles}
      }

      ${SubMenuContainer} {
        ${subMenuExpandedStyles}

        max-height: unset;
      }
    }

    ${mediaQueries.md`
      ${SubMenuContainer} {
        &:before {
          content: '';
            bottom: 0;
          ${menuBackgroundCommonStyles}
        }
      }

      ${TopMenuItemContainer}:hover, ${TopMenuItemContainer}:has(*:focus) {
        ${SubMenuContainer} {
          ${subMenuExpandedStyles}
          &:before {
            ${menuBackgroundExpandedStyles}
          }
        }
      }
    `}
  }
`
