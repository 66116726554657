import React, { memo, useCallback, useRef, useState } from 'react'
import { Button } from '@typeform/echo-ui'
import TypeformLogo from 'components/atoms/common/logo'
import { trackSignupPageLogoClick } from 'components/tracking'
import { CloseIcon } from 'components/atoms/common/close-icon'
import { ArrowLeftIcon } from '@typeform/echo-assets'
import AuthDivider from 'components/okta-signup-page/auth-divider'

import { TOktaConfig } from '../types'

import ViaSocialsButtons from './via-socials'
import ViaEmail from './via-email'
import ViaEmailButton from './via-email/via-email-button'
import {
  AuthBottomContainer,
  AuthHeaderContainer,
  AuthNavBarContainer,
  AuthThroughEmail,
  AuthThroughSocials,
  AuthWrapper,
  BottomText,
  Heading,
} from './auth.styles'

const Auth = ({
  oktaConfig,
  closeModal,
}: {
  oktaConfig: TOktaConfig
  closeModal: () => {}
}) => {
  const [showOktaWidget, shouldShowOktaWidget] = useState(false)
  const isOktaWidgetRendered = useRef(false)

  const handleShowOktaWidget = useCallback(
    (shouldShow: boolean) => () => {
      shouldShowOktaWidget(shouldShow)
      isOktaWidgetRendered.current = true
    },
    []
  )

  const handleRegistrationComplete = useCallback(() => {
    // Do nothing for now, tracking on future
  }, [])

  return (
    <AuthWrapper>
      <AuthNavBarContainer>
        <Button
          kind='ghost'
          iconLeft={<CloseIcon />}
          onClick={closeModal}
          size='small'
        />
        {showOktaWidget && (
          <Button
            kind='ghost'
            iconLeft={<ArrowLeftIcon height={12} />}
            onClick={handleShowOktaWidget(false)}
            size='small'
          >
            Go back
          </Button>
        )}
      </AuthNavBarContainer>
      <AuthHeaderContainer>
        <TypeformLogo
          title='Typeform'
          target='_top'
          onClick={trackSignupPageLogoClick}
          onlyIcon
        />
        <Heading textAlign='center' mt={12}>
          Get started with Typeform!
        </Heading>
      </AuthHeaderContainer>
      <AuthThroughSocials showOktaWidget={showOktaWidget}>
        <ViaSocialsButtons />
        <AuthDivider />
        <ViaEmailButton handleOnClick={handleShowOktaWidget(true)} />
      </AuthThroughSocials>
      <AuthThroughEmail showOktaWidget={showOktaWidget}>
        {/* Ensures that the Okta widget gets dynamically loaded only once */}
        {(showOktaWidget || isOktaWidgetRendered.current) && (
          <ViaEmail
            oktaConfig={oktaConfig}
            handleRegistrationComplete={handleRegistrationComplete}
          />
        )}
      </AuthThroughEmail>
      <AuthBottomContainer>
        <BottomText>Already have an account?</BottomText>
        <Button
          as='a' // @ts-expect-error: Button cannot be used as a JSX component - given a @types/react version mismatch with @echo-ui
          href='/login'
          size='small'
          kind='secondary'
        >
          Log in
        </Button>
      </AuthBottomContainer>
    </AuthWrapper>
  )
}

export default memo(Auth)
