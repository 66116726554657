import {
  RebrandTokens,
  TokensThemeStructure,
  rebrand,
} from '@typeform/echo-tokens'
import { zIndexes } from '@typeform/echo-ui'
import { fontFamily } from '@typeform/ginger/dist/constants/typography'

const getStyledSystemTheme = (theme: TokensThemeStructure) => {
  return {
    // Mappings for styled-system
    breakpoints: {
      small: '414px',
      medium: '768px',
      large: '1024px',
      xlarge: '1440px',
    },
    borderWidths: theme.border.width,
    colors: theme.color,
    radii: theme.border.radius,
    fonts: theme.typography,
    shadows: {
      elevation: theme.elevation,
    },
    sizes: theme.sizing,
    space: theme.spacing,
    zIndices: zIndexes,
  }
}

const siteRebrandOverrides: RebrandTokens = {
  ...rebrand,
  typography: {
    header: {
      product: {
        h1: `400 24px/133% ${fontFamily.apercuPro}`,
        h2: `400 21px/133% ${fontFamily.apercuPro}`,
        h3: `500 18px/133% ${fontFamily.apercuPro}`,
        h4: `500 16px/125% ${fontFamily.apercuPro}`,
        h5: `500 14px/143% ${fontFamily.apercuPro}`,
      },
      brand: {
        h1: '400 36px/122% "Apercu Pro"',
        h2: '400 31px/116% "Apercu Pro"',
        h3: '300 27px/133% "Apercu Pro"',
        h4: '500 24px/133% "Apercu Pro"',
        h5: '500 21px/133% "Apercu Pro"',
        h6: '500 18px/133% "Apercu Pro"',
      },
    },
    subheader: {
      h1Subheader: `400 16px/125% ${fontFamily.apercuPro}`,
      h2Subheader: `400 14px/143% ${fontFamily.apercuPro}`,
      h3Subheader: `400 12px/133% ${fontFamily.apercuPro}`,
      h1Underline: `400 16px/125% ${fontFamily.apercuPro}`,
      h2Underline: `400 14px/143% ${fontFamily.apercuPro}`,
      h3Underline: '400 12px/133% "Inter"',
    },
    description: {
      default: `400 12px/133% ${fontFamily.apercuPro}`,
      emphasis: `500 12px/133% ${fontFamily.apercuPro}`,
      underline: `400 12px/133% ${fontFamily.apercuPro}`,
      emphasisUnderline: `500 12px/133% ${fontFamily.apercuPro}`,
      heavy: `700 12px/133% ${fontFamily.apercuPro}`,
    },
    label: {
      sm: {
        default: `400 13px/123% ${fontFamily.apercuPro}`,
        emphasis: `500 13px/120% ${fontFamily.apercuPro}`,
        underline: `400 13px/123% ${fontFamily.apercuPro}`,
        emphasisUnderline: `500 13px/120% ${fontFamily.apercuPro}`,
        heavy: `700 13px/123% ${fontFamily.apercuPro}`,
      },
      md: {
        default: `400 14px/143% ${fontFamily.apercuPro}`,
        emphasis: `500 14px/143% ${fontFamily.apercuPro}`,
        underline: `400 14px/143% ${fontFamily.apercuPro}`,
        emphasisUnderline: `500 14px/143% ${fontFamily.apercuPro}`,
        heavy: `700 14px/143% ${fontFamily.apercuPro}`,
      },
      lg: {
        default: `400 16px/150% ${fontFamily.apercuPro}`,
        emphasis: `500 16px/150% ${fontFamily.apercuPro}`,
        underline: `400 16px/150% ${fontFamily.apercuPro}`,
        emphasisUnderline: `500 16px/150% ${fontFamily.apercuPro}`,
        heavy: `700 16px/150% ${fontFamily.apercuPro}`,
      },
    },
    body: {
      default: `400 14px/143% ${fontFamily.apercuPro}`,
      emphasis: `500 14px/143% ${fontFamily.apercuPro}`,
      underline: `400 14px/143% ${fontFamily.apercuPro}`,
      emphasisUnderline: `500 14px/143% ${fontFamily.apercuPro}`,
      heavy: `700 14px/143% ${fontFamily.apercuPro}`,
    },
  },
}

const siteRebrandTokens = {
  ...siteRebrandOverrides,
  ...getStyledSystemTheme(siteRebrandOverrides),
}
export default siteRebrandTokens
