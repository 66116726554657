import { spacing } from '@typeform/ginger/dist/util/spacing'
import mediaQueries, {
  mediaQueriesDown,
} from '@typeform/ginger/dist/util/mediaqueries'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import styled, { css } from 'styled-components'
import { ButtonElement } from 'components/atoms/button'

import { MOBILE_CTAS_CONTAINER_HEIGHT } from '../../mega-menu.constants'

type TAuthLinksContainerProps = {
  cookieBannerHeight: number
  isMobileMenuOpen: boolean
  isMinimalMenu?: boolean
}
export const AuthLinksContainer = styled.div<TAuthLinksContainerProps>`
  display: flex;
  gap: ${spacing(2)};
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  ${({ isMinimalMenu, cookieBannerHeight, isMobileMenuOpen }) =>
    !isMinimalMenu &&
    mediaQueriesDown.md`
      visibility: hidden;
      transform: translateY(${MOBILE_CTAS_CONTAINER_HEIGHT}px);
      transition: transform 300ms ease-in-out, visibility 300ms ease-in-out;
      position: fixed;
      bottom: ${cookieBannerHeight}px;
      left: 0;
      right: 0;
      background-color: ${NeutralColors.Ink};
      height: ${MOBILE_CTAS_CONTAINER_HEIGHT}px;

      ${
        !cookieBannerHeight &&
        css`
          height: calc(
            ${MOBILE_CTAS_CONTAINER_HEIGHT}px + env(safe-area-inset-bottom)
          );
          padding-bottom: env(safe-area-inset-bottom);
        `
      }

      ${
        isMobileMenuOpen &&
        css`
          visibility: visible;
          transform: translateY(0);
        `
      }
    `}

  ${mediaQueries.md`
    justify-content: flex-end;
    ${ButtonElement} {
      text-decoration: none;
    }
  `}
`
