import {
  ATTRIBUTION_ID_GLOBAL_KEY,
  CATEGORY,
  OWNER,
  PROPERTY,
} from 'constants/tracking'
import { TYPEFORM_ROOT_DOMAIN } from 'constants/public-envs'

import isMobile from 'ismobilejs'
import idUtil from '@typeform/js-tracking/lib/idUtil'
import sessionUtil from '@typeform/js-tracking/lib/sessionUtil'
import { removeQueryString } from 'utils/url'
import { getOptimizelyAttributes, getOptimizelyUserId } from 'utils/optimizely'
import { ATTRIBUTION_COOKIE_NAME } from '@typeform/js-tracking/lib/constants'
import { v4 as uuidv4 } from 'uuid'
import Cookies from 'js-cookie'

export const getAttributionUserId = () => {
  return window[ATTRIBUTION_ID_GLOBAL_KEY] || null
}

export const setAttributionUserId = id => {
  window[ATTRIBUTION_ID_GLOBAL_KEY] = id
}

export const getLinkUrl = (linkUrl = '') => {
  const origin = typeof window !== 'undefined' ? window.location.origin : ''
  return linkUrl.replace(origin, '')
}

export const getFormattedOptimizelyAttributes = () => {
  const nextJsData = window?.__NEXT_DATA__?.props.pageProps
  const attributes = getOptimizelyAttributes({
    device: nextJsData?.device,
    language: nextJsData?.language,
    currentUrl: removeQueryString(nextJsData?.currentUrl),
    visitorType: nextJsData?.visitorType,
    experiments: nextJsData?.experiments,
    isVisitorAuthenticated: nextJsData?.isVisitorAuthenticated,
    isVisitorRegistered: nextJsData?.isVisitorRegistered,
    userCountryCode: nextJsData?.userCountryCode,
  }).reduce(
    (acc, { key, value }) => ({
      // Transform attributes array to key-value/object
      ...acc,
      [key]: value,
    }),
    {}
  )

  return { Optimizely: { attributes } }
}

export const getMandatoryProperties = () => {
  const isMobileDevice = isMobile(window.navigator).any
  const nextJsData = window?.__NEXT_DATA__?.props.pageProps
  const typeformProperty =
    nextJsData?.pageContent?.trackingConfig?.viewPageSectionProps
      ?.typeform_property ?? PROPERTY.PUBLIC_SITE

  return {
    category: CATEGORY.PUBLIC_SITE,
    typeform_version: isMobileDevice ? 'mobile' : 'v2',
    typeform_property: typeformProperty,
    unique_pageview_id: idUtil(),
    unique_sectionview_id: idUtil(),
    attribution_user_id: getAttributionUserId(),
    page: getPage(),
    tracking_session_id: sessionUtil.read(),
    event_owner: OWNER,
    country: nextJsData?.userCountryCode,
  }
}

export const addOptimizelyMiddleware = () => {
  try {
    window.analytics.addSourceMiddleware(async ({ payload, next }) => {
      const userId = getOptimizelyUserId()
      const optimizelyAttributes = getFormattedOptimizelyAttributes().Optimizely

      if (userId) {
        payload.obj.context.Optimizely = {
          ...optimizelyAttributes,
          userId,
        }
      }

      next(payload)
    })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error adding Optimizely middleware', error)
  }
}

export const addMiddlewares = () => {
  addOptimizelyMiddleware()
}

export const setClearbitCookie = () => {
  if (window.reveal) {
    const { company } = window.reveal

    if (company) {
      // Override default encoder
      const cookies = Cookies.withConverter({
        write: (value = {}) => JSON.stringify(value),
      })

      cookies.set(
        'clearbit-reveal',
        {
          employees: company.metrics.employees,
        },
        {
          domain: `.${TYPEFORM_ROOT_DOMAIN}`,
        }
      )
    }
  }
}

export const getPage = () => window.location.origin + window.location.pathname

export const syncAttributionIdState = () => {
  const attributionUserIdFromCookie = Cookies.get(ATTRIBUTION_COOKIE_NAME)
  const attributionUserIdFromState = getAttributionUserId()
  /*
    1. If attribution cookie is present and different than global attribution id
    use attribution cookie as source of truth
  */
  if (
    attributionUserIdFromCookie &&
    attributionUserIdFromCookie !== attributionUserIdFromState
  ) {
    setAttributionUserId(attributionUserIdFromCookie)
  }

  /*
    2. If global attribution id is undefined/null create a new uuid
  */
  if (!getAttributionUserId()) {
    setAttributionUserId(uuidv4())
  }
  /*
    3. Finally, replace history state with the latest attribution id
    so that back/forward/refresh actions have the current state
  */
  window.history.replaceState(
    {
      ...window.history.state,
      [ATTRIBUTION_ID_GLOBAL_KEY]: getAttributionUserId(),
    },
    '',
    `${window.location.href}`
  )
}
