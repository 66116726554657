import styled from 'styled-components'
import { fontSize } from '@typeform/ginger/dist/constants/typography'

export const ConsentsOptions = styled.div`
  padding-left: 30px;
  padding-bottom: 15px;
  padding-top: 8px;
`

export const SmallText = styled.p`
  font-size: ${fontSize.xSmall};
  margin: 0;
  color: ${props => props.color || 'inherit'};
`

export const SeeOptions = styled.div`
  align-items: baseline;
  cursor: pointer;
  display: flex;
  font-size: ${fontSize.xSmall};
  justify-content: space-between;
  line-height: 32px;
  margin: 0;

  svg {
    margin: 0 8px;
  }
`

export const CaretUpWrapper = styled.span`
  transition: all 0.3s ease-out;
  transform: rotateX(${({ rotateDegrees }) => rotateDegrees}deg);
`
