import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import {
  applyOpacityToHexColor,
  darkenHex,
} from '@typeform/ginger/dist/util/color'
import { css } from 'styled-components'

export const getMenuBackgroundColor = ({ palette }: { palette?: Palette }) => {
  const color =
    palette === Palette.Negative
      ? darkenHex(35, NeutralColors.Ink)
      : NeutralColors.White

  const opacityRatio = palette === Palette.Negative ? 86 : 90

  return applyOpacityToHexColor(opacityRatio, color)
}

export const getMenuBackgroundColorStyles = css`
  background-color: ${getMenuBackgroundColor};
  backdrop-filter: saturate(180%) blur(20px);
`
