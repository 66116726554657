import React, { memo } from 'react'

type TOptimizelyVariation = {
  variationId: string
  children?: React.ReactNode[] | React.ReactNode
  default?: boolean
}

const OptimizelyVariation = ({ children = null }: TOptimizelyVariation) =>
  children

OptimizelyVariation.displayName = 'OptimizelyVariation'

const MemoOptimizelyVariation = memo(OptimizelyVariation)

export { MemoOptimizelyVariation as OptimizelyVariation }
