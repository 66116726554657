import { Colors } from 'constants/colors'

export const isColorLight = (color: string = '') => {
  let hex = color.replace('#', '')
  if (hex.length === 6) {
    hex = `${hex}FF`
  }
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  const a = parseInt(hex.substring(6, 8), 16)
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b + (255 - a)
  return luma > 165
}

export const isColorDark = (color: string = '') => !isColorLight(color)

const getColor = (color: string): string => {
  return Colors[color] || color
}

export const percentToHex = (percent: number) => {
  if (typeof percent !== 'number') {
    throw new Error('percentToHex: a percentage value must be provided.')
  }

  const boundPercent = Math.max(0, Math.min(100, percent))
  const intValue = Math.round((boundPercent / 100) * 255)
  const hexValue = intValue.toString(16)
  return hexValue.padStart(2, '0').toUpperCase()
}

export const applyOpacityToHexColor = (opacity: number, color: string) => {
  if (typeof opacity !== 'number') {
    throw new Error('applyOpacityToHexColor: a opacity must be provided.')
  }

  if (!color) {
    throw new Error(
      `applyOpacityToHexColor: a color NAME (check constants/colors) or HEX must be provided.`
    )
  }

  return `${getColor(color)}${percentToHex(opacity)}`
}

const darkenColorComponent = (percent: number, component: number) => {
  return Math.floor(component * (percent / 100))
}

export const darkenHex = (percent: number, color: string) => {
  if (typeof percent !== 'number') {
    throw new Error('darkenHex: a percent must be provided.')
  }

  if (!color) {
    throw new Error(
      `darkenHex: a color NAME (check constants/colors) or HEX must be provided.`
    )
  }

  let hex = getColor(color).replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return `#${[r, g, b]
    .map(component =>
      darkenColorComponent(percent, component)
        .toString(16)
        .padStart(2, '0')
        .toUpperCase()
    )
    .join('')}`
}

const lightenColorComponent = (percent: number, component: number) => {
  return Math.floor(component + (255 - component) * (percent / 100))
}

export const lightenHex = (percent: number, color: string) => {
  if (typeof percent !== 'number') {
    throw new Error('lightenHex: a percent must be provided.')
  }

  if (!color) {
    throw new Error(
      `lightenHex: a color NAME (check constants/colors) or HEX must be provided.`
    )
  }

  let hex = getColor(color).replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return `#${[r, g, b]
    .map(component =>
      lightenColorComponent(percent, component)
        .toString(16)
        .padStart(2, '0')
        .toUpperCase()
    )
    .join('')}`
}
