import { NAMESPACES } from 'constants/locales'

import React, { useMemo, useCallback } from 'react'
import { string } from 'prop-types'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { useMedia } from 'react-use'
import { BREAKPOINTS } from '@typeform/ginger/dist'
import { ButtonSize } from 'components/atoms/button'
import { snakeCase } from '@s-libs/micro-dash'
import { trackItemClicked } from 'components/tracking'

import { StyledButton, Text } from './signup-success.styles'

// In the future, if we want to direct users to a filtered inbox, we'll need to use different links for mobile vs desktop
const INBOX_MAPPINGS = [
  {
    domains: ['gmail.com', 'googlemail.com'],
    mobileLink: 'https://mail.google.com/',
    desktopLink: 'https://mail.google.com/',
  },
  {
    domains: [
      'live.com',
      'live.co.uk',
      'hotmail.com',
      'hotmail.co.uk',
      'outlook.com',
      'outlook.co.uk',
    ],
    mobileLink: 'https://outlook.live.com/',
    desktopLink: 'https://outlook.live.com/',
  },
  {
    domains: ['yahoo.com'],
    mobileLink: 'https://mail.yahoo.com/',
    desktopLink: 'https://mail.yahoo.com/',
  },
  {
    domains: ['icloud.com'],
    mobileLink: 'https://www.icloud.com/mail/',
    desktopLink: 'https://www.icloud.com/mail/',
  },
]

const SignupSuccess = ({ email }) => {
  const isDesktop = useMedia(`(min-width: ${BREAKPOINTS.md}px)`, true)

  const openEmailLink = useMemo(() => {
    if (!email) {
      return null
    }

    for (const inboxMapping of INBOX_MAPPINGS) {
      if (inboxMapping.domains.some(suffix => email.endsWith(`@${suffix}`))) {
        return isDesktop ? inboxMapping.desktopLink : inboxMapping.mobileLink
      }
    }
    return null
  }, [email, isDesktop])
  const { t } = useTranslation(NAMESPACES.AUTH)

  const trackOpenEmailCta = useCallback(() => {
    trackItemClicked({
      item: 'redirect_to_page',
      item_type: 'link',
      link_url: openEmailLink,
      label: snakeCase(t('auth.signup.success.open-email')),
      location: 'sign-up:success',
    })
  }, [openEmailLink, t])

  return (
    <>
      <Text>
        <Trans
          i18nKey='auth.signup.success.email-sent'
          components={[<strong key='strong' />]}
          values={{ email }}
          ns={NAMESPACES.AUTH}
        />
      </Text>
      {openEmailLink && (
        <StyledButton
          size={ButtonSize.Medium}
          href={openEmailLink}
          target='_blank'
          rel='noopener noreferrer'
          onClick={trackOpenEmailCta}
        >
          {t('auth.signup.success.open-email')}
        </StyledButton>
      )}
    </>
  )
}

SignupSuccess.displayName = 'SignupSuccess'

SignupSuccess.propTypes = {
  email: string.isRequired,
}

export default SignupSuccess
