import React from 'react'
import {
  ForegroundColors,
  NeutralColors,
} from '@typeform/ginger/dist/constants/colors'
import { GRID_UNIT } from '@typeform/ginger/dist/constants/design'
import styled, { css } from 'styled-components'

export const AlignmentStyles = {
  Left: css`
    left: 0;
    transform: translateY(100%);
  `,
  Center: css`
    left: 50%;
    transform: translateY(100%) translateX(-50%);
  `,
  Right: css`
    right: 0;
    transform: translateY(100%);
  `,
}

export const DropdownContainer = styled.div`
  position: relative;
`

export const getDropdownTriggerProps = (props: {
  id: string
  menuId: string
  open?: boolean
}) => ({
  'aria-haspopup': 'menu',
  'aria-controls': props.menuId,
  'aria-expanded': props.open,
})

export const DropdownTrigger = styled.button.attrs(
  ({ menuId, open }: { id: string; menuId: string; open?: boolean }) => ({
    'aria-haspopup': 'menu',
    'aria-controls': menuId,
    'aria-expanded': open ? 'true' : 'false',
  })
)<
  React.ComponentProps<'button'> & {
    id: string
    menuId: string
    open?: boolean
  }
>`
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
`

export const DropdownMenuContainer = styled.div<{
  'alignment': keyof typeof AlignmentStyles
  'open'?: boolean
  'aria-haspopup'?: string
  'aria-controls'?: string
  'aria-expanded'?: boolean
}>`
  position: absolute;
  bottom: -${GRID_UNIT}px;
  background-color: ${NeutralColors.White};
  color: ${NeutralColors.Ink};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0 2px 4px, rgba(0, 0, 0, 0.06) 0 2px 12px;
  padding: ${GRID_UNIT}px 0;
  min-width: 150px;
  max-width: 250px;
  display: ${({ open }) => (open ? 'block' : 'none')};

  ${({ alignment }) => AlignmentStyles[alignment]}
`

export const DropdownSection = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    padding-bottom: ${GRID_UNIT}px;
    margin-bottom: ${GRID_UNIT}px;
  }
`

export const DropdownLink = styled.a.attrs(
  ({ href, destructive }: { href: string; destructive: boolean }) => ({
    as: href ? 'a' : 'button',
    role: 'menuitem',
    destructive,
  })
)`
  display: flex;
  align-items: center;
  padding-left: ${GRID_UNIT * 2}px;
  padding-right: ${GRID_UNIT * 2}px;
  min-height: ${GRID_UNIT * 4}px;
  border: none;
  background: none;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: ${GRID_UNIT / 2}px;
  }

  color: ${({ destructive }) =>
    destructive ? ForegroundColors.CriticalDefault : NeutralColors.Ink};

  &:hover,
  &:focus {
    background-color: ${NeutralColors.Grey100};
  }
`
