import styled from 'styled-components'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { fontSize } from '@typeform/ginger/dist/constants/typography'

export const Divider = styled.div`
  position: relative;
  background-color: ${NeutralColors.Grey400};
  height: 1px;
  margin: 25px 0;
  text-align: center;
`
export const Text = styled.span`
  background-color: ${NeutralColors.White};
  color: ${NeutralColors.Grey700};
  font-size: ${fontSize.xSmall};
  padding: 0 15px;
  position: relative;
  top: -0.5em;
`
