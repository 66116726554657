import styled from 'styled-components'
import { Flex } from '@typeform/echo-ui'

export const BackgroundDropContainer = styled(Flex)<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0d0d0db2;
  backdrop-filter: blur(0.5px);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  opacity: ${props => (props.isOpen ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;
  ${props => !props.isOpen && 'pointer-events: none;'}
  overflow: hidden;
`

export const WidgetContainer = styled(Flex)<{ isOpen: boolean }>`
  display: flex;
  align-items: stretch;
  justify-content: center;
  background-color: white;
  border-radius: 32px;
  width: 380px;
  min-height: 580px;
  max-height: 80vh;
  overflow: hidden;
  padding: 24px;
  z-index: 10001;
  transition: transform 0.4s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateX(${props => (props.isOpen ? '0' : '100%')});
  opacity: ${props => (props.isOpen ? '1' : '0')};
`
