import React, { memo, useCallback, useMemo, useState } from 'react'
import { getLocaleFallbackLink } from '@typeform/ginger/dist/util/translator'
import { useMainContext } from '@typeform/ginger/dist/components/main-context'

import { ALL_LOCALE, SelectPositions } from './constants'
import LanguagesMenu, { TOption } from './components/languages-menu'
import CaretDown from './components/caret-down'
import Globe from './components/globe'
import {
  Container,
  Prefix,
  Suffix,
  Text,
  Button,
} from './language-switcher.styles'

export type TPageLocale = {
  title: keyof typeof ALL_LOCALE
  href: string
}

export type TLanguageSwitcherProps = {
  pageLocales: TPageLocale[]
  onLanguageSwitcherOptionClick: (optionEvent: {
    option: TOption
    event: React.MouseEvent<HTMLAnchorElement>
  }) => void
  position?: SelectPositions
  locale?: string
}

const LanguageSwitcher: React.FC<TLanguageSwitcherProps> = ({
  pageLocales,
  onLanguageSwitcherOptionClick,
  position = SelectPositions.Top,
  locale: localeProp,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const mainContext = useMainContext()
  const locale = localeProp || mainContext.locale
  const createOptions = useCallback(
    (localesMap: Record<string, string>) => {
      return Object.keys(localesMap).map(supportedLocale => ({
        locale: supportedLocale,
        label: localesMap[supportedLocale],
        href:
          pageLocales.find(pageLocale => pageLocale.title === supportedLocale)
            ?.href ?? getLocaleFallbackLink(supportedLocale),
      }))
    },
    [pageLocales]
  )

  const options = useMemo(() => {
    const availableLocales = pageLocales.map(pageLocale => pageLocale.title)
    const localesMap = Object.fromEntries(
      Object.entries(ALL_LOCALE).filter(([key]) =>
        availableLocales.includes(key)
      )
    )

    return createOptions(localesMap)
  }, [createOptions, pageLocales])

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()

      setIsOpen(prevIsOpen => !prevIsOpen)
    },
    []
  )

  return (
    <Container>
      <LanguagesMenu
        options={options}
        onLanguageSwitcherOptionClick={onLanguageSwitcherOptionClick}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        position={position}
      />
      <Button onClick={handleClick}>
        <Prefix>
          <Globe />
        </Prefix>
        <Text>{ALL_LOCALE[locale]}</Text>
        <Suffix>
          <CaretDown />
        </Suffix>
      </Button>
    </Container>
  )
}

LanguageSwitcher.displayName = 'LanguageSwitcher'

export default memo(LanguageSwitcher)
