import { MouseEvent, memo, useCallback } from 'react'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import { useCookieBannerHeight } from 'components/organisms/cookie-banner'
import Button, { ButtonSize } from 'components/atoms/button'

import { TCtaData, MegaMenuType } from '../../mega-menu.types'
import { useMenuContext } from '../../mega-menu-provider'

import { AuthLinksContainer } from './auth-links.styles'

export const AuthLinks = memo(
  ({
    palette,
    isMobileMenuOpen,
    isMinimalMenu,
    ctas,
  }: {
    palette: Palette
    isMobileMenuOpen: boolean
    isMinimalMenu?: boolean
    ctas: TCtaData[]
  }) => {
    const cookieBannerHeight = useCookieBannerHeight() || 0
    const { isMobileMenu } = useMenuContext()

    const handleCtaClick = useCallback(
      (
          onClickHandler?: (
            event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
            params: { menuType: MegaMenuType }
          ) => void
        ) =>
        (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
          if (!onClickHandler) {
            return
          }

          let menuType = MegaMenuType.Desktop

          if (isMinimalMenu) {
            menuType = MegaMenuType.Mimimal
          } else if (isMobileMenu) {
            menuType = MegaMenuType.Mobile
          }

          onClickHandler?.(event, {
            menuType,
          })
        },
      [isMobileMenu, isMinimalMenu]
    )

    return (
      <AuthLinksContainer
        isMobileMenuOpen={isMobileMenuOpen}
        cookieBannerHeight={cookieBannerHeight}
        isMinimalMenu={isMinimalMenu}
      >
        {ctas.map(({ variant, label, onClick, link, dataQa }) => (
          <Button
            key={link}
            variant={variant}
            href={link}
            size={ButtonSize.Medium}
            hasMinWidth={false}
            palette={isMobileMenuOpen ? Palette.Negative : palette}
            onClick={handleCtaClick(onClick)}
            data-gtm-event='cta_button'
            data-qa={dataQa}
          >
            {label}
          </Button>
        ))}
      </AuthLinksContainer>
    )
  }
)
