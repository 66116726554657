import {
  IMAGE_LOADING,
  IMAGE_DECODING,
  IMAGE_FETCHPRIORITY,
} from '@typeform/ginger/dist/constants/image'
import React, { memo } from 'react'

import { getPictureSources } from './utils/get-picture-sources'
import { sizes as defaultSizes } from './constants'
import { PictureContainer, Image } from './picture.styles'

export type TPicture = {
  alt: string
  loading?: typeof IMAGE_LOADING[keyof typeof IMAGE_LOADING]
  decoding?: typeof IMAGE_DECODING[keyof typeof IMAGE_DECODING]
  fetchpriority?: typeof IMAGE_FETCHPRIORITY[keyof typeof IMAGE_FETCHPRIORITY]
  role?: string
  src: string
  sizes?: string[]
  defaultSize?: string
  width?: number
  height?: number
  srcset?: {
    srcSetWebP: string
    srcSet: string
    original: string
  }
}
const Picture = ({
  src,
  sizes = defaultSizes,
  defaultSize = defaultSizes[defaultSizes.length - 1],
  role = 'presentation',
  alt,
  loading = IMAGE_LOADING['lazy'],
  decoding = IMAGE_DECODING['async'],
  fetchpriority = IMAGE_FETCHPRIORITY['low'],
  srcset,
  width,
  height,
  ...additionalImageProps
}: TPicture) => {
  const sources = srcset
    ? {
        srcSetWebP: srcset.srcSetWebP,
        srcSet: srcset.srcSet,
        fallback: srcset.original,
      }
    : getPictureSources({ src, sizes, defaultSize })

  if (!sources) {
    return null
  }

  const pictureProps = {
    ...(width && { width }),
    ...(height && { height }),
  }

  return (
    <div style={{ maxWidth: width }}>
      <PictureContainer {...pictureProps}>
        <source srcSet={sources.srcSetWebP} type='image/webp' />
        <source data-testid='default-source' srcSet={sources.srcSet} />
        <Image
          alt={alt}
          height={height}
          loading={loading}
          decoding={decoding}
          fetchpriority={fetchpriority}
          role={role}
          src={sources.fallback}
          width={width}
          {...additionalImageProps}
        />
      </PictureContainer>
    </div>
  )
}

export default memo(Picture)
