import { memo } from 'react'
import { TTopMenuItemGalleryItem } from 'models/top-menu-item-gallery-item-model.types'

import { TSubMenuItemClickEventHandler } from '../../mega-menu.types'

import {
  MenuGalleryLink,
  MenuGalleryList,
  MenuListItem,
} from './sub-menu.styles'

export type TSubMenuWithGalleryProps = {
  items?: TTopMenuItemGalleryItem[]
  onItemClick?: TSubMenuItemClickEventHandler
}
export const SubMenuWithGallery = ({
  items,
  onItemClick,
}: TSubMenuWithGalleryProps) => {
  return (
    <MenuGalleryList>
      {items?.map(menuItem => (
        <MenuListItem key={menuItem.id}>
          <MenuGalleryLink
            onClick={event => onItemClick?.({ event, item: menuItem })}
            href={menuItem.link || undefined}
          >
            <>
              {menuItem.icon && (
                <img
                  width='24'
                  height='24'
                  alt={menuItem.icon.title || ''}
                  src={menuItem.icon.url || ''}
                />
              )}
              {menuItem.label}
            </>
          </MenuGalleryLink>
        </MenuListItem>
      ))}
    </MenuGalleryList>
  )
}

export default memo(SubMenuWithGallery)
