import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { GRID_UNIT } from '@typeform/ginger/dist/constants/design'
import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import styled, { css, keyframes } from 'styled-components'

import { AvatarSize } from './avatar.types'

const pictureSizes = {
  [AvatarSize.Medium]: GRID_UNIT * 4,
  [AvatarSize.Large]: GRID_UNIT * 5,
}
const textSizes = {
  [AvatarSize.Medium]: fontSize.xSmall,
  [AvatarSize.Large]: fontSize.small,
}

const shapeSizes = {
  medium: css`
    transform: none;
    left: 0;
    top: 0;
  `,
  large: css`
    transform: scale(1.25);
    left: ${GRID_UNIT / 2}px;
    top: ${GRID_UNIT / 2}px;
  `,
}

export const AvatarWrapper = styled.span`
  display: block;
  position: relative;
  width: min-content;
`

type TPictureWrapperProps = {
  size: AvatarSize
  backgroundColor?: string
}
export const PictureWrapper = styled.span<TPictureWrapperProps>`
  position: relative;
  width: ${({ size }) => size && pictureSizes[size]}px;
  height: ${({ size }) => size && pictureSizes[size]}px;
  border-radius: 50%;
  color: ${NeutralColors.White};
  background: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-transform: uppercase;
`

type TAvatarTextProps = {
  size: AvatarSize
}
export const AvatarText = styled.span<TAvatarTextProps>`
  font-size: ${({ size }) => size && textSizes[size]};
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.large};
  z-index: 1;
`

export const Gravatar = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 99;
`
type TShapeWrapperProps = {
  size: AvatarSize
}
export const ShapeWrapper = styled.svg<TShapeWrapperProps>`
  position: absolute;
  z-index: 0;
  ${({ size }) => size && shapeSizes[size]};
`

const skeletonAnimationKeyframe = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`
type TSkeletonProps = {
  size: AvatarSize
}
export const Skeleton = styled.div<TSkeletonProps>`
  width: ${({ size }) => size && pictureSizes[size]}px;
  height: ${({ size }) => size && pictureSizes[size]}px;
  border-radius: 50%;
  background: ${NeutralColors.Grey300};
  animation: 1.5s ease-in-out 0.5s infinite ${skeletonAnimationKeyframe};
`
