import { MouseEvent, memo } from 'react'

import {
  TMenuTriggerSvgProps,
  MobileMenuTriggerContainer,
  NoJsMenuTrigger,
  TriggerButton,
  TriggerSvg,
} from './mobile-menu-trigger.styles'

const HamburgerSvg = ({ isVisible, ...props }: TMenuTriggerSvgProps) => (
  <TriggerSvg isVisible={isVisible} {...props}>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M4 7h16M4 12.5h16M4 18h16'
    />
  </TriggerSvg>
)

const CloseSvg = ({ isVisible, ...props }: TMenuTriggerSvgProps) => (
  <TriggerSvg isVisible={isVisible} {...props}>
    <path
      d='M5 5L19 19M19 5L5 19'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
    />
  </TriggerSvg>
)

export type TMobileMenuTriggerProps = {
  isMobileMenuOpen: boolean
  openLabel: string
  closeLabel: string
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
}
const MobileMenuTrigger = ({
  isMobileMenuOpen,
  openLabel,
  closeLabel,
  ...buttonProps
}: TMobileMenuTriggerProps) => {
  return (
    <MobileMenuTriggerContainer data-testid='mobile-menu-trigger'>
      <NoJsMenuTrigger />
      <TriggerButton
        aria-expanded={isMobileMenuOpen}
        aria-label={isMobileMenuOpen ? closeLabel : openLabel}
        {...buttonProps}
      >
        <CloseSvg id='close-menu-svg' isVisible={isMobileMenuOpen} />
        <HamburgerSvg id='open-menu-svg' isVisible={!isMobileMenuOpen} />
      </TriggerButton>
    </MobileMenuTriggerContainer>
  )
}

export default memo(MobileMenuTrigger)
