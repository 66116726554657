import {
  ATTRIBUTION_ID_GLOBAL_KEY,
  DEFAULT_PROPERTY,
  SEARCH_PARAMS_ALLOWLIST,
} from 'constants/tracking'

import { useCallback, useEffect, useRef } from 'react'
import { useOptimizelyContext } from 'components/optimizely'
import { trackPageView } from 'services/tracking'
import { isAllowedHostname, getTypeformVersion } from 'utils/tracking'
import useCookieSettings from 'hooks/use-cookie-settings'
import { hasKeyPressed } from 'utils/event'
import { getAttributionUserId } from 'components/tracking'
import { useRollbar } from '@rollbar/react'

import { isNavigationTrackingDisabledForElement } from './helpers'

const useNavigationTrackingMiddleware = ({
  typeformProperty = DEFAULT_PROPERTY,
  userCountryCode,
} = {}) => {
  const rollbar = useRollbar()
  const { userId } = useOptimizelyContext()
  const { ready, areFunctionalCookiesAllowed } = useCookieSettings()
  const currentSearchParams = useRef([])
  const mutationObserver = useRef(null)
  const isPageViewTracked = useRef(false)
  const listenersMap = useRef(new Map())
  const shouldTrackPageView = ready && !areFunctionalCookiesAllowed

  const handleLinkClick = useCallback(
    link => event => {
      // If the user had pressed a key when they click on a link then we'll use native link behaviour
      if (hasKeyPressed(event)) {
        return
      }

      if (isNavigationTrackingDisabledForElement(link)) {
        return
      }

      const linkHref = link.href
      const targetHref = event?.target?.href
      const linkTarget = link?.target
      const targetTarget = event?.target?.target
      const href = linkHref || targetHref
      const anchorTarget = linkTarget || targetTarget

      try {
        // Links without an href attribute
        if (!linkHref) {
          return
        }

        // If the link is to an anchor on the same page, then we'll use native link behaviour
        if (linkHref.startsWith('#')) {
          return
        }

        const destinationUrl = new URL(href)

        // If the link goes outside of typeform.com then we'll use native link behaviour
        if (!isAllowedHostname(destinationUrl.hostname)) {
          return
        }

        event.preventDefault()

        currentSearchParams.current.forEach(([key, value]) => {
          destinationUrl.searchParams.set(key, value)
        })

        if (userId) {
          destinationUrl.searchParams.set(
            'optimizely_experiments_fingerprint',
            userId
          )
        }

        const attributionUserId = getAttributionUserId()
        if (attributionUserId) {
          destinationUrl.searchParams.set(
            ATTRIBUTION_ID_GLOBAL_KEY,
            attributionUserId
          )
        }

        const destinationUrlString = destinationUrl.toString()
        // If we have a target value, then use it, otherwise change navigate in the same window.
        if (anchorTarget) {
          return window.open(destinationUrlString, anchorTarget)
        }
        window.location = destinationUrlString
      } catch (e) {
        rollbar.warning(
          '[Navigation Tracking Middleware] Error while handling the link click',
          e
        )
      }
    },
    [rollbar, userId]
  )

  const addListeners = useCallback(() => {
    const links = document.querySelectorAll('a')

    links.forEach(link => {
      const listener = handleLinkClick(link)
      listenersMap.current.set(link, listener)
      link.addEventListener('click', listener)
    })
  }, [handleLinkClick])

  const removeListeners = useCallback(() => {
    const links = document.querySelectorAll('a')

    links.forEach(link => {
      const listener = listenersMap.current.get(link)
      link.removeEventListener('click', listener)
    })
  }, [])

  const storeSearchParams = useCallback(() => {
    const currentUrl = new URL(window.location.href)
    const entries = [...currentUrl.searchParams.entries()]

    currentSearchParams.current = entries.filter(([key]) => {
      return SEARCH_PARAMS_ALLOWLIST.includes(key)
    })
  }, [])

  const handleMutatedNode = useCallback(
    isRemoved => node => {
      if (node instanceof HTMLElement) {
        const links =
          node.nodeName === 'A' ? [node] : node.querySelectorAll('a')

        links.forEach(link => {
          const hasListener = listenersMap.current.has(link)

          if (!hasListener) {
            const listener = handleLinkClick(link)
            listenersMap.current.set(link, listener)
            link.addEventListener('click', listener)
          } else {
            if (isRemoved) {
              link.removeEventListener('click', listenersMap.current.get(link))
            }
          }
        })
      }
    },
    [handleLinkClick]
  )

  const addMutationObserver = useCallback(() => {
    mutationObserver.current = new MutationObserver(mutations => {
      mutations.forEach(mutationRecord => {
        if (mutationRecord.type === 'childList') {
          mutationRecord.addedNodes.forEach(handleMutatedNode(false))
          mutationRecord.removedNodes.forEach(handleMutatedNode(true))
        }
      })
    })

    mutationObserver.current.observe(document.body, {
      childList: true,
      subtree: true,
    })
  }, [handleMutatedNode])

  const removeMutationObserver = useCallback(() => {
    if (mutationObserver.current) {
      mutationObserver.current.disconnect()
    }

    mutationObserver.current = null
  }, [])

  const handlePageViewTracking = useCallback(async () => {
    await trackPageView(
      {
        typeformProperty,
        title: document.title,
        url: window.location.href,
        referrer: document.referrer,
        userAgent: navigator.userAgent,
        typeformVersion: getTypeformVersion(),
        attributionUserId: getAttributionUserId(),
        locale: navigator.language,
        country: userCountryCode,
      },
      rollbar
    )
  }, [rollbar, typeformProperty, userCountryCode])

  useEffect(() => {
    if (!isPageViewTracked.current && shouldTrackPageView) {
      isPageViewTracked.current = true

      handlePageViewTracking()
    }
  }, [handlePageViewTracking, shouldTrackPageView])

  useEffect(() => {
    storeSearchParams()
    addMutationObserver()
    addListeners()

    return () => {
      removeMutationObserver()
      removeListeners()
    }
  }, [
    addListeners,
    addMutationObserver,
    removeListeners,
    removeMutationObserver,
    storeSearchParams,
  ])
}

export default useNavigationTrackingMiddleware
