import { TYPEFORM_ROOT_DOMAIN } from 'constants/public-envs'
import { ADMIN_BASE_URL } from 'constants/url'

import { TPricingPlansCodes } from './types'

// ============== LINKS ==============
export const enterpriseCtaLinkBase = `https://tfsales.${TYPEFORM_ROOT_DOMAIN}/to/`
export const checkoutLink = `${ADMIN_BASE_URL}/checkout`
export const planDetailsLink = '/account#/section/plan'
export const workspacesLink = ADMIN_BASE_URL

// ============== THE REST OF STUFF ==============

export const billingPeriodCodes = {
  yearly: 'P1Y',
  monthly: 'P1M',
} as const

export const currencies = {
  eur: 'EUR',
  gbp: 'GBP',
  usd: 'USD',
} as const

export const pricingPlans = {
  pro: 'pro',
  proPlus: 'pro_plus',
  free: 'free',
  essentials: 'essentials',
  professional: 'professional',
  premium: 'premium',
  basic: 'basic',
  plus: 'plus',
  business: 'business',
  default: 'default',
  enterprise: 'enterprise_tier_1_self_serve',
  starter: 'v4.0_tier1_starter',
  standard: 'v4.0_tier2_standard',
  advanced: 'v4.0_tier3_advanced',
  growth_essentials: 'growth_essentials',
  growth_pro: 'growth_pro',
  growth_enterprise: 'growth_enterprise',
} as const

export const planNamesByPlanCode = {
  [pricingPlans.starter]: 'starter',
  [pricingPlans.standard]: 'standard',
  [pricingPlans.advanced]: 'advanced',
} as const

export const individualResponseAddonType = 'individual_response'

// Note that we explicitly include the plans to show here to ensure that, when the API is updated, new plans are not automatically shown.
// See https://typeform.atlassian.net/browse/WEB-6372
// To include free in the comparison table, simply add the `free` column here:
export const CORE_PLAN_NAMES: Array<TPricingPlansCodes> = [
  pricingPlans.basic,
  pricingPlans.plus,
  pricingPlans.business,
  pricingPlans.enterprise,
]

export const GROWTH_PLAN_NAMES: Array<TPricingPlansCodes> = [
  pricingPlans.growth_essentials,
  pricingPlans.growth_pro,
  pricingPlans.growth_enterprise,
]

export const PLANS_WITH_CUSTOM_PRICING: Array<TPricingPlansCodes> = [
  pricingPlans.enterprise,
  pricingPlans.growth_enterprise,
]

// Note that we explicitly include the plans to show here to ensure that, when the API is updated, new plans are not automatically shown.
// See https://typeform.atlassian.net/browse/WEB-6372
// To include free in the comparison table, simply add the `free` column here:
export const PLAN_NAMES_TO_SHOW_4_DOT_0 = [
  pricingPlans.starter,
  pricingPlans.standard,
  pricingPlans.advanced,
  pricingPlans.enterprise,
]

export const plansWithResponsesAddon = [
  pricingPlans.premium,
  pricingPlans.basic,
  pricingPlans.plus,
  pricingPlans.business,
]

export const translationBaseKeys = {
  features: 'pricing-page.features.',
  tooltip: 'pricing-page.tooltip.',
} as const

export const planNameQueryParam = 'plan-name'
