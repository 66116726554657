import { getImageVariantPath } from '../get-image-variant-path'
import { sizes as defaultSizes } from '../../constants'

export const getSrcSet = ({
  src,
  sizes = defaultSizes,
  overrideExtension,
}: {
  src: string
  sizes?: string[]
  overrideExtension?: string
}) => {
  return sizes
    .reduce((prev, size) => {
      const path = getImageVariantPath({ src, size, overrideExtension })
      if (!path) {
        return prev
      }
      return prev.concat(`${path} ${size}`)
    }, [] as string[])
    .join(', ')
}
