import { Palette } from '@typeform/ginger/dist/constants/palettes'
import { TTopMenuItemColumnItem } from 'models/top-menu-item-column-item-model.types'
import { TTopMenuItemColumn } from 'models/top-menu-item-column-model.types'

import { useMenuContext } from '../../mega-menu-provider'
import { TSubMenuItemClickEventHandler } from '../../mega-menu.types'

import {
  MenuGroup,
  MenuGroupList,
  MenuGroupTitle,
  MenuGroupTitleIcon,
  MenuLink,
  MenuLinkBody,
  MenuLinkDescription,
  MenuLinkIcon,
  MenuLinkIconContainer,
  MenuLinkImage,
  MenuListItem,
  MenuWithColumnsContainer,
  RevampedMenuLinkBodyLabel,
} from './sub-menu.styles'

const renderLabel = (
  { coverImage, description, label }: TTopMenuItemColumnItem,
  isMobileMenu: boolean = false
) => {
  if (!isMobileMenu && coverImage) {
    return (
      <>
        {description}
        <RevampedMenuLinkBodyLabel>
          {label} {'-->'}
        </RevampedMenuLinkBodyLabel>
      </>
    )
  }

  if (isMobileMenu && coverImage) {
    return (
      <RevampedMenuLinkBodyLabel>
        {label} {'-->'}
      </RevampedMenuLinkBodyLabel>
    )
  }

  return label
}

export type TSubMenuWithColumnsProps = {
  items: TTopMenuItemColumn[]
  palette: Palette
  onItemClick?: TSubMenuItemClickEventHandler
}
const SubMenuWithColumns = ({
  items,
  palette,
  onItemClick,
}: TSubMenuWithColumnsProps) => {
  const { isMobileMenu } = useMenuContext()

  return (
    <MenuWithColumnsContainer>
      {items.map(column => (
        <MenuGroup key={column.id} showColumnSeparator={column.columnSeparator}>
          <MenuGroupTitle
            palette={palette}
            hideOnMobile={column.hideLabelOnMobile}
          >
            {column.labelIcon?.url && (
              <MenuGroupTitleIcon
                src={column.labelIcon.url}
                alt={column.labelIcon.alt}
                width={16}
                height={16}
              />
            )}
            {column.label}
          </MenuGroupTitle>
          <MenuGroupList>
            {column?.items?.map(menuItem => (
              <MenuListItem key={menuItem.id}>
                <MenuLink
                  onClick={event => onItemClick?.({ event, item: menuItem })}
                  href={menuItem.link}
                  target={menuItem?.linkTarget}
                  hasImage={!!menuItem.coverImage && !isMobileMenu}
                >
                  <>
                    {!isMobileMenu && menuItem.coverImage && (
                      <MenuLinkImage
                        alt={menuItem.coverImage.alt}
                        role={menuItem.coverImage.role}
                        src={menuItem.coverImage.url}
                      />
                    )}
                    <MenuLinkIconContainer>
                      {menuItem.icon && (
                        <MenuLinkIcon
                          src={menuItem.icon.url}
                          alt={menuItem.icon.alt}
                          role={menuItem.icon.role}
                        />
                      )}
                      <MenuLinkBody>
                        {renderLabel(menuItem, isMobileMenu)}
                        {!menuItem.coverImage && menuItem.description && (
                          <MenuLinkDescription palette={palette}>
                            {menuItem.description}
                          </MenuLinkDescription>
                        )}
                      </MenuLinkBody>
                    </MenuLinkIconContainer>
                  </>
                </MenuLink>
              </MenuListItem>
            ))}
          </MenuGroupList>
        </MenuGroup>
      ))}
    </MenuWithColumnsContainer>
  )
}
export default SubMenuWithColumns
