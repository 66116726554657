import {
  CELLO_REFERRAL_INVITE_LP_HERO,
  HOMEPAGE_HEADER_SIGN_UP_WITH_FREE_COPY,
  INTEGRATIONS_INTENT_SURVEY,
  NOW_YOU_KNOW_EXPLORE,
  NO_SCROLL_PAID_HOMEPAGE,
  SIGNUP_CAPTCHA,
  SIGNUP_WIDGET,
} from 'constants/experiments'

import React from 'react'
import {
  OptimizelyExperiment,
  OptimizelyVariation,
} from 'components/optimizely'

function ClientSideExperimentTriggers() {
  // experiments that are implemented on server side and have to be activated on the client side
  return (
    <>
      <OptimizelyExperiment
        experimentId={HOMEPAGE_HEADER_SIGN_UP_WITH_FREE_COPY.ID}
      >
        <OptimizelyVariation
          variationId={
            HOMEPAGE_HEADER_SIGN_UP_WITH_FREE_COPY.VARIATIONS.CONTROL
          }
          default
        />
        <OptimizelyVariation
          variationId={
            HOMEPAGE_HEADER_SIGN_UP_WITH_FREE_COPY.VARIATIONS.VARIATION
          }
        />
      </OptimizelyExperiment>
      <OptimizelyExperiment experimentId={INTEGRATIONS_INTENT_SURVEY.ID}>
        <OptimizelyVariation
          variationId={INTEGRATIONS_INTENT_SURVEY.VARIATIONS.CONTROL}
          default
        />
        <OptimizelyVariation
          variationId={INTEGRATIONS_INTENT_SURVEY.VARIATIONS.VARIATION}
        />
      </OptimizelyExperiment>
      <OptimizelyExperiment experimentId={SIGNUP_CAPTCHA.ID}>
        <OptimizelyVariation
          variationId={SIGNUP_CAPTCHA.VARIATIONS.OFF}
          default
        />
        <OptimizelyVariation variationId={SIGNUP_CAPTCHA.VARIATIONS.ON} />
      </OptimizelyExperiment>
      <OptimizelyExperiment experimentId={CELLO_REFERRAL_INVITE_LP_HERO.ID}>
        <OptimizelyVariation
          variationId={CELLO_REFERRAL_INVITE_LP_HERO.VARIATIONS.OFF}
          default
        />
        <OptimizelyVariation
          variationId={CELLO_REFERRAL_INVITE_LP_HERO.VARIATIONS.ON}
        />
      </OptimizelyExperiment>
      <OptimizelyExperiment experimentId={NO_SCROLL_PAID_HOMEPAGE.ID}>
        <OptimizelyVariation
          variationId={NO_SCROLL_PAID_HOMEPAGE.VARIATIONS.OFF}
          default
        />
        <OptimizelyVariation
          variationId={NO_SCROLL_PAID_HOMEPAGE.VARIATIONS.TRY_SHORT_FORM}
        />
        <OptimizelyVariation
          variationId={NO_SCROLL_PAID_HOMEPAGE.VARIATIONS.LEGACY_HOMEPAGE}
        />
      </OptimizelyExperiment>
      <OptimizelyExperiment experimentId={SIGNUP_WIDGET.ID}>
        <OptimizelyVariation
          variationId={SIGNUP_WIDGET.VARIATIONS.CONTROL}
          default
        />
        <OptimizelyVariation variationId={SIGNUP_WIDGET.VARIATIONS.VARIATION} />
      </OptimizelyExperiment>
      <OptimizelyExperiment experimentId={NOW_YOU_KNOW_EXPLORE.ID}>
        <OptimizelyVariation
          variationId={NOW_YOU_KNOW_EXPLORE.VARIATIONS.CONTROL}
          default
        />
        <OptimizelyVariation
          variationId={NOW_YOU_KNOW_EXPLORE.VARIATIONS.VARIATION}
        />
      </OptimizelyExperiment>
    </>
  )
}

export default React.memo(ClientSideExperimentTriggers)
