import React, { useEffect, useRef } from 'react'
import {
  useOptimizelyContext,
  OptimizelyVariation,
} from 'components/optimizely'
import { TExperiment } from 'components/optimizely-provider-wrapper/optimizely-provider-wrapper'

type TOptimizelyExperiment = {
  children?: React.ReactNode[] | React.ReactNode
  experimentId: string
  onVariationDecided?: (experiment: TExperiment) => void
}

export const OptimizelyExperiment = ({
  children,
  experimentId,
  onVariationDecided,
}: TOptimizelyExperiment) => {
  validateChildren(children)

  const isExperimentActivated = useRef(false)
  const experimentToActivate = useRef<TExperiment>()

  const defaultVariation = React.Children.toArray(children).find(c => {
    if (React.isValidElement(c)) {
      return c?.props?.default
    }
    return false
  })

  if (!defaultVariation) {
    throw new Error(
      `OptimizelyExperiment (${experimentId}): a default OptimizelyVariation is required`
    )
  }

  const { experiments, experimentsToActivate, activateExperiment } =
    useOptimizelyContext()

  useEffect(() => {
    /* Experiments eligible for activation */
    if (experimentsToActivate) {
      if (!isExperimentActivated.current && experimentToActivate.current) {
        const { experimentId: expId, variationId } =
          experimentToActivate.current

        if (experimentsToActivate?.[expId]?.variationId === variationId) {
          isExperimentActivated.current = true

          activateExperiment(experimentToActivate.current)
        }
      }
    }
  }, [activateExperiment, experimentsToActivate])

  const experiment = experiments?.[experimentId] ?? null

  if (experiment) {
    const { variationId } = experiment

    const variation = React.Children.toArray(children).find(c => {
      if (React.isValidElement(c)) {
        return c?.props?.variationId === variationId
      }
      return false
    })

    if (variation) {
      onVariationDecided?.(experiment)

      if (!isExperimentActivated.current) {
        experimentToActivate.current = {
          ...experiment,
          experimentId,
        }
      }

      return variation
    }
  }

  return defaultVariation
}

function validateChild(child: React.ReactNode) {
  if (!React.isValidElement(child)) {
    // eslint-disable-next-line no-console
    console.warn('OptimizelyExperiment: children should be valid element.')
    return
  }
  if (child?.type !== OptimizelyVariation) {
    // eslint-disable-next-line no-console
    console.warn(
      'OptimizelyExperiment: children should be only of type OptimizelyVariation.'
    )
  }
}

function validateChildren(children: React.ReactNode[] | React.ReactNode) {
  React.Children.forEach(children, validateChild)
}

OptimizelyExperiment.displayName = 'OptimizelyExperiment'
