const PREVENT_LINK_HANDLING_ATTRIBUTE = 'data-prevent-link-handling'

export const disableNavigationTrackingForElement = (
  element: HTMLAnchorElement
) => {
  element.setAttribute(PREVENT_LINK_HANDLING_ATTRIBUTE, 'true')
}

export const isNavigationTrackingDisabledForElement = (
  element: HTMLAnchorElement
) => {
  return element.hasAttribute(PREVENT_LINK_HANDLING_ATTRIBUTE)
}
