import { defaultProps } from './constants'
import { ButtonElement, ButtonWrapper, ButtonHelperText } from './button.styles'
import { TButtonProps } from './types'

const Button = ({
  helperText,
  children,
  variant,
  hasMinWidth,
  size,
  href,
  className,
  fullWidth,
  palette,
  ...buttonElementProps
}: TButtonProps) => {
  const anchorOrButtonElement = href ? 'a' : 'button'

  return (
    <ButtonWrapper
      className={className}
      fullWidth={fullWidth}
      variant={variant}
      hasMinWidth={hasMinWidth}
      size={size}
    >
      <ButtonElement
        {...buttonElementProps}
        palette={palette}
        size={size}
        variant={variant}
        href={href}
        as={anchorOrButtonElement}
      >
        {children}
      </ButtonElement>
      {helperText && (
        <ButtonHelperText palette={palette}>{helperText}</ButtonHelperText>
      )}
    </ButtonWrapper>
  )
}

Button.defaultProps = defaultProps

export default Button
