import { memo, useMemo } from 'react'
import { ButtonSize } from 'components/atoms/button'
import { TUser } from 'services/user-service/types'

import UserDropdown from '../user-dropdown/user-dropdown'
import { TCtaData, TUserMenuItems } from '../../mega-menu.types'
import { useMenuContext } from '../../mega-menu-provider'
import { AuthLinks } from '../auth-links/auth-links'

import {
  HighlightedMobileLink,
  RightNavigationContainer,
} from './right-navigation.styles'

export type TRightNavigationProps = {
  isMinimalMenu?: boolean
  highlightedMobileLink?: TCtaData
  userMenuItems?: TUserMenuItems
  user?: TUser
  ctas: Array<TCtaData>
}

const RightNavigation = ({
  isMinimalMenu,
  userMenuItems,
  user,
  ctas,
  highlightedMobileLink,
}: TRightNavigationProps) => {
  const {
    isMenuScrolled,
    isMobileMenuOpen,
    isUserDropdownVisible,
    onUserMenuClick,
    onUserMenuItemClick,
    palette,
  } = useMenuContext()

  const shouldShowHighlightedLink = useMemo(() => {
    if (!highlightedMobileLink || !isMenuScrolled || isMobileMenuOpen) {
      return false
    }
    return isUserDropdownVisible ? !highlightedMobileLink.isSignupLink : true
  }, [
    isUserDropdownVisible,
    isMobileMenuOpen,
    highlightedMobileLink,
    isMenuScrolled,
  ])

  return (
    <RightNavigationContainer>
      {!isMinimalMenu && highlightedMobileLink && (
        <HighlightedMobileLink
          isVisible={shouldShowHighlightedLink}
          href={highlightedMobileLink.link}
          size={ButtonSize.Small}
          palette={palette}
        >
          {highlightedMobileLink.label}
        </HighlightedMobileLink>
      )}
      {isUserDropdownVisible ? (
        <UserDropdown
          menuItems={userMenuItems}
          user={user}
          onUserMenuClick={onUserMenuClick}
          onUserMenuItemClick={onUserMenuItemClick}
        />
      ) : (
        <AuthLinks
          isMinimalMenu={isMinimalMenu}
          ctas={ctas}
          palette={palette}
          isMobileMenuOpen={isMobileMenuOpen}
        />
      )}
    </RightNavigationContainer>
  )
}

export default memo(RightNavigation)
