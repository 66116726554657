import useTranslation from 'next-translate/useTranslation'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import { NAMESPACES } from '../../../constants/locales'
import {
  doesPasswordContainUsername,
  getPasswordEle,
  isPasswordValid,
} from '../dom-utils'
import OktaValidationError from '../okta-validation-error'

const PasswordValidation = ({ isWidgetRendered }) => {
  const { t } = useTranslation(NAMESPACES.AUTH)
  const [hasPatternError, setHasPatternError] = useState(false)
  const [containsUsername, setContainsUsername] = useState(false)
  const [portalContainer, setPortalContainer] = useState()

  useEffect(() => {
    if (isWidgetRendered && !portalContainer) {
      const passwordValidationPortalContainer = document.createElement('DIV')
      passwordValidationPortalContainer.setAttribute(
        'id',
        'custom-password-validation'
      )

      const portalWrapper = document.getElementById('subschemas-password')
      portalWrapper.after(passwordValidationPortalContainer)

      setPortalContainer(passwordValidationPortalContainer)
    }
  }, [isWidgetRendered, portalContainer])

  const Child = () => {
    const handleInput = useCallback(async () => {
      const passwordEle = getPasswordEle()

      if (passwordEle.value !== '') {
        setHasPatternError(!(await isPasswordValid()))
        setContainsUsername(await doesPasswordContainUsername())
      } else {
        setHasPatternError(false)
        setContainsUsername(false)
      }
    }, [])

    useEffect(() => {
      const passwordEle = getPasswordEle()

      passwordEle.addEventListener('input', handleInput)

      return () => {
        passwordEle.removeEventListener('input', handleInput)
      }
    }, [handleInput])

    return (
      <>
        {hasPatternError && (
          <OktaValidationError error={t('auth.signup.form.password.pattern')} />
        )}
        {containsUsername && (
          <OktaValidationError
            error={t('auth.signup.form.password.contains_username')}
          />
        )}
      </>
    )
  }

  return portalContainer ? createPortal(<Child />, portalContainer) : null
}

PasswordValidation.propTypes = {
  isWidgetRendered: PropTypes.bool.isRequired,
}

export default memo(PasswordValidation)
