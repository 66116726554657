import { NAMESPACES } from 'constants/locales'
import { TYPEFORM_ROOT_DOMAIN } from 'constants/public-envs'

import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { capitalize } from '@s-libs/micro-dash'
import { ButtonSize, ButtonVariant } from 'components/atoms/button'
import {
  trackExperimentEvent,
  trackSocialProviderSignup,
} from 'components/tracking'
import { getSocialSignupRedirectUrl } from 'components/okta-widget/helpers'

import { setSignupCookies } from '../../helpers'
import { TSocialProviderName } from '../constants'

import { getSocialIcon } from './helpers'
import { Button } from './styled-components'

function openUrlInPopup(
  url: string,
  providerName: TSocialProviderName,
  width: number,
  height: number
) {
  const left = Math.round((window.innerWidth - width) / 2)
  const top = Math.round((window.innerHeight - height) / 2)
  return window.open(
    url,
    `social-button-${providerName}-popup`,
    `left=${left},top=${top},width=${width},height=${height}`
  )
}

async function isUserLoggedIn(oktaDomain: string) {
  try {
    const response = await fetch(`${oktaDomain}/api/v1/sessions/me`, {
      credentials: 'include',
    })
    return response.status === 200
  } catch (err) {
    return false
  }
}

function getEmbedAdminRedirectUrl(appName: string) {
  return `https://embed-admin.${TYPEFORM_ROOT_DOMAIN}/?isEmbedAdmin=${appName}`
}

function waitForPopupAuthRedirect(
  popup: WindowProxy | null,
  oktaDomain: string,
  embedAdminAppName: string
) {
  const interval = setInterval(async () => {
    const popupClosed = !!popup?.closed
    if (popupClosed) {
      clearInterval(interval)
      clearTimeout(sessionTimeout)

      if (await isUserLoggedIn(oktaDomain)) {
        window.location.href = getEmbedAdminRedirectUrl(embedAdminAppName)
      }
    }
  }, 250)
  const sessionTimeout = setTimeout(() => {
    clearInterval(interval)
    popup?.close()
  }, 30 * 60_000)
}

type TSocialButtonProps = {
  providerName: TSocialProviderName
  idpId: string
  clientId: string
  oktaDomain: string
  state: string
  embedAdminAppName?: string | null
  device?: string | null
  icon?: string
}

const SocialButton = ({
  providerName,
  idpId,
  icon,
  clientId,
  oktaDomain,
  state,
  embedAdminAppName,
  device,
}: TSocialButtonProps) => {
  const { t } = useTranslation(NAMESPACES.AUTH)
  const openInPopup = !!embedAdminAppName
  const url = getSocialSignupRedirectUrl(idpId, clientId, oktaDomain, state)

  const handleOnClick = (event: React.MouseEvent) => {
    if (openInPopup) {
      event.preventDefault()
      const popup = openUrlInPopup(url, providerName, 500, 550)
      waitForPopupAuthRedirect(popup, oktaDomain, embedAdminAppName)
    }

    setSignupCookies({ provider: providerName, device })
    trackSocialProviderSignup(providerName)
    if (providerName === 'linkedin') {
      trackExperimentEvent({
        name: 'signup_with_linkedin',
        testId: 'AB_SignupWithLinkedin_WEB-7952',
      })
    }
  }

  return (
    <Button
      iconUrl={icon || getSocialIcon(providerName)}
      className={`social-auth-button link-button social-auth-${providerName}-button`}
      href={!openInPopup ? url : undefined}
      target='_self'
      onClick={handleOnClick}
      size={ButtonSize.Medium}
      variant={ButtonVariant.Secondary}
      data-qa={`${providerName}-signin-btn`}
      data-se={`social-auth-${providerName}-button`}
      data-testid='social-button'
    >
      {t('auth.social-button', {
        provider: capitalize(providerName),
      })}
    </Button>
  )
}

export default SocialButton
