import { useEnvContext } from 'components/env-context'
import React, { memo, useCallback, useMemo } from 'react'
import {
  generateState,
  setSignupCookies,
} from 'components/okta-signup-page/helpers'
import { trackSocialProviderSignup } from 'components/tracking'
import { Button, Flex } from '@typeform/echo-ui'
import { getSocialIcon } from 'components/okta-signup-page/social-buttons/social-button/helpers'
import { getSocialSignupRedirectUrl } from 'components/okta-widget/helpers'

import { SocialsButtonText } from '../auth.styles'
import { useAppContext } from 'components/app-context'

const ViaSocialsButtons = () => {
  const { device } = useAppContext()
  const oktaState = useMemo(() => generateState(), [])
  const {
    GOOGLE_SOCIAL_IDP_ID,
    MICROSOFT_SOCIAL_IDP_ID,
    OKTA_CLIENT_ID,
    OKTA_DOMAIN,
  } = useEnvContext()
  const handleClick = useCallback(
    (provider: string) => () => {
      trackSocialProviderSignup(provider)
      setSignupCookies({ provider: provider, device })
    },
    [device]
  )

  return (
    <Flex rowGap='12px' flexDirection='column'>
      <Button
        as='a'
        fullWidth
        kind='secondary'
        iconLeft={
          <img alt='Google logo' width={16} src={getSocialIcon('google')} />
        }
        // @ts-expect-error: Button cannot be used as a JSX component - given a @types/react version mismatch with @echo-ui
        href={getSocialSignupRedirectUrl(
          GOOGLE_SOCIAL_IDP_ID,
          OKTA_CLIENT_ID,
          OKTA_DOMAIN,
          oktaState
        )}
        onClick={handleClick('google')}
        size='large'
      >
        <SocialsButtonText>Sign up with Google</SocialsButtonText>
      </Button>
      <Button
        as='a'
        fullWidth
        kind='secondary'
        iconLeft={
          <img
            alt='Microsoft logo'
            width={16}
            src={getSocialIcon('microsoft')}
          />
        }
        // @ts-expect-error: Button cannot be used as a JSX component - given a @types/react version mismatch with @echo-ui
        href={getSocialSignupRedirectUrl(
          MICROSOFT_SOCIAL_IDP_ID,
          OKTA_CLIENT_ID,
          OKTA_DOMAIN,
          oktaState
        )}
        onClick={handleClick('microsoft')}
        size='large'
      >
        <SocialsButtonText>Sign up with Microsoft</SocialsButtonText>
      </Button>
    </Flex>
  )
}

export default memo(ViaSocialsButtons)
