import app from 'constants/app'

import { checkIgnore } from 'utils/rollbar'
import type { Configuration } from 'rollbar'
import { IS_DEV } from 'server/constants'
// TODO: Why do we have this and also have it in process.env?
const ROLLBAR_ACCESS_TOKEN = 'b29ee77439ba41d685176942bb88dd5b' // #gitleaks:allow

export const rollbarConfig: Configuration = {
  accessToken: ROLLBAR_ACCESS_TOKEN,
  // eslint-disable-next-line local-rules/no-disallowed-root-domains
  hostSafeList: ['typeform.com'],
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: IS_DEV ? 'development' : 'production',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: app.CODE_VERSION,
        guess_uncaught_frames: true,
      },
    },
  },
  checkIgnore,
}
