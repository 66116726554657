import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import { useInView } from 'react-intersection-observer'

import { StyledAnimatedContainer } from './animated-container.styles'
import { TStyledAnimatedContainer } from './animated-container.types'

const AnimatedContainer = forwardRef(
  (
    {
      externalRef,
      entryAnimations = [],
      runAnimations,
      runAnimationsOnPageLoad,
      entryConfig = {},
      ...rest
    }: TStyledAnimatedContainer,
    forwardedRef
  ) => {
    const elementRef = useRef(null)
    const refToWatch = externalRef || elementRef
    const didAnimationRunRef = useRef(false)

    const { triggerOnce } = entryConfig as { triggerOnce: boolean }

    const computedTriggerOnce =
      typeof triggerOnce === 'boolean' ? triggerOnce : true

    const hasRunAnimationsIsDefined = typeof runAnimations === 'boolean'

    const shouldSkipIntersectionObserver =
      hasRunAnimationsIsDefined || runAnimationsOnPageLoad

    useImperativeHandle(forwardedRef, () => elementRef.current)

    const [entryRef, inView] = useInView({
      threshold: 1,
      triggerOnce: computedTriggerOnce,
      skip: shouldSkipIntersectionObserver || entryConfig?.skip,
      ...entryConfig,
    })

    useEffect(() => {
      entryRef(refToWatch?.current as Element)
    }, [refToWatch, entryRef])

    const shouldRunEntryAnimations = useMemo(() => {
      if (runAnimationsOnPageLoad) {
        return false
      }

      if (!shouldSkipIntersectionObserver) {
        return inView
      }

      if (computedTriggerOnce && didAnimationRunRef.current) {
        return true
      }

      const didAnimationRun = runAnimations
      didAnimationRunRef.current = didAnimationRun || false

      return didAnimationRun
    }, [
      inView,
      computedTriggerOnce,
      runAnimationsOnPageLoad,
      runAnimations,
      shouldSkipIntersectionObserver,
    ])

    return (
      <StyledAnimatedContainer
        ref={elementRef}
        runEntryAnimations={shouldRunEntryAnimations}
        runAnimationsOnPageLoad={runAnimationsOnPageLoad}
        entryAnimations={entryAnimations}
        entryConfig={entryConfig}
        {...rest}
      />
    )
  }
)

export default AnimatedContainer
