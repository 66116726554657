import {
  TYPEFORM_COM_DEFAULT_ROOT_DOMAIN,
  VIDEOASK_COM_DEFAULT_ROOT_DOMAIN,
} from 'constants/root-domains'
import { ALL_LOCALES } from 'constants/prop-types'
import getTranslateFunction from 'util/translator/getTranslateFunction'

import { LANGUAGE_CODES } from 'lib/i18n'
import React, { useContext, useMemo, ReactNode } from 'react'

export const defaultContext = {
  locale: LANGUAGE_CODES.english,
  device: 'desktop',
  sharedVariables: {
    TYPEFORM_ROOT_DOMAIN: TYPEFORM_COM_DEFAULT_ROOT_DOMAIN,
    VIDEOASK_ROOT_DOMAIN: VIDEOASK_COM_DEFAULT_ROOT_DOMAIN,
  },
}

export const MainContext = React.createContext(defaultContext)

type TMainProviderProps = {
  locale: typeof ALL_LOCALES[keyof typeof ALL_LOCALES]
  children: ReactNode
  t?: Function
  device: string
  sharedVariables?: {
    TYPEFORM_ROOT_DOMAIN: string
    VIDEOASK_ROOT_DOMAIN: string
  }
}

export const MainProvider = ({
  locale = defaultContext.locale,
  children,
  t,
  device = defaultContext.device,
  sharedVariables = defaultContext.sharedVariables,
}: TMainProviderProps) => {
  const translatorFunction = t || getTranslateFunction(locale)
  const value = useMemo(() => {
    return {
      locale,
      t: (key: string, options: object, ...args: unknown[]) =>
        translatorFunction(key, { ...sharedVariables, ...options }, ...args),
      device,
      sharedVariables,
    }
  }, [locale, device, sharedVariables, translatorFunction])

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>
}

MainProvider.displayName = 'MainProvider'

export const useMainContext = () => {
  return useContext(MainContext)
}
