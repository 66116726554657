import { ADMIN_BASE_URL, LOGOUT_URL, SIGNUP_PATH } from 'constants/url'
import { TYPEFORM_ROOT_DOMAIN } from 'constants/public-envs'

import { getLocalizedHref } from '@typeform/ginger/dist/util/translator'
import { Translate } from 'next-translate'
import { getLinkWithPreservedQueryParams } from 'utils/link'
import { ButtonVariant } from 'components/atoms/button'
import { TCtaData } from 'components/organisms/mega-menu'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import type { TExperiment } from 'types/experiments'
import type { TTopMenu } from 'models/top-menu-model.types'
import type { TTopMenuItem } from 'models/top-menu-item-model.types'

import { TCtaOnClickFunctionType, TCustomCtaProps } from '../types'

export const flattenMenuItemsWithExperiments = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  menu: any,
  experimentsToActivate: Map<String, TExperiment> = new Map()
): [TTopMenu, Map<String, TExperiment>] => {
  if (!menu.hasOwnProperty('items')) {
    return [menu, experimentsToActivate]
  }

  const { items, ...otherMenuFields } = menu

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normalisedMenuItems: TTopMenuItem[] = items.map((item: any) => {
    if (item.activeExperiment) {
      experimentsToActivate.set(
        `${item.activeExperiment.id}_${item.activeExperiment.variationId}`,
        item.activeExperiment
      )
    }

    const [normalisedItem] = flattenMenuItemsWithExperiments(
      item?.variationEntry ? item.variationEntry : item,
      experimentsToActivate
    )

    return normalisedItem
  })

  const nonEmptyMenuItems = normalisedMenuItems.filter(item => !!item.label)

  return [
    {
      ...otherMenuFields,
      items: nonEmptyMenuItems,
    },
    experimentsToActivate,
  ]
}

export const getUserMenuItems = (t: Translate) => [
  {
    id: 'user_menu_dropdown_links',
    items: [
      {
        id: 'user_menu_dropdown_my_forms',
        label: t('megamenu.user-dropdown.my-forms.label'),
        link: ADMIN_BASE_URL,
        trackingName: 'workspace',
      },
    ],
  },
  {
    id: 'user_menu_dropdown_referral',
    items: [
      {
        id: 'user_menu_dropdown_referral_cello',
        label: 'Refer friends, get rewards',
        link: `${ADMIN_BASE_URL}?cello-open=true`,
        trackingName: 'cello_referral_widget',
      },
    ],
  },
  {
    id: 'user_menu_dropdown_actions',
    items: [
      {
        id: 'user_menu_dropdown_logout',
        label: t('megamenu.user-dropdown.logout.label'),
        destructive: true,
        link: LOGOUT_URL,
      },
    ],
  },
]

export const getPrimaryLink = ({
  t,
  queryParamsToPreserve,
  customCta,
  onSignUp,
  isWidgetSignUp = false,
}: {
  t: Translate
  queryParamsToPreserve: NextParsedUrlQuery
  customCta?: TCustomCtaProps
  onSignUp: TCtaOnClickFunctionType
  isWidgetSignUp?: boolean
}): TCtaData => {
  const { link: customLink, label, ...customProps } = customCta || {}

  const defaultSignupPath = `/${SIGNUP_PATH}/`

  const link =
    customLink ||
    getLocalizedHref('public-header.signup-cta-slug', t, defaultSignupPath)

  const isSignupLink = link.includes(defaultSignupPath)

  return {
    label: label || t('public-header.signup-cta-title'),
    link: isWidgetSignUp
      ? '' // Do nothing and stay on the same page if widget
      : getLinkWithPreservedQueryParams(link, queryParamsToPreserve),
    onClick: onSignUp,
    dataQa: 'signup-cta',
    isSignupLink,
    ...customProps,
  }
}
export const getCtas = ({
  t,
  onSignUp,
  onSignIn,
  customCta,
  queryParamsToPreserve,
  isMinimalMenu,
  isWidgetSignUp = false,
}: {
  t: Translate
  onSignUp: TCtaOnClickFunctionType
  onSignIn: TCtaOnClickFunctionType
  queryParamsToPreserve: NextParsedUrlQuery
  customCta?: TCustomCtaProps
  isMinimalMenu?: boolean
  isWidgetSignUp?: boolean
}): TCtaData[] => {
  const links = []

  if (!isMinimalMenu) {
    links.push({
      label: t('public-header.login-cta-title'),
      link: getLocalizedHref('public-header.login-cta-slug', t, `/login/`, {
        TYPEFORM_ROOT_DOMAIN,
      }),
      onClick: onSignIn,
      variant: ButtonVariant.Tertiary,
      dataQa: 'login-cta',
    })
  }

  links.push(
    getPrimaryLink({
      t,
      customCta,
      onSignUp,
      queryParamsToPreserve,
      isWidgetSignUp,
    })
  )

  return links
}
