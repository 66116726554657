import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocalStorage } from 'react-use'
import { useInView } from 'react-intersection-observer'
import ResizeObserver from 'resize-observer-polyfill'
import { DEFAULT_RENDER_DELAY } from 'components/molecules/banner/constants'
import { TBanner } from 'components/molecules/banner/banner.types'

import { getTargetedBanners } from './utils'
import { TUseBannerProps } from './banner-provider.types'
import { DEFAULT_AUDIENCES_CONFIG } from './constants'
export const BANNER_STORAGE_ID = 'dismissedBanners'

export const useBanner = (props: TUseBannerProps) => {
  const {
    banners = [],
    bannerEvents: getBannerEvents,
    audiencesConfig = DEFAULT_AUDIENCES_CONFIG,
    onShow,
  } = props

  const [bannerElement, setBannerElement] = useState<HTMLDivElement | null>(
    null
  )

  const setBannerRef = useCallback((node: HTMLDivElement) => {
    setBannerElement(node)
  }, [])

  const [hasShown, setHasShown] = useState(false)

  const [dismissedBanners = [], setDismissedBanners] = useLocalStorage<
    string[]
  >(BANNER_STORAGE_ID, [])

  const targetedBanners = useMemo(
    () => getTargetedBanners(banners, audiencesConfig),
    [banners, audiencesConfig]
  )

  const activeBanner = useActiveBanner(targetedBanners, dismissedBanners)

  const bannerHeight = useBannerHeight(activeBanner, bannerElement)
  const bannerEvents = useMemo(
    () => ({
      click: () => {},
      dismiss: () => {},
      show: () => {},
      ...(getBannerEvents?.(activeBanner) || {}),
    }),
    [activeBanner, getBannerEvents]
  )

  const cta = useMemo(
    () => getCta(activeBanner, bannerEvents?.click),
    [activeBanner, bannerEvents]
  )

  const handleDismiss = activeBanner
    ? () => {
        const bannerKey = getBannerKey(activeBanner)
        setDismissedBanners([...dismissedBanners, bannerKey])
        bannerEvents.dismiss()
      }
    : null

  useEffect(() => {
    if (!activeBanner || hasShown) return
    onShow && activeBanner && onShow(activeBanner)
    setHasShown(true)
    bannerEvents.show()
  }, [activeBanner, bannerEvents, hasShown, onShow])

  return {
    activeBanner,
    bannerHeight,
    setBannerRef,
    cta,
    handleDismiss,
    hasActiveBanner: Boolean(activeBanner),
    targetedBanners,
  }
}

const useBannerHeight = (
  activeBanner: TBanner | null,
  bannerElement: HTMLDivElement | null
) => {
  const ESTIMATED_BANNER_HEIGHT = 72

  const [bannerHeight, setBannerHeight] = useState(
    // If a non-dismissible banner is shown, then we initially set an estimated banner height
    // so that the mega-menu position is correct when the page is server-side rendered
    activeBanner ? ESTIMATED_BANNER_HEIGHT : 0
  )

  const [inViewRef, inView] = useInView({
    threshold: 1,
  })

  useEffect(() => {
    if (!bannerElement) {
      return
    }

    inViewRef(bannerElement)
  }, [inViewRef, bannerElement])

  useEffect(() => {
    if (!bannerElement) {
      return
    }

    const resizeObserver = new ResizeObserver(entries => {
      const isEntriesIterable = Symbol.iterator in Object(entries)

      if (!isEntriesIterable) {
        return
      }

      let newBannerHeight
      for (const entry of entries) {
        newBannerHeight =
          entry.borderBoxSize?.length > 0
            ? entry.borderBoxSize[0].blockSize
            : entry.contentRect.height
      }

      setBannerHeight(newBannerHeight || 0)
    })

    resizeObserver.observe(bannerElement)

    return () => {
      resizeObserver.unobserve(bannerElement)
    }
  }, [bannerElement])

  if (!activeBanner || !inView) return 0

  return bannerHeight
}

const useActiveBanner = (banners: TBanner[], dismissedBanners: string[]) => {
  const bannersNotDismissible = banners.every(
    banner => banner.dismissible === false
  )
  const initialBanner: TBanner | null = bannersNotDismissible
    ? getActiveBanner(banners, dismissedBanners) || null
    : null

  const delayedBanners = useRef<string[]>([])
  const [activeBanner, setActiveBanner] = useState<TBanner | null>(
    initialBanner
  )

  const setBanner = useCallback(
    (
      banners: TBanner[],
      dismissedBanners: string[],
      delayedBanners: MutableRefObject<string[]>
    ) => {
      const banner = getActiveBanner(banners, dismissedBanners)
      if (banner) {
        const bannerKey = getBannerKey(banner)
        const { renderDelay = DEFAULT_RENDER_DELAY } = banner

        // Banner appearance already delayed
        if (delayedBanners.current.includes(bannerKey)) return

        // Banner appearance needs to be delayed
        if (renderDelay > 0 && !delayedBanners.current.includes(bannerKey)) {
          delayedBanners.current.push(bannerKey)

          setTimeout(() => {
            setActiveBanner(banner)
          }, renderDelay * 1000)

          setActiveBanner(null)
          return
        }

        setActiveBanner(banner)
        return
      }

      setActiveBanner(null)
    },
    []
  )

  // This useEffect ensures that we only set the active banner on the client.
  // dismissedBanners is only accurate client-side.
  useEffect(() => {
    setBanner(banners, dismissedBanners, delayedBanners)
  }, [banners, dismissedBanners, delayedBanners, setBanner])

  return activeBanner
}

const getActiveBanner = (banners: TBanner[], dismissedBanners: string[]) => {
  return banners.find(banner => {
    const bannerKey = getBannerKey(banner)

    return !dismissedBanners.includes(bannerKey)
  })
}

const getCta = (activeBanner: TBanner | null, onClick: () => void) => {
  if (!activeBanner) return
  return {
    ...activeBanner.cta,
    onClick,
  }
}

const getBannerKey = (banner: TBanner) => {
  return (banner.name || '').toLowerCase().replace(' ', '-')
}
