import React from 'react'

export const reportAccessibility = async (
  config?: Record<string, unknown>
): Promise<void> => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
    const axe = (await import('@axe-core/react')).default
    const ReactDOM = await import('react-dom')

    await axe(React, ReactDOM, 1000, config)
  }
}
