import {
  IMAGE_DECODING,
  IMAGE_FETCHPRIORITY,
  IMAGE_LOADING,
} from '@typeform/ginger/dist/constants/image'
import { ImgHTMLAttributes } from 'react'
import Head from 'next/head'

export type TOptimizedImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  aboveTheFold?: boolean
}

const OptimizedImage = ({ aboveTheFold, ...props }: TOptimizedImageProps) => {
  return (
    <>
      {aboveTheFold && (
        <Head>
          <link
            rel='preload'
            as='image'
            href={props.src}
            imageSrcSet={props.srcSet}
            imageSizes={props.sizes}
            // @ts-expect-error fetchpriority is not yet defined in HTMLLinkElement
            fetchpriority={IMAGE_FETCHPRIORITY.high}
          />
        </Head>
      )}
      <img
        alt=''
        // @ts-expect-error fetchpriority is not yet defined in HTMLLinkElement
        fetchpriority={
          aboveTheFold ? IMAGE_FETCHPRIORITY.high : IMAGE_FETCHPRIORITY.low
        }
        loading={aboveTheFold ? IMAGE_LOADING.eager : IMAGE_LOADING.lazy}
        decoding={aboveTheFold ? IMAGE_DECODING.default : IMAGE_DECODING.async}
        {...props}
      />
    </>
  )
}

export default OptimizedImage
