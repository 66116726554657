export const JS_CLASSNAME = 'js'
export const NO_JS_CLASSNAME = 'no-js'

export const Axis = {
  X: 'x',
  Y: 'y',
}

export const Origin = {
  Left: 'left',
  Center: 'center',
  Right: 'right',
  Top: 'top',
  Bottom: 'bottom',
}
