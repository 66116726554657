import {
  DEVICE_HEADER,
  LANGUAGE_HEADER,
  USER_ID_HEADER,
  VISITOR_AUTHENTICATED_HEADER,
  VISITOR_REGISTERED_HEADER,
  VISITOR_TYPE_HEADER,
} from 'constants/headers'
import { DEFAULT_COUNTRY_CODE } from 'constants/server'

import { isNil } from '@s-libs/micro-dash'
import OptimizelyService from '@typeform/ginger/dist/services/optimizely-service'
import cookies from 'utils/cookies'
import { OPTIMIZELY_USER_COOKIE_ID } from 'components/optimizely'

import {
  FORCED_COUNTRY_CODE_HEADER,
  USER_COUNTRY_CODE_HEADER,
} from '../server/constants'

import { getQueryString, removeQueryString } from './url'

export const getOptimizelyUserId = () => cookies.get(OPTIMIZELY_USER_COOKIE_ID)

export const getActiveExperimentsOptimizelyAttribute = (experiments = {}) => {
  const values = Object.values(experiments)

  if (!values.length) {
    return null
  }

  return values
    .map(
      ({ experimentKey, variationKey }) => `${experimentKey}:${variationKey}`
    )
    .join(' ')
}

export const getActiveExperimentVariation = ({
  experiments = {},
  experimentId = '',
}) => {
  return experiments[experimentId]?.variationId
}

export const isExperimentVariationActive = ({
  experiments = {},
  experimentId = '',
  variationId = '',
} = {}) => {
  return (
    getActiveExperimentVariation({ experiments, experimentId }) === variationId
  )
}

export const getExperimentsFromMiddlewareRequest = request => {
  const url = new URL(request.url)
  const forcedExperiments = url.searchParams.get('optimizely-experiments')

  if (forcedExperiments) {
    return OptimizelyService.getForcedExperiment(forcedExperiments)
  }

  const experimentsHeader = request.headers.get(
    OptimizelyService.OPTIMIZELY_EXPERIMENTS_HEADER
  )

  if (experimentsHeader) {
    return OptimizelyService.normaliseExperiments(experimentsHeader)
  }

  return {}
}

export const getPartOfDay = () => {
  const hour = new Date().getHours()

  if (hour >= 5 && hour < 12) {
    return 'morning'
  }
  if (hour >= 12 && hour < 17) {
    return 'afternoon'
  }
  if (hour >= 17 && hour < 21) {
    return 'evening'
  }

  return 'night'
}

export const getUTMParams = fullUrl => {
  let urlParams

  if (fullUrl) {
    urlParams = new URL(fullUrl).searchParams
  } else if (typeof window !== 'undefined' && window?.location?.search) {
    urlParams = new URLSearchParams(window.location.search.toLowerCase())
  } else {
    return {}
  }

  const UTMParams = {
    source: urlParams.get('tf_source') || urlParams.get('utm_source'),
    medium: urlParams.get('tf_medium') || urlParams.get('utm_medium'),
    campaign: urlParams.get('tf_campaign') || urlParams.get('utm_campaign'),
    content: urlParams.get('tf_content') || urlParams.get('utm_content'),
    term: urlParams.get('tf_term') || urlParams.get('utm_term'),
  }

  return Object.fromEntries(
    Object.entries(UTMParams).filter(([, value]) => {
      return !isNil(value)
    })
  )
}

export const getOptimizelyAttributes = ({
  device,
  currentUrl,
  language,
  visitorType,
  experiments,
  isVisitorAuthenticated,
  isVisitorRegistered,
  fullUrl,
  userCountryCode,
}) => {
  const UTMParams = getUTMParams(fullUrl)

  return [
    {
      entity_id: '20114386141',
      key: 'Device',
      type: 'custom',
      value: device,
    },
    {
      entity_id: '20169754333',
      key: 'Url',
      type: 'custom',
      value: removeQueryString(currentUrl),
    },
    {
      entity_id: '20592680141',
      key: 'Language',
      type: 'custom',
      value: language,
    },
    {
      entity_id: '20717011455',
      key: 'PartOfDay',
      type: 'custom',
      value: getPartOfDay(),
    },
    {
      entity_id: '20771230718',
      key: 'VisitorType',
      type: 'custom',
      value: visitorType,
    },
    {
      entity_id: '21965630255',
      key: 'VisitorIsAuthenticated',
      type: 'custom',
      value: isVisitorAuthenticated,
    },
    {
      entity_id: '21980090575',
      key: 'VisitorHasAccount',
      type: 'custom',
      value: isVisitorRegistered,
    },
    {
      entity_id: '20688331554',
      key: 'Term',
      type: 'custom',
      value: UTMParams['term'],
    },
    {
      entity_id: '20688892106',
      key: 'Medium',
      type: 'custom',
      value: UTMParams['medium'],
    },
    {
      entity_id: '20704740719',
      key: 'Source',
      type: 'custom',
      value: UTMParams['source'],
    },
    {
      entity_id: '20722270372',
      key: 'Content',
      type: 'custom',
      value: UTMParams['content'],
    },
    {
      entity_id: '21280500594',
      key: 'QueryString',
      type: 'custom',
      value: getQueryString(currentUrl),
    },
    {
      entity_id: '20745060775',
      key: 'Campaign',
      type: 'custom',
      value: UTMParams['campaign'],
    },
    {
      entity_id: '21213810715',
      key: 'ActiveExperiments',
      type: 'custom',
      value: getActiveExperimentsOptimizelyAttribute(experiments),
    },
    {
      entity_id: '25075600611',
      key: 'UserCountryCode',
      type: 'custom',
      value: userCountryCode,
    },
  ].filter(attribute => !isNil(attribute.value))
}

export const activateExperimentFromMiddlewareRequest = (
  request,
  experimentId,
  experiments
) => {
  if (!request || !experimentId) {
    return
  }

  const computedExperiments =
    experiments || getExperimentsFromMiddlewareRequest(request)

  if (!computedExperiments) {
    return
  }

  const attributes = getOptimizelyAttributes({
    device: request.headers.get(DEVICE_HEADER),
    currentUrl: request.url,
    fullUrl: request.url,
    language: request.headers.get(LANGUAGE_HEADER),
    visitorType: request.headers.get(VISITOR_TYPE_HEADER),
    experiments: computedExperiments,
    isVisitorAuthenticated: request.headers.get(VISITOR_AUTHENTICATED_HEADER),
    isVisitorRegistered: request.headers.get(VISITOR_REGISTERED_HEADER),
    userCountryCode:
      request.headers.get(FORCED_COUNTRY_CODE_HEADER) ||
      request.headers.get(USER_COUNTRY_CODE_HEADER) ||
      DEFAULT_COUNTRY_CODE,
  })

  const experimentToActivate = computedExperiments[experimentId]

  if (!experimentToActivate) {
    return
  }

  const { campaignId, variationId } = experimentToActivate

  OptimizelyService.activateExperiment({
    userId: request.headers.get(USER_ID_HEADER),
    campaignId,
    experimentId,
    variationId,
    attributes,
  })
}
