import React, { ReactNode } from 'react'
import Button, { ButtonSize, ButtonVariant } from 'components/atoms/button'

type TEmailSignupButtonProps = {
  handleClick: () => void
  openInNewTab: boolean
  children: ReactNode
}

export const EmailSignupButton = ({
  handleClick,
  openInNewTab,
  children,
}: TEmailSignupButtonProps) => {
  const buttonProps = {
    'contentColor': 'white',
    'data-gtm-event': 'cta_button',
    'data-qa': 'user-friendly-cta',
    'size': ButtonSize.Medium,
    'variant': ButtonVariant.Primary,
  }

  if (openInNewTab) {
    return (
      <Button {...buttonProps} href={'/signup#email'} target={'_blank'}>
        {children}
      </Button>
    )
  }

  return (
    <Button {...buttonProps} onClick={handleClick}>
      {children}
    </Button>
  )
}
