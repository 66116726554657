import {
  DAYS_TO_REMEMBER_FEEDBACK_FORM_RESPONSE,
  FEEDBACK_FORM_RESPONSE_TIME_STORAGE_KEY,
  MILLISECONDS_IN_A_DAY,
} from './floating-feedback-form.constants'

export const setFeedbackResponseTime = () => {
  localStorage.setItem(
    FEEDBACK_FORM_RESPONSE_TIME_STORAGE_KEY,
    new Date().getTime().toString()
  )
}

export const hasFeedbackFormResponse = () => {
  const feedbackFormResponseTime = localStorage.getItem(
    FEEDBACK_FORM_RESPONSE_TIME_STORAGE_KEY
  )

  if (!feedbackFormResponseTime) {
    return false
  }

  const today = new Date().getTime()
  const milliSecondsToToday = Math.abs(today - Number(feedbackFormResponseTime))
  const daysToToday = Math.ceil(milliSecondsToToday / MILLISECONDS_IN_A_DAY)

  if (daysToToday <= DAYS_TO_REMEMBER_FEEDBACK_FORM_RESPONSE) {
    return true
  }

  localStorage.removeItem(FEEDBACK_FORM_RESPONSE_TIME_STORAGE_KEY)
  return false
}
