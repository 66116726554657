export const NAVIGATION_LINKS_KEYS = {
  TYPEFORM_PRODUCT: 'typeform-product',
  VIDEOASK: 'videoask-product',
  FORM_TEMPLATES: 'form-templates',
  SURVEY_TEMPLATES: 'survey-templates',
  ALL_TEMPLATES: 'all-templates',
  SLACK_INTEGRATION: 'slack-integration',
  ZAPIER_INTEGRATION: 'zapier-integration',
  GOOGLE_SHEETS_INTEGRATION: 'google-sheet-integration',
  AIRTABLE_INTEGRATION: 'airtable-integration',
  MAILCHIMP_INTEGRATION: 'mailchimp-integration',
  GOOGLE_ANALYTICS_INTEGRATION: 'google-analytics-integration',
  KLAVIYO_INTEGRATION: 'klaviyo-integration',
  WORDPRESS_INTEGRATION: 'wordpress-integration',
  PIPEDRIVE_INTEGRATION: 'pipedrive-integration',
  GOOGLE_CALENDAR_INTEGRATION: 'google-calendar-integration',
  CANVA_INTEGRATION: 'canva-integration',
  ZOHO_CRM_INTEGRATION: 'zoho-crm-integration',
  OFFICE_365_INTEGRATION: 'office-365-integration',
  ZENDESK_INTEGRATION: 'zendesk-integration',
  ANALYTICS_REPORTING_INTEGRATION: 'analytics-reporting-integration',
  SALES_AND_CRM_INTEGRATION: 'sales-and-crm-integration',
  PAYMENTS_INTEGRATION: 'payments-integration',
  SCHEDULING_INTEGRATION: 'scheduling-integration',
  EMAIL_MARKETING_INTEGRATION: 'email-marketing-integration',
  CUSTOMER_SUPPORT_INTEGRATION: 'customer-support-integration',
  AUTOMATION_INTEGRATION: 'automation-integration',
  DOCUMENTS_INTEGRATION: 'documents-integration',
  REWARDS_INTEGRATION: 'rewards-integration',
  COLLABORATION_INTEGRATION: 'collaboration-integration',
  ALL_INTEGRATIONS: 'all-integrations',
  HELP_CENTER: 'help-center',
  ONLINE_COMMUNITY: 'online-community',
  BLOG: 'blog',
  PRICING: 'pricing',
  CAREERS: 'careers',
  PROFESSIONAL_FEATURES: 'enterprise',
  LEAD_GENERATION: 'lead-generation',
  QUIZ_TEMPLATES: 'quiz-templates',
  POLL_TEMPLATES: 'poll-templates',
  INTERACTIVE_FICTION_TEMPLATE: 'navigation-fiction-template',
  TRIVIA_TEMPLATE: 'trivia-template',
  EMPLOYEE_JOB_APPLICATION_TEMPLATE: 'employee-job-application-template',
  EVENT_REGISTRATION_TEMPLATE: 'event-registration-template',
  POLL_TEMPLATE: 'poll-template',
  LEAD_GEN_FORMS_TEMPLATES: 'lead-gen-forms-templates',
  LEAD_GEN_QUIZZES_TEMPLATES: 'lead-gen-quizzes-templates',
  EVALUATION_FORMS_TEMPLATES: 'evaluations-forms-templates',
  APPLICATION_FORMS_TEMPLATES: 'application-forms-templates',
  OTHER_FORMS_TEMPLATES: 'other-forms-templates',
  REGISTRATION_FORMS_TEMPLATES: 'registration-forms-templates',
  FEEDBACK_FORMS_TEMPLATES: 'feedback-forms-templates',
  ALL_FORMS_TEMPLATES: 'all-forms-templates',
  FEEDBACK_SURVEYS_TEMPLATES: 'feedback-surveys-templates',
  SATISFACTION_SURVEYS_TEMPLATES: 'satisfaction-surveys-templates',
  HUBSPOT_INTEGRATION: 'hubspot-integration',
  FACEBOOK_PIXEL_INTEGRATION: 'facebook-pixel-integration',
  TUTORIALS: 'tutorials',
  FAQS: 'faqs',
  AGENCIES: 'agencies',
  REFERRAL_PROGRAM: 'referral-program',
  PARTNERS: 'partners',
  SYS_STATUS: 'sys-status',
  DEVELOPERS: 'developers',
  CONTACT_US: 'contact-us',
  NEWS: 'news',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  FILE_UPLOAD: 'file-upload',
  JOB_APPLICATION: 'job-application',
  APPLICATION: 'application',
  EVENT_FORMS: 'event-forms',
  EDUCATION: 'education',
  PHOTO_UPLOAD: 'photo-upload',
  CUSTOMER_SATISFACTION: 'customer-satisfaction',
  WEBSITE_QUESTIONNAIRE: 'website-questionnaire',
  BETA_PRODUCT: 'beta-product',
  PRE_ORDER_FORM: 'preorder-form',
  LEAD_GENERATION_FORMS: 'lead-generation-forms',
  LEAD_GENERATION_QUIZZES: 'lead-generation-quizzes',
  REGISTRATION: 'registration',
  CUSTOMER_SUCCESS_FORMS: 'customer-success-forms',
  STARTUPS: 'startups',
  GET_TO_KNOW_US: 'get_to_know_us',
  ABOUT_US: 'about-us',
  BRAND: 'brand',
  NEWSLETTER: 'newsletter',
  ENTERPRISE_OVERVIEW: 'enterprise-overview',
  ENTERPRISE_HEALTHCARE: 'enterprise-healthcare',
  TERMS_AND_CONDITIONS_FILE: 'terms-and-conditions-file',
  MODERN_SLAVERY_STATEMENT_FILE: 'modern-slavery-statement-file',
  PARTNERS_AGENCY: 'partners-agency',
  PARTNERS_TECHNOLOGY: 'partners-technology',
  AGENCY_DIRECTORY: 'agency-directory',
}

export const SOCIAL_LINKS = 'social-links'
