import { ENABLE_WDYR } from 'constants/public-envs'

import React from 'react'

if (ENABLE_WDYR) {
  if (process.env.NODE_ENV === 'development') {
    if (typeof window !== 'undefined') {
      const whyDidYouRender = require('@welldone-software/why-did-you-render')
      whyDidYouRender(React, {
        trackAllPureComponents: true,
      })
    }
  }
}
