import React from 'react'
import PropTypes from 'prop-types'

import {
  Label,
  RadioContainer,
  RadioDescription,
  RadioInput,
} from './radio.styles'

const Radio = ({
  style,
  children,
  name,
  onChange,
  onClick,
  value,
  checked,
  disabled,
  ...props
}) => {
  const inputProps = {
    name,
    onChange,
    onClick,
    value,
    checked,
    disabled,
    ...props,
  }

  return (
    <RadioContainer style={style}>
      <Label disabled={disabled}>
        <RadioInput {...inputProps} type='radio' />
        {children && <RadioDescription>{children}</RadioDescription>}
      </Label>
    </RadioContainer>
  )
}

Radio.propTypes = {
  'aria-label': props => {
    if (process.env.NODE_ENV === 'production') {
      return
    }

    if (!props.children && !props['aria-label'] && !props['aria-labelledby']) {
      throw new Error(
        'Must provide one of `children`, `aria-label` or `aria-labelledby`.'
      )
    }
  },
  'aria-labelledby': PropTypes.string,
  'children': PropTypes.node,
  'style': PropTypes.object,
  'checked': PropTypes.bool,
  'disabled': PropTypes.bool,
  'value': PropTypes.any,
  'name': PropTypes.string,
  'onClick': PropTypes.func,
  'onChange': PropTypes.func,
}

Radio.defaultProps = {
  'aria-label': '',
  'aria-labelledby': '',
  'checked': false,
  'children': '',
  'disabled': false,
  'name': null,
  'onChange': () => null,
  'onClick': () => null,
  'style': null,
  'value': '',
}

Radio.displayName = 'Radio'

export default Radio
