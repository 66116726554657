import styled from 'styled-components'
import { getStaticAsset } from 'utils/assets'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { fontSize } from '@typeform/ginger/dist/constants/typography'

import Radio from './radio'

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  margin: 0;
  appearance: none;
  box-shadow: inset 0 0 0 1px
    ${({ checked }) => (checked ? NeutralColors.Ink : NeutralColors.Grey300)};
  border-radius: 3px;
  transition: background-color 0.15s ease;

  :focus {
    outline: none;
    box-shadow: 0 0 0 1px ${NeutralColors.White},
      0 0 0 3px rgba(38, 38, 39, 0.4);
  }

  :checked {
    background-color: ${NeutralColors.Ink};
  }

  :checked:before {
    content: '';
    color: ${NeutralColors.White};
    background: url(${getStaticAsset('signup-page/check-white.svg')}) no-repeat
      50% 4px;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    position: absolute;
  }
`

const Label = styled.label`
  font-size: ${fontSize.small};
  line-height: 24px;
  color: ${NeutralColors.Ink};
`

export const CheckboxLabel = styled(Label)`
  display: block;
  font-size: ${fontSize.xSmall};
  line-height: 18px;
  margin-bottom: 16px;
  padding-left: 30px;
  position: relative;

  a {
    text-decoration: underline;
    color: ${NeutralColors.Ink};
  }
`
export const RadioToggleTitle = styled.div`
  font-size: ${fontSize.xSmall};
  margin-bottom: 8px;
`

export const RadioToggleLabel = styled.label`
  color: ${NeutralColors.Ink};
  line-height: 20px;
  margin: 0;

  input {
    margin: 0;
  }
`
export const RadioToggleLink = styled.a`
  color: ${NeutralColors.Grey700};
  text-decoration: none;

  &::before {
    content: ' ';
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

export const RadioToggleOption = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 12px;

  &:first-child {
    margin-right: 1rem;
  }
`

export const SmallText = styled.p`
  font-size: ${fontSize.xSmall};
  color: ${({ color }) => color || 'inherit'};
  margin: 0 ${({ gap }) => (gap ? `${16 * gap}px` : 0)} 0 0;
  padding-top: 2px;
`

export const StyledRadio = styled(Radio)`
  label {
    align-items: end;

    input {
      margin: 0;
    }

    div {
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
  }
`
