/* eslint-disable no-console */
const { v4: uuidv4 } = require('uuid')

class OptimizelyService {
  static ENDPOINT = 'https://logx.optimizely.com/v1/events'
  static ACCOUNT_ID = '15381830540'
  static OPTIMIZELY_RAW_EXPERIMENTS_HEADER = 'x-experiments-raw'
  static OPTIMIZELY_EXPERIMENTS_HEADER = 'x-experiments'

  static async genericRequestHandler(request, { onError = () => {} } = {}) {
    try {
      return await request()
    } catch (error) {
      onError(error)
      return { ok: false, error }
    }
  }

  static async activateExperiment({
    userId,
    campaignId,
    experimentId,
    variationId,
    attributes = [],
  }) {
    return this.genericRequestHandler(async () => {
      const result = await fetch(
        this.ENDPOINT,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            account_id: this.ACCOUNT_ID,
            visitors: [
              {
                visitor_id: userId,
                attributes,
                snapshots: [
                  {
                    decisions: [
                      {
                        campaign_id: campaignId,
                        experiment_id: experimentId,
                        variation_id: variationId,
                      },
                    ],
                    events: [
                      {
                        entity_id: campaignId,
                        type: 'campaign_activated',
                        timestamp: Date.now(),
                        uuid: uuidv4(),
                      },
                    ],
                  },
                ],
              },
            ],
            enrich_decisions: true,
          }),
        },
        {
          onError: error => {
            console.warn(
              `[Optimizely] Failed to activate experiment ${experimentId} with variation ${variationId} for user ${userId}. Status: ${result.status}`
            )
            console.error(error)
          },
        }
      )

      return { ok: result.ok }
    })
  }

  static async trackEvent({ userId, eventId, attributes = [] }) {
    return this.genericRequestHandler(async () => {
      const result = await fetch(this.ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          account_id: this.ACCOUNT_ID,
          visitors: [
            {
              visitor_id: userId,
              attributes,
              snapshots: [
                {
                  decisions: [],
                  events: [
                    {
                      entity_id: eventId,
                      timestamp: Date.now(),
                      uuid: uuidv4(),
                    },
                  ],
                },
              ],
            },
          ],
          enrich_decisions: true,
        }),
      })

      return result.ok
    })
  }

  /**
   * @param {string} [experimentsString] - <campaign_id>:<experiment_id>:<variation_id>:<experiment_key>:<variation_key>[-<campaign_id>:<experiment_id>:<variation_id>:<experiment_key>:<variation_key>]* **/
  static normaliseExperiments(experimentsString = '') {
    const regex =
      /^([0-9]+:[0-9]+:[0-9]+(:\w+)?(:\w+)?)(-[0-9]+:[0-9]+:[0-9]+(:\w+)?(:\w+)?)*$/

    if (!regex.test(experimentsString)) {
      // eslint-disable-next-line no-console
      console.warn(
        `[OptimizelyService.normaliseExperiments] The experiments string doesn't match the expected pattern: <campaign_id>:<experiment_id>:<variation_id>:<experiment_key>:<variation_key>[-<campaign_id>:<experiment_id>:<variation_id>:<experiment_key>:<variation_key>]* Received: ${experimentsString}`
      )

      return {}
    }

    const experimentsArray = experimentsString.split('-')

    return experimentsArray.reduce((accum, current) => {
      const [
        campaignId,
        experimentId,
        variationId,
        experimentKey = null,
        variationKey = null,
      ] = current.split(':')

      return {
        ...accum,
        [experimentId]: {
          variationId,
          campaignId,
          experimentKey,
          variationKey,
        },
      }
    }, {})
  }

  static getForcedExperiment(experimentQuery = '') {
    return experimentQuery
      .split('-')
      .map(experiment => experiment.split(':'))
      .filter(experimentValues => experimentValues.every(Boolean))
      .reduce(
        (
          prev,
          [campaignId, experimentId, variationId, experimentKey, variationKey]
        ) => ({
          ...prev,
          [experimentId]: {
            variationId,
            campaignId,
            experimentKey,
            variationKey,
          },
        }),
        {}
      )
  }

  static getExperimentsFromRequest({ req, res }) {
    const experimentQuery = req.query?.['optimizely-experiments']

    if (experimentQuery) {
      return OptimizelyService.getForcedExperiment(experimentQuery)
    }

    const rawExperiments =
      req.headers[OptimizelyService.OPTIMIZELY_RAW_EXPERIMENTS_HEADER]
    const experiments =
      req.headers[OptimizelyService.OPTIMIZELY_EXPERIMENTS_HEADER]

    res.setHeader(
      OptimizelyService.OPTIMIZELY_EXPERIMENTS_HEADER,
      experiments || ''
    )
    res.setHeader(
      OptimizelyService.OPTIMIZELY_RAW_EXPERIMENTS_HEADER,
      rawExperiments || ''
    )

    return experiments
      ? OptimizelyService.normaliseExperiments(experiments)
      : {}
  }
}

export default OptimizelyService
