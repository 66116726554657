import { TNullable } from 'types/generics'

import { TContentfulEntry, TEntry } from './entry-model.types'
import {
  TTopMenuItemSeeMoreLink,
  TTopMenuItemSeeMoreLinkEntry,
} from './top-menu-item-see-more-link.types'
import {
  TTopMenuItemColumnEntry,
  TTopMenuItemColumn,
  TTopMenuItemColumnWithVariations,
} from './top-menu-item-column-model.types'
import {
  TTopMenuItemGalleryItem,
  TTopMenuItemGalleryItemEntry,
} from './top-menu-item-gallery-item-model.types'

export enum MenuLayout {
  Columns = 'Columns',
  Gallery = 'Gallery',
}

export type TTopMenuItemEntry = TContentfulEntry & {
  fields: {
    label: string
    trimmedLabel?: string
    labelTrimThreshold?: number
    layout: MenuLayout
    link: string
    linkTarget: string
    seeMoreLink: TTopMenuItemSeeMoreLinkEntry
    trackingName: string
    items: TTopMenuItemColumnEntry[] | TTopMenuItemGalleryItemEntry[]
  }
}

export type TTopMenuItem = TEntry & {
  label?: string
  trimmedLabel?: string
  labelTrimThreshold?: TNullable<number>
  layout?: MenuLayout
  items?: TTopMenuItemColumn[] | TTopMenuItemGalleryItem[]
  link?: string
  linkTarget?: string
  seeMoreLink?: TNullable<TTopMenuItemSeeMoreLink>
  trackingName?: string
}

export type TTopMenuItemWithVariations = Omit<TTopMenuItem, 'items'> & {
  items?: TTopMenuItemColumnWithVariations[] | TTopMenuItemGalleryItem[]
}
