import {
  fontSize,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import { BORDER_RADIUS } from '@typeform/ginger/dist/constants/borders'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import styled from 'styled-components'

import { TStyledCtaPrimaryLink } from './banner.types'
import { StyledContainer as Container } from './base.styles'

export const StyledContainer = styled(Container)``

export const StyledMessageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 16px;

  ${mediaQueries.md`
    justify-content: center;
    align-items: unset;
    gap: unset;
  `}
`

export const StyledMessage = styled.p`
  display: inline-block;
  flex-grow: 1;
  margin: 0;

  ${mediaQueries.md`
    display: inline;
    flex-grow: unset;
  `}
`

export const StyledCtaPrimaryLink = styled.a<TStyledCtaPrimaryLink>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-color: ${({ $color }) => $color};
  border-radius: ${BORDER_RADIUS.small};
  border-style: solid;
  border-width: 1px;
  color: ${({ $color }) => $color};
  display: inline-block;
  text-align: center;
  align-self: center;
  margin: -8px 8px;
  padding: 3px 10px;
  font-size: ${fontSize.x2Small};
  line-height: ${lineHeight.large};
  white-space: nowrap;

  ${mediaQueries.sm`
    margin-right: 0;
    margin-left: 0;
    padding: 8px 16px;
    font-size: ${fontSize.small};
  `}

  ${mediaQueries.md`
    margin-right: 16px;
    margin-left: 16px;
  `}
`
