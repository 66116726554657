import { generateId } from 'utils/generate-id'

import { trackSignupFormError } from '../tracking'

import {
  AUTH_BRIDGE_LOGIN_CALLBACK,
  OKTA_AUTH_URL,
  OKTA_FORM_CHILDREN_ID,
  OKTA_INFO_ERROR_CLASS_NAME,
} from './constants'

export const getPortalContainer = () => {
  const form = document.querySelector('form')
  if (!form) {
    return null
  }

  const formChildren = document.createElement('div')
  formChildren.id = OKTA_FORM_CHILDREN_ID
  const submitButton = form.querySelector('input[type=submit]')?.parentNode

  if (submitButton) {
    form.insertBefore(formChildren, submitButton)
  }

  return formChildren
}

const checkFormValidationErrors = () => {
  const oktaErrorElement = document.querySelector(
    `.${OKTA_INFO_ERROR_CLASS_NAME}`
  )

  if (!oktaErrorElement) {
    return
  }

  const errorText = oktaErrorElement.querySelector('p')?.innerText

  if (errorText) {
    trackSignupFormError({ label: errorText })
  }
}

export const setUpFormValidatorListener = () => {
  const form = document.querySelector('form')
  if (!form) {
    return
  }

  form.addEventListener('submit', checkFormValidationErrors)
}

export const getSocialSignupRedirectUrl = (
  idpId: string,
  clientId: string,
  oktaDomain: string,
  state: string,
  loginHint: string = ''
) => {
  const params = {
    client_id: clientId,
    idp: idpId,
    response_type: 'code',
    scope: 'openid email',
    redirect_uri: AUTH_BRIDGE_LOGIN_CALLBACK,
    nonce: generateId(),
    state,
  }
  const queries = new URLSearchParams(params)

  if (loginHint) {
    queries.append('login_hint', loginHint)
  }

  return `${oktaDomain}${OKTA_AUTH_URL}?${queries.toString()}`
}
