import { TYPEFORM_COM_DEFAULT_ROOT_DOMAIN } from 'constants/root-domains'

export const getAppPublicBaseUrl = () => {
  if (
    typeof process !== 'undefined' &&
    process?.env.APP_PUBLIC_BASE_URL !== undefined
  ) {
    return process.env.APP_PUBLIC_BASE_URL
  }
  if (typeof window !== 'undefined' && window.location.origin !== undefined) {
    return window.location.origin
  }
  return `https://www.${TYPEFORM_COM_DEFAULT_ROOT_DOMAIN}`
}
