import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import Button from 'components/atoms/button'
import styled, { css } from 'styled-components'

export const RightNavigationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  gap: ${spacing(2)};

  ${mediaQueries.md`
    flex-grow: unset;
  `}

  z-index: 1;
`

type THighlightedMobileLinkProps = {
  isVisible: boolean
}

export const HighlightedMobileLink = styled(
  Button
)<THighlightedMobileLinkProps>`
  visibility: hidden;
  opacity: 0;
  transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;

  ${({ isVisible }) =>
    isVisible &&
    css`
      visibility: visible;
      opacity: 1;
    `}
  ${mediaQueries.md`
    display: none;
  `}
`
