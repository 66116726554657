import { CLICK_EVENT_KEYS } from './constants'

/**
 *
 * @param {Object} event
 * @returns boolean
 * Takes an event and returns true if the event has any key property active.
 * For example, if a user presses cmd+click then the "metaKey" property will be set to true.
 */
export const hasKeyPressed = event => {
  return Object.values(CLICK_EVENT_KEYS).some(key => event[key] === true)
}
