import styled, { createGlobalStyle } from 'styled-components'
import {
  NeutralColors,
  UIPalette,
} from '@typeform/ginger/dist/constants/colors'
import {
  fontFamily,
  fontSize,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import { getStaticAsset } from 'utils/assets'

export const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  top: 0;
`

export const OktaContainer = styled.div`
  width: 100%;
`

/*
  Minimal set of rules needed to style DOM elements rendered by OktaWidget
  Using their original classes directly
 */
export const OktaWidgetStyles = createGlobalStyle`
  .okta-form-label,
  .auth-footer,
  .required-fields-label {
    display: none;
  }

  .okta-form-infobox-error,
  .subschema-unsatisfied,
  .subschema-satisfied {
    display: none;
  }

  .okta-form-infobox-error.visible,
  .subschema-unsatisfied.visible,
  .subschema-satisfied.visible {
    display: block;
  }

  .o-form-button-bar {
    display: flex;
    justify-content: center;

    /* stylelint-disable-next-line media-feature-range-notation */
    @media screen and (min-width: 600px) {
      max-width: 100%;
    }
  }

  .button-primary {
    background-color: ${NeutralColors.Ink};
    border-radius: 3px;
    border: none;
    color: ${NeutralColors.White};
    cursor: pointer;
    font-size: ${fontSize.small};
    font-family: ${fontFamily.apercuPro};
    height: 40px;
    line-height: ${lineHeight.medium};
    padding: 0;
    width: 100%;
    min-width: 100%;
    appearance: none;

    /* stylelint-disable-next-line media-feature-range-notation */
    @media screen and (min-width: 600px) {
      max-width: 230px;
    }
  }


  /* Email/password inputs */
  .okta-form-input-field {
    &:hover {
      border-color: ${NeutralColors.Grey600};
    }

    input::placeholder {
      color: ${NeutralColors.Grey300};
      font-family: ${fontFamily.apercuPro};
    }
  }

  .okta-form-title {
    display: none;
  }

  /* Input fields */
  .o-form-fieldset {
    margin-bottom: 15px;
  }

  .o-form-input-name-email,
  .o-form-input-name-password {
    width: 100%;
    height: 40px;
    display: inline-block;
    border: 1px solid ${NeutralColors.Grey400};
    border-radius: 3px;

    input {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 6px 8px;
      border-radius: 3px;

      /* Inputs need to have a minimum font-size of 16px to avoid Mobile Safari zooming in when the field is focused/blurred. */
      font-size: ${fontSize.small};
      line-height: 16px;
      border: none;
    }
  }

  .o-form-input-name-password {
    position: relative;

    input {
      padding-right: 35px;
    }
  }

  .o-form-input-password-toggle {
    background: none;
    border: none;
    color: inherit;
    outline: inherit;
    height: 0;
    width: 0;
    padding: 0;
  }

  .o-form-input-eyeicon {
    right: 5px;
    position: absolute;
    top: 58%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    padding: 6px 8px;
    opacity: 0.175;
    cursor: pointer;
    font-size: 0;
    user-select: none;
  }

  .o-form-input-eyeicon-show:before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M16 16c-.8125-.8125 1.1875-4 0-4-2.1875 0-4 1.8125-4 4s1.8125 4 4 4 4-1.8125 4-4c0-1-3.375.8125-4 0Zm0-10C5.625 6 0 14.8125 0 16.1875 0 17.625 5.625 26 16 26s16-8.8125 16-10S26.375 6 16 6Zm0 16c-3.375 0-6-2.625-6-6s2.625-6 6-6 6 2.625 6 6-2.625 6-6 6Zm0 0'/%3E%3C/svg%3E");
  }

  .o-form-input-eyeicon-hide {
    display: none;

    &:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M27.375 21.813 22 16.375V16c0-3.375-2.625-6-6-6h-.375L12 6.375C13.375 6.187 14.625 6 16 6c10.375 0 16 8.813 16 10 0 .625-1.625 3.375-4.625 5.813ZM20 25.625c-1.375.188-2.625.375-4 .375-10.375 0-16-8.375-16-9.813 0-.812 1.625-3.562 4.625-6L10 15.625V16c0 3.375 2.625 6 6 6h.375ZM6.375 4 28 25.625 25.625 28 4 6.375Zm0 0'/%3E%3C/svg%3E");
    }
  }

  .o-form-content {
    margin-bottom: 15px;
  }

  /* Errors */
  .okta-form-input-error {
    color: ${UIPalette.Red700};
    display: none;
    font-size: ${fontSize.xSmall};
    line-height: ${lineHeight.large};
    margin: 0;
    padding: 8px 0 6px 20px;
    position: relative;

    .o-form-has-errors & {
      display: inherit;
    }

    span:before {
      content: '';
      background: url(${getStaticAsset('signup-page/warning.svg')}) repeat 50%;
      width: 14px;
      height: 14px;
      position: absolute;
      left: 0;
      top: 8px;
    }
  }

  .default-schema {
    display: none;
  }

  .subschema-unsatisfied,
  .subschema-satisfied {
    p {
      font-size: ${fontSize.xSmall};
      line-height: ${lineHeight.large};
      margin: 0;
      padding: 8px 0 6px 20px;
      position: relative;

      span:before {
        content: '';
        width: 14px;
        height: 14px;
        position: absolute;
        left: 0;
      }
    }
  }

  .subschema-satisfied {
    p {
      color: ${NeutralColors.Grey500};

      span:before {
        background: url(${getStaticAsset('signup-page/check.svg')}) repeat 50%;
        top: 10px;
      }
    }
  }

  .subschema-unsatisfied {
    p {
      color: ${UIPalette.Red700};

      span:before {
        background: url('${getStaticAsset(
          'signup-page/warning.svg'
        )}') repeat 50%;
      }
    }
  }

  /* Terms&Consents error */
  .okta-form-infobox-error {
    border: 1px solid ${NeutralColors.Grey200};
    border-radius: 3px;
    color: ${NeutralColors.Grey700};
    font-size: ${fontSize.xSmall};
    line-height: ${lineHeight.large};
    margin-bottom: 15px;
    padding: 12px 18px 12px 48px;
    position: relative;
    overflow: hidden;

    p {
      margin: 0;
    }

    a {
      text-decoration: underline;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 36px;
      height: 100%;

      /* Check if this is the same color as the svg. */
      background: url('${getStaticAsset(
        'signup-page/warning-inverted.svg'
      )}') no-repeat 50% 1rem ${UIPalette.Red700};
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`

export const ReCAPTCHADisclaimerStyles = styled.span`
  display: block;
  margin-top: 30px;
  font-size: ${fontSize.xSmall};
  line-height: ${lineHeight.large};
  color: ${NeutralColors.Grey500};
  a {
    text-decoration: underline;
  }
`
