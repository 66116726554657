export const ONETRUST_SDK_ID = 'onetrust-consent-sdk'
export const ONETRUST_SDK_SELECTOR = '#onetrust-consent-sdk'
export const SHOWN_COOKIE_AREA_CLASSNAME = 'show-cookie-banner'
export const SHOWN_COOKIE_AREA_SELECTOR = `.${SHOWN_COOKIE_AREA_CLASSNAME}`
export const ONETRUST_BANNER_CLOSED_COOKIE_NAME = 'OptanonAlertBoxClosed'
export const COOKIE_ACCEPT_INTENT_KEY = 'CustomCookieBannerAcceptIntent'
export const COOKIE_BANNER_SAFE_AREA_CLASSNAME =
  'cookie-consent-banner-safe-area'
export const COOKIE_BANNER_SAFE_AREA_SELECTOR = `.${COOKIE_BANNER_SAFE_AREA_CLASSNAME}`
export const ONE_TRUST_MODAL_REJECT_ALL_BUTTON_CLASSNAME =
  'ot-pc-refuse-all-handler'
export const ONE_TRUST_MODAL_REJECT_ALL_BUTTON_SELECTOR = `.${ONE_TRUST_MODAL_REJECT_ALL_BUTTON_CLASSNAME}`
export const ONE_TRUST_COOKIES_REJECTED_EVENT = 'oneTrustCookiesRejected'
