import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import { BREAKPOINTS } from '@typeform/ginger/dist/constants/breakpoints'
import { BORDER_RADIUS } from '@typeform/ginger/dist/constants/borders'
import { BOX_SHADOWS } from '@typeform/ginger/dist/constants/shadows'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'

import {
  COOKIE_BANNER_SAFE_AREA_SELECTOR,
  SHOWN_COOKIE_AREA_SELECTOR,
} from './constants'

export const cookieCss = `
  #onetrust-banner-sdk {
    display: none !important;
  }

  .onetrust-pc-dark-filter, #onetrust-banner-sdk {
    pointer-events: none;
    display: none !important;
  }

  #onetrust-pc-sdk {
    box-shadow: ${NeutralColors.Ink}73 0 0 50px 1000px;
  }

  .cookie-consent-area {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in;
    position: relative;
    z-index: 8000;
  }

  #cookie-consent-title {
    margin-bottom: 12px;
    font-size: ${fontSize.large};
    font-weight: ${fontWeight.regular};
    line-height: ${lineHeight.small};
  }

  .cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;
    background: ${NeutralColors.White};
    color: ${NeutralColors.Ink};
    border-radius: ${BORDER_RADIUS.small};
    box-shadow: ${BOX_SHADOWS.small}, ${BOX_SHADOWS.medium};
    max-height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-font-smoothing: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cookie-consent-banner-boundaries {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
  }

  ${SHOWN_COOKIE_AREA_SELECTOR} .cookie-consent-banner-boundaries {
    display: block;
  }

  .cookie-consent-banner-content {
    display: flex;
    max-width: 1322px;
    gap: 48px;
    align-items: center;
  }

  .cookie-consent-text {
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.xSmall};
    line-height: ${lineHeight.large};
    margin: 0;
  }

  .cookie-consent-text a {
    text-decoration: underline
  }

  ${SHOWN_COOKIE_AREA_SELECTOR} .cookie-consent-area {
    opacity: 1;
    pointer-events: unset;
  }

  .cookie-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  .cookie-settings-link, .cookie-accept-btn {
    border-radius: 10px;
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.small};
    line-height: ${lineHeight.large};
    padding: 8px 16px;
    border: 1px solid;
    cursor: pointer;
    min-width: 200px;
    width: 100%;
    max-width: 328px;
  }

  .cookie-settings-link:hover, .cookie-accept-btn:hover {
    opacity: 0.8;
  }

  .cookie-settings-link:focus, .cookie-accept-btn:focus {
    outline: 2px solid ${NeutralColors.Ink};
    border: 1px solid ${NeutralColors.White};
  }

  .cookie-settings-link {
    border: 1px solid transparent;
    background-color: ${NeutralColors.White};
    color: black;
    text-decoration: underline;
    padding: 6px 16px;
  }

  .cookie-accept-btn {
    background-color: ${NeutralColors.Ink};
    border-color: ${NeutralColors.Ink};
    color: ${NeutralColors.White};
  }


  @media (max-width: ${BREAKPOINTS.sm}px) {
    #cookie-consent-title {
      font-size: ${fontSize.small} !important;
      line-height: ${lineHeight.large} !important;
      font-weight: ${fontWeight.medium} !important;
    }

    .cookie-buttons {
      width: 100%;
    }

    .cookie-consent-banner {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .cookie-consent-banner-content {
      flex-direction: column;
      gap: 24px;
    }

    .cookie-consent-text {
      font-size: ${fontSize.x2Small};
      line-height: ${lineHeight.large};
    }
  }

  ${COOKIE_BANNER_SAFE_AREA_SELECTOR} {
    transition: height 0.2s ease-in, padding 0.2s ease-in;
    pointer-events: none;
    visibility: hidden;
  }

  ${SHOWN_COOKIE_AREA_SELECTOR} ${COOKIE_BANNER_SAFE_AREA_SELECTOR} {
     visibility: visible;
   }

`
