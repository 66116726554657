import React, { memo } from 'react'
import { Button } from '@typeform/echo-ui'

import { SocialsButtonText } from '../auth.styles'

const ViaEmailButton = ({ handleOnClick }: { handleOnClick: () => void }) => {
  return (
    <Button fullWidth onClick={handleOnClick} size='large'>
      <SocialsButtonText> Sign up with email </SocialsButtonText>
    </Button>
  )
}

export default memo(ViaEmailButton)
