import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import mediaqueries from '@typeform/ginger/dist/util/mediaqueries'
import {
  fontSize,
  lineHeight,
  fontWeight,
} from '@typeform/ginger/dist/constants/typography'
import { GRID_UNIT } from '@typeform/ginger/dist/constants/design'
import styled, { css } from 'styled-components'

import {
  TButtonElementProps,
  ButtonSize,
  ButtonVariant,
  TButtonWrapperProps,
} from './types'

const smallFontSize = css`
  font-size: ${fontSize.x2Small};
  line-height: ${lineHeight.large};
`

const regularFontSize = css`
  font-size: ${fontSize.small};
  line-height: ${lineHeight.large};
`

const buttonSizesStyle = {
  [ButtonSize.Small]: css`
    padding: 3px 10px;
    ${smallFontSize};

    border-radius: 6px;
  `,
  [ButtonSize.Medium]: css`
    padding: 8px 16px;
    ${regularFontSize};

    border-radius: 10px;
  `,
  [ButtonSize.Large]: css`
    padding: 12px 24px;
    ${regularFontSize};

    border-radius: 12px;
  `,
}

const getMinWidth = ({
  variant,
  size,
  hasMinWidth,
}: {
  variant: ButtonVariant
  size: ButtonSize
  hasMinWidth?: boolean
}) => {
  if (variant === ButtonVariant.Tertiary || hasMinWidth === false) {
    return 'auto'
  }
  if (size === ButtonSize.Small) {
    return '64px'
  }
  if (size === ButtonSize.Medium) {
    return '140px'
  }
  return '160px'
}

export const getColorStyle = ({
  variant,
  palette,
}: {
  variant: ButtonVariant
  palette: Palette
}) => {
  const isPositivePalette = palette !== Palette.Negative
  const colorFromPalette = isPositivePalette
    ? NeutralColors.Ink
    : NeutralColors.White
  const oppositeColorFromPalette = isPositivePalette
    ? NeutralColors.White
    : NeutralColors.Ink

  if (variant === ButtonVariant.Primary) {
    return css`
      background: ${colorFromPalette};
      color: ${oppositeColorFromPalette};
      border: 1px solid ${colorFromPalette};
    `
  }

  if (variant === ButtonVariant.Secondary) {
    return css`
      background: transparent;
      color: ${colorFromPalette};
      border: 1px solid ${colorFromPalette};
    `
  }
  if (variant === ButtonVariant.Tertiary) {
    return css`
      background: transparent;
      color: ${colorFromPalette};
      border: none;
      padding-left: 0;
      padding-right: 0;
      text-decoration: underline;
    `
  }
}
export const ButtonWrapper = styled.div<TButtonWrapperProps>`
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${GRID_UNIT * 2}px;
  background-color: transparent;
  min-width: ${({ variant, size, hasMinWidth }) =>
    getMinWidth({ variant, size, hasMinWidth })};
  max-width: 100%;
  width: 100%;

  ${mediaqueries.sm`
    gap: ${GRID_UNIT}px;
  `}
  ${mediaqueries.xs`
    width: ${({ fullWidth }: { fullWidth: boolean }) =>
      fullWidth ? '100%' : 'auto'};
  `}
`

export const ButtonElement = styled.button<TButtonElementProps>`
  display: inline-block;
  border-radius: 6px;
  cursor: pointer;
  font-family: inherit;
  transition: all 0.2s ease-out;
  transition-property: background-color, border-color, color, opacity;
  justify-content: center;
  text-align: center;
  font-weight: ${fontWeight.medium};
  width: 100%;

  ${({ size }) => buttonSizesStyle[size] ?? buttonSizesStyle[ButtonSize.Large]};

  ${({ palette, variant }) => getColorStyle({ palette, variant })};

  :hover {
    opacity: 0.8;
  }
`

export const ButtonHelperText = styled.span<{
  palette: Palette
}>`
  font-size: ${fontSize.x2Small};
  font-weight: ${fontWeight.regular};
  color: ${NeutralColors.Grey700};
  line-height: ${lineHeight.large};
  text-align: center;

  ${({ palette }) =>
    palette === Palette.Negative &&
    css`
      color: ${NeutralColors.Grey600};
    `}
`
