import {
  BREAKPOINTS,
  BreakpointKeys,
  TBreakpointConfig,
} from 'constants/breakpoints'

import { CSSObject, FlattenSimpleInterpolation, css } from 'styled-components'
import { useMedia } from 'react-use'

const breakpointKeyValues = Object.values(
  BreakpointKeys
) as Array<BreakpointKeys>

type TMediaQueries = {
  [K in BreakpointKeys]: (
    first: TemplateStringsArray | CSSObject,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...interpolations: any[]
  ) => FlattenSimpleInterpolation
}

const mediaQueries = breakpointKeyValues.reduce((accumulator, label) => {
  const breakpoint = BREAKPOINTS[label]

  accumulator[label] = (
    literals: TemplateStringsArray | CSSObject,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]
  ) => css`
    @media (min-width: ${breakpoint}px) {
      ${css(literals, ...args)}
    }
  `

  return accumulator
}, {} as TMediaQueries)

export const mediaQueriesDown = breakpointKeyValues.reduce(
  (accumulator, label) => {
    const breakpoint = BREAKPOINTS[label]

    accumulator[label] = (
      literals: TemplateStringsArray | CSSObject,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...args: any[]
    ) => css`
      @media (max-width: ${breakpoint - 1}px) {
        ${css(literals, ...args)}
      }
    `

    return accumulator
  },
  {} as TMediaQueries
)

/**
 * React hook that uses useMedia hook to return a break point config object with matched breakpoints set to true otherwise false
 *
 * @param {Object} defaultStates Default states to pass useMedia hook
 * @param {Object} defaultStates.xl Default state for lg media query
 * @param {Object} defaultStates.lg Default state for lg media query
 * @param {Object} defaultStates.md Default state for md media query
 * @param {Object} defaultStates.sm Default state for sm media query
 * @param {Object} defaultStates.xs Default state for xs media query
 * @returns {{ lg: Boolean, md: Boolean, sm: Boolean, xs: Boolean, base: Boolean }}
 */
export const useMatchedBreakpoints = (
  defaultStates: { [k in BreakpointKeys]?: boolean } = {}
): { [k in BreakpointKeys]: boolean } => {
  return {
    xl: useMedia(`(min-width: ${BREAKPOINTS.xl}px)`, defaultStates?.xl),
    lg: useMedia(`(min-width: ${BREAKPOINTS.lg}px)`, defaultStates?.lg),
    md: useMedia(`(min-width: ${BREAKPOINTS.md}px)`, defaultStates?.md),
    sm: useMedia(`(min-width: ${BREAKPOINTS.sm}px)`, defaultStates?.sm),
    xs: useMedia(`(min-width: ${BREAKPOINTS.xs}px)`, defaultStates?.xs),
    base: true,
  }
}

/**
 * Accepts a breakpoints config, iterates over them and applies css template string by calling template literal tags with the matched config
 */
export const cssForBreakpointsConfig =
  (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    breakpointsConfig: TBreakpointConfig<any>
  ) =>
  (
    templateString: TemplateStringsArray,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...templateStringTags: any[]
  ) =>
    breakpointKeyValues
      .filter(breakpoint => !!breakpointsConfig[breakpoint])
      .map(breakpoint => {
        const config = breakpointsConfig[breakpoint]

        const templateStringTagsWithConfig = templateStringTags.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          literalArg => (props: any) => literalArg(config, props)
        )

        if (breakpoint === 'base') {
          return css(templateString, templateStringTagsWithConfig)
        }

        return mediaQueries[breakpoint](
          templateString,
          ...templateStringTagsWithConfig
        )
      })
export default mediaQueries
