import { BREAKPOINTS } from '@typeform/ginger/dist/constants/design'
import { Device } from '@typeform/ginger/dist/constants/device'
import useMediaWithDevice from '@typeform/ginger/dist/hooks/use-media-with-device'

export const useIsMobileMenu = () => {
  const isMd = useMediaWithDevice(
    `(max-width: ${BREAKPOINTS.md - 1}px)`,
    Device.Mobile
  )

  return isMd
}
