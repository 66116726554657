import {
  fontSize,
  fontWeight,
  lineHeight,
} from '@typeform/ginger/dist/constants/typography'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import styled from 'styled-components'

export const UserInfoBox = styled.div`
  display: flex;
  padding-left: ${spacing(2)};
  padding-right: ${spacing(2)};
  padding-top: ${spacing(0.5)};
  padding-bottom: ${spacing(0.5)};
  gap: ${spacing(2)};
  align-items: center;
`

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`
export const UserName = styled.span`
  font-size: ${fontSize.xSmall};
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.medium};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const UserEmail = styled.span`
  font-size: ${fontSize.x2Small};
  color: ${NeutralColors.Grey700};
  line-height: ${lineHeight.large};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
